import React, { Component } from 'react'
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { ReactComponent as ISubmit } from '../icons/history_log/send.svg';
import { ReactComponent as IActivity } from '../icons/table/activity.svg';
import { ReactComponent as IRFI } from '../icons/table/rfi.svg';
import { ReactComponent as IReporting } from '../icons/table/reporting.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip as MuiTooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { Line, Bar } from 'react-chartjs-2';
import WorldMap, { CountryContext } from "react-svg-worldmap"
import Dropdown from 'react-bootstrap/Dropdown';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import MDEditor from "@uiw/react-md-editor";
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function get_status_color(status, transparent) {
    switch (status) {
        case "New":
            return transparent ? "rgba(244,63,94,0.25)" : "#F43F5E"
        case "Pending":
            return transparent ? "rgba(245,158,11,0.25)" : "#F59E0B"
        case "False Positive":
            return transparent ? "rgba(139,92,246,0.25)" : "#8B5CF6"
        case "Closed":
            return transparent ? "rgba(6,182,212,0.25)" : "#06B6D4"
        default:
            return ""
    }
}

function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

const getStyle = ({
                      countryValue,
                      countryCode,
                      minValue,
                      maxValue,
                      color,
                  }: CountryContext) => ({
    fill: countryValue ? color: "rgb(85,115,85)",
    fillOpacity: 0.5,
    stroke: "white",
    strokeWidth: 1,
    cursor: "pointer",
});

const rfi_columns = [
    {
        accessorKey: 'urgency',
        header: 'Urgency',
        size: 150,
    },
    {
        accessorKey: 'user',
        header: 'User',
        size: 150,
    },
    {
        accessorKey: 'category',
        header: 'Category',
        size: 200,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    color: get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'submitted',
        header: 'Submitted',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];
const columns = [
    {
        accessorKey: 'host',
        header: 'Host / User',
        size: 150,
    },
    {
        accessorKey: 'priority',
        header: 'Priority',
        size: 150,
    },
    {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
    },
    {
        accessorKey: 'name',
        header: 'Name / Domain',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {
                    cell.row.original["name"] ?
                        cell.row.original["name"]
                        :
                        cell.row.original["domain"]
                }

            </div>
        ),
    },
    {
        header: 'Country',
        size: 150,
        Cell: ({ cell }) => (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyItems: "center",
                alignItems: "center"
            }}>
                <div>
                    {
                        <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                    }
                </div>
                <div>
                    {getISO(cell.row.original["country"])}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({cell}) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    color: get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'snow_ticket',
        header: 'SNOW Ticket',
        size: 150,
    },
    {
        accessorKey: 'created',
        header: 'First Detected',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
    {
        accessorKey: 'updated',
        header: 'Last Updated',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default class K2IHud extends Component {
    search_placeholder = {
        priority: "P",
        ioa: "-",
        country: ["-", ""],
        malware: "-",
        first_seen: "-",
        last_seen: "-"
    };
    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });
    wait_backend = false;
    wait_ioa = false;
    last_feed_idx = 0;

    constructor(props) {
        super(props);
        this.map_ref = React.createRef()
        this.map_box_ref = React.createRef()
        this.eti_feed_ref = React.createRef()
        this.state = {
            username: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0, 0, 0, 0, 0],
            p: [0, 0, 0, 0],
            p_val: [0, 0, 0, 0],
            table_tab_idx: 0,
            s: [0],
            s_val: [0],
            table_data: [], map_data: [],
            showModal: false,
            showPlaybook: false,
            showRFIModal: false,
            modal_data: {},
            playbook: {},
            ioa_data: undefined,
            eti_feed: [],
            up_cache: undefined,
            down_cache: undefined,
            search_result: this.search_placeholder,
            top10_data: {},
            showTop10: false,
            log_input_text: "",
            show_log_input: false,
            rfi_priority: "Vendor"
        };
        this.getOnlineNumber = this.getOnlineNumber.bind(this);
        this.getEndpointLimit = this.getEndpointLimit.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.getDetectionNumber = this.getDetectionNumber.bind(this);
        this.set_dials = this.set_dials.bind(this);
        this.getLatestPulse = this.getLatestPulse.bind(this);
        this.getPackets = this.getPackets.bind(this)
        this.getMapWidth = this.getMapWidth.bind(this)
        this.getMapHeight = this.getMapHeight.bind(this)
        this.getMapBoxHeight = this.getMapBoxHeight.bind(this)
        this.OpenModal = this.OpenModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.getCountryRender = this.getCountryRender.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.update_detection = this.update_detection.bind(this);
        this.update_feed = this.update_feed.bind(this);
        this.getSearchColor = this.getSearchColor.bind(this);
        this.getOnlineHistory = this.getOnlineHistory.bind(this);
        this.getDayLabel = this.getDayLabel.bind(this);
        this.pull_ioa_snapshot = this.pull_ioa_snapshot.bind(this);
        this.update_feed_cache = this.update_feed_cache.bind(this);
        this.get_top_countries = this.get_top_countries.bind(this);
        this.get_trending_down = this.get_trending_down.bind(this);
        this.get_trending_up = this.get_trending_up.bind(this);
        this.getBreachDate = this.getBreachDate.bind(this)
        this.getBreachURL = this.getBreachURL.bind(this)
        this.getBreachCountryRender = this.getBreachCountryRender.bind(this)
        this.top_10 = this.top_10.bind(this);
        this.tick = this.tick.bind(this)
        this.feed_cache = []
        let data = this.props.page_data()
        //console.log("dashboard", data)
    }
    getBreachURL() {
        let url = "N/A"
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["group"]) {
                url = this.state.modal_data["risk"]["post_url"]
            } else url = this.state.modal_data["risk"]["url"]
        }
        let image

        if (url.includes(".onion")) {
            image = "/main/onion-link-i.svg"
            url = url.replaceAll(".", "[.]")
        } else {
            image = "/main/link-i.svg"
        }

        let full_url = url
        if (url.length > 40) {
            url = url.slice(0, 40) + "..."
        }
        return (
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                <img src={image} alt={"url-icon"}/>
                <a href={full_url} target="_blank"
                   rel="noreferrer"
                   style={{
                       color: "#06B6D4"
                   }}
                >
                    {url}
                </a>
            </div>
        )
    }
    getBreachDate() {
        let time = 0
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["risk"]["date"]) {
                time = this.state.modal_data["risk"]["date"]
            } else time = this.state.modal_data["risk"]["published"]
        }
        return (
            <div>
                {get_time_string(time)}
            </div>
        )
    }
    getBreachCountryRender() {
        let code = getISO(this.state.modal_data["country"], true)
        let name = getISO(this.state.modal_data["country"])
        return (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                {name}
            </div>
        )
    }
    get_trending_up() {
        if (!this.state.ioa_data)return []
        if (this.state.up_cache) return this.state.up_cache

        let day_ago = Math.floor(new Date().getTime()/1000.0) - 86400

        let latest_detections = {}
        let going_up = {}

        for (let item of this.state.ioa_data) {
            if (item["first_seen"] > day_ago) {
                if (item["malware"] in latest_detections) latest_detections[item["malware"]]["count"]++
                else {
                    latest_detections[item["malware"]] = {}
                    latest_detections[item["malware"]]["malware"] = item["malware"]
                    latest_detections[item["malware"]]["group"] = item["group"]
                    latest_detections[item["malware"]]["count"] = 1
                }
            }
        }

        let top_up = []

        let top_up_list = Object.keys(latest_detections).sort(function(a,b) { return latest_detections[b]["count"] - latest_detections[a]["count"]; });

        for (let item of top_up_list) {
            top_up.push(latest_detections[item])
        }

        if (top_up.length > 5) {
            top_up = top_up.slice(0, 5)
        }

        this.setState({up_cache: top_up})
        return top_up
    }

    get_trending_down() {
        if (!this.state.ioa_data)return []
        if (this.state.down_cache) return this.state.down_cache

        let now = Math.floor(new Date().getTime()/1000.0)
        let day_ago = now - 86400

        let latest_detections = {}
        let old_detections = {}
        let going_down = {}

        for (let item of this.state.ioa_data) {
            if (item["last_seen"] > day_ago) {
                if (item["malware"] in latest_detections) latest_detections[item["malware"]]["count"]++
                else {
                    latest_detections[item["malware"]] = {}
                    latest_detections[item["malware"]]["malware"] = item["malware"]
                    latest_detections[item["malware"]]["group"] = item["group"]
                    latest_detections[item["malware"]]["count"] = 1
                }
            }
            if (item["last_seen"] < day_ago) {
                if (item["malware"] in old_detections) old_detections[item["malware"]]["count"]++
                else {
                    old_detections[item["malware"]] = {}
                    old_detections[item["malware"]]["malware"] = item["malware"]
                    old_detections[item["malware"]]["group"] = item["group"]
                    old_detections[item["malware"]]["count"] = 1
                }
            }
        }

        for (let item of Object.keys(latest_detections)) {
            if (item in old_detections) {
                if (latest_detections[item]["count"] < old_detections[item]["count"]) {
                    going_down[item] = old_detections[item]
                    going_down[item]["diff"] = old_detections[item]["count"] - latest_detections[item]["count"]
                    going_down[item]["current_count"] = latest_detections[item]["count"]
                }
            }
        }


        for (let item of Object.keys(old_detections)) {
            if (!(item in latest_detections)) {
                going_down[item] = old_detections[item]
                going_down[item]["diff"] = old_detections[item]["count"]
                going_down[item]["current_count"] = 0
            }
        }

        for (let item of Object.keys(going_down)) {
            going_down[item]["per"] = Math.ceil(going_down[item]["diff"] * 100 / going_down[item]["count"]) * going_down[item]["count"]
        }


        let top_down = []

        let top_down_list = Object.keys(going_down).sort(function(a,b) { return going_down[b]["per"] - going_down[a]["per"]; });

        for (let item of top_down_list) {
            top_down.push(going_down[item])
        }

        if (top_down.length > 5) {
            top_down = top_down.slice(0, 5)
        }


        this.setState({down_cache: top_down})
        return top_down
    }

    get_top_countries() {
        if (!this.state.ioa_data)return []
        let country_map = {}
        let total = 0
        let top = []
        for (let item of this.state.ioa_data) {
            if (item["country"][0] in country_map) country_map[item["country"][0]]["count"]++
            else {
                country_map[item["country"][0]] = {}
                country_map[item["country"][0]]["country"] = item["country"]
                country_map[item["country"][0]]["count"] = 1
            }
            total++
        }
        let top_countries = Object.keys(country_map).sort(function(a,b) { return country_map[b]["count"] - country_map[a]["count"]; });

        for (let item of top_countries) {
            let hold = country_map[item]
            hold["per"] = Math.ceil(hold["count"] * 100 / total)
            top.push(country_map[item])
        }
        return top.slice(0,5)
    }

    top_10(val, type) {
        if (!this.state.ioa_data)return
        let data = {}
        let malware_map = {}
        data["list"] = []
        data["target"] = val
        let total = 0

        for (let item of this.state.ioa_data) {
            let check = item["priority"] === val
            if (type) {
                check = item["group"] === val
            }
            if (check) {
                total++
                if (item["malware"] in malware_map) malware_map[item["malware"]]["count"]++
                else {
                    malware_map[item["malware"]] = item
                    malware_map[item["malware"]]["count"] = 1
                }
            }

        }

        let top_malware = Object.keys(malware_map).sort(function(a,b) { return malware_map[b]["count"] - malware_map[a]["count"]; });

        for (let item of top_malware) {
            data["list"].push(malware_map[item])
        }

        data["total"] = total
        this.setState({showTop10: true, top10_data: data});
    }

    OpenModal (data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        this.setState({ modal_data: data, showModal: true });
    }

    CloseModal () {
        this.setState({ modal_data: {}, showModal: false, show_log_input: false, log_input_text: ""});
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = this.state.modal_data["country"][1]
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {this.state.modal_data["country"][0]}
                    {code === "" ? "": <CircleFlag height={20} countryCode={code} />}
                </div>
            )
        }
    }

    getDayLabel(idx) {
        if (idx < 0) {
            idx += 7
        }
        return days[idx]
    }

    update_feed() {
        if (this.feed_cache.length > 1) {
            let feed = this.state.eti_feed
            let len = 30
            if (this.eti_feed_ref.current !== null) {
                len = Math.ceil(this.eti_feed_ref.current.offsetHeight / 21)
            }
            if (feed.length >= len) {
                feed.splice(0, 1)
            }
            feed.push(this.feed_cache[0])
            this.feed_cache.splice(0, 1)
            if (this.feed_cache.length > 10) {
                this.feed_cache.length = []
            }
            this.setState({eti_feed: feed})
        }
    }

    getSearchColor() {
        switch(this.state.search_result.priority) {
            case "P1":
                return "#EF4444"
            case "P2":
                return "#F97316"
            case "P3":
                return "#EAB308"
            case "P4":
                return "#06B6D4"
            default:
                return "#A1A1AA"
        }
    }

    pull_ioa_snapshot() {
        if (this.wait_ioa)return
        this.wait_ioa = true
        let data = this.props.page_data()
        let company = data.company
        let key = data.keys[data.company]
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            // fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/latest_ioa_data", {
            //     method: 'POST',
            //     mode: 'cors',
            //     body: JSON.stringify(jsonData)
            // }).then((response) => {
            //     if (response.ok) {
            //         response.json().then(json => {
            //             if (json.data) {
            //                 this.setState({ioa_data: json["data"]})
            //             }
            //         });
            //     }
            // }).catch((err) => {
            //     console.log("dashboard", err)
            // }).finally(() => {
            //     this.wait_ioa = false
            // })
        }
    }

    update_feed_cache() {
        let update = []
        if (!this.state.ioa_data)return
        if (this.last_feed_idx > this.state.ioa_data.length - 15) {
            this.last_feed_idx = 0
        }
        for (let i = this.last_feed_idx; i < this.last_feed_idx + 10; i++) {
            let str = this.state.ioa_data[i]["ip"]
            if (this.state.ioa_data[i]["host"] !== "N/A") {
                str += " " + this.state.ioa_data[i]["host"]
            } else if (this.state.ioa_data[i]["ssl_cn"] !== "N/A") {
                str += " " + this.state.ioa_data[i]["ssl_cn"]
            }
            update.push(str)
        }
        this.last_feed_idx = this.last_feed_idx + 10
        this.feed_cache = this.feed_cache.concat(update)
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company;
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            if (this.wait_backend) {
                return
            }
            this.wait_backend = true
            // fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/stats", {
            //     method: 'POST',
            //     mode: 'cors',
            //     body: JSON.stringify(jsonData)
            // }).then((response) => {
            //     if (response.ok) {
            //         response.json().then(json => {
            //             this.setState({stats: json})
            //         });
            //     }
            // }).catch((err) => {
            //     console.log("dashboard", err)
            // }).finally(() => {
            //     this.wait_backend = false
            // })
        }
        this.update_feed_cache()
        if (!this.state.ioa_data) {
            this.pull_ioa_snapshot()
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("k2ihud", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("k2ihud")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({username: data.username, key: data.keys[data.company], company: data.company, keys: data.keys, live_data: {}})
        }

        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );

        this.feed_update = setInterval(
            () => this.update_feed(),
            200
        );

        this.pull_ioa_snapshot()
    }

    update_detection(e, log) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "data": log ? log : parseInt(e.currentTarget.id)
        }
        // fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/update_detection", {
        //     method: 'POST',
        //     mode: 'cors',
        //     body: JSON.stringify(jsonData)
        // }).then((response) => {
        //     if (response.ok) {
        //         response.json().then(json => {
        //             if (json.status) {
        //                 let modal_data = this.state.modal_data
        //                 modal_data["status"] = json.status
        //                 this.setState({modal_data: modal_data})
        //             }
        //             if (json.log) {
        //                 let modal_data = this.state.modal_data
        //                 modal_data["log"] = json.log
        //                 this.setState({modal_data: modal_data})
        //             }
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("update_detection", err)
        // })
    }



    submitSearch(e) {
        if (e.target.value !== this.state.search_result.ioa) {
            let company = this.state.company;
            let jsonData = {
                "key": this.state.key,
                "ioa": e.target.value
            }
            // fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/ioa_search", {
            //     method: 'POST',
            //     mode: 'cors',
            //     body: JSON.stringify(jsonData)
            // }).then((response) => {
            //     if (response.ok) {
            //         response.json().then(json => {
            //             this.setState({search_result: this.search_placeholder})
            //             if (json.ioa) {
            //                 this.setState({search_result: json})
            //             }
            //         });
            //     }
            // }).catch((err) => {
            //     console.log("dashboard ioa search", err)
            // })
        }
    }

    getMapWidth() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetWidth
        }
        return 200
    }

    getMapHeight() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetHeight
        }
        return 200
    }

    getMapBoxHeight() {
        if (this.map_box_ref.current !== null) {
            return this.map_box_ref.current.offsetHeight
        }
        return 1400
    }

    getPackets() {
        if (this.state.stats.packets) {
            return this.state.stats.packets
        }
        return 0
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
        clearInterval(this.feed_update);
    }
    getOnlineHistory() {
        if (this.state.stats.online) {
            let history = Array.from(this.state.stats.online)
            history.splice(0, 1)
            history[0] = this.getOnlineNumber()
            
            return history.reverse()
        } else {
            return Array.from({length: 28}, this.getOnlineNumber)
        }
    }

    getOnlineNumber() {
        if (this.state.stats.online) {
            return this.state.stats.online[0]
        } else {
            return 0
        }
    }

    getEndpointLimit() {
        if (this.state.stats.limit) {
            return this.state.stats.limit
        } else {
            return 0
        }
    }

    getTableData() {
        if (this.state.stats.detections) {
            let items = []
            let map_data = []
            let d = [0, 0, 0, 0, 0, 0]
            let data = this.state.stats.detections.concat(this.state.stats.risk_detections)
            for (let item of data) {
                items.push(item)
                switch (item.priority) {
                    case 'P1':
                        d[0]++
                        break
                    case 'P2':
                        d[1]++
                        break
                    case 'P3':
                        d[2]++
                        break
                    case 'P4':
                        d[3]++
                        break
                }

                switch (item.priority.charAt(0)) {
                    case 'S':
                        d[4]++
                        break
                    case 'C':
                        d[5]++
                        break
                }

                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function(key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            if (!deepEqual(this.state.table_data, items)) {
                this.setState({table_data: items, d: d, map_data: map_data})
            }
        }
        if (this.state.stats.c2) {
            this.set_dials()
        }
        return this.state.table_data
    }

    getDetectionNumber() {
        if (this.state.stats.detections) {
            return 0
        } else {
            return 0
        }
    }

    set_dials() {
        let p1 = (this.state.stats.p1 / this.state.stats.c2) * 100
        let s = (this.state.d[4] / this.state.stats.s) * 100
        if (p1 !== this.state.p[0] || s !== this.state.s[0]) {
            this.setState({
                p: [
                    p1,
                    (this.state.stats.p2 / this.state.stats.c2) * 100,
                    (this.state.stats.p3 / this.state.stats.c2) * 100,
                    (this.state.stats.p4 / this.state.stats.c2) * 100
                ],
                p_val: [
                    this.state.stats.p1,
                    this.state.stats.p2,
                    this.state.stats.p3,
                    this.state.stats.p4
                ],
                s: [
                    s
                ],
                s_val: [
                    this.state.stats.s
                ]
            })
        }
    }

    getLatestPulse() {
        if (this.state.stats.pulse) {
            return this.state.stats.pulse
        } else {
            return 0
        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showTop10}
                    contentLabel="Top 10"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "720px",
                            background: "#171717"
                        },
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                        }}>
                            <div style={{color: "#F9B233"}}>
                                Top 10 Malware: {this.state.top10_data["target"]}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showTop10: false})}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        {
                            this.state.top10_data["list"] ?
                                this.state.top10_data["list"].slice(0, 10).map((entry, i) =>
                                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "8px"}}>
                                        <div style={{
                                            width: "100%",
                                            paddingLeft: 4,
                                            paddingRight: 8,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{paddingRight: 4, alignItems: 'center', display: 'flex'}}>
                                                <div style={{
                                                    width: "30px",
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    lineHeight: 1,
                                                    wordWrap: 'break-word'
                                                }}>#{i + 1}</div>
                                            </div>

                                            <button
                                                onClick={() => this.props.open_threat_card(entry["malware"])}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    border: "none",
                                                }}
                                            >
                                                <div style={{
                                                    padding: 8,
                                                    background: '#27272A',
                                                    borderRadius: 2,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                    <img style={{width: 20, height: 20}} src="/main/show-i.svg"
                                                         alt="show"/>
                                                </div>
                                            </button>


                                            <div style={{
                                                flex: '1 1 0',
                                                height: 40,
                                                paddingRight: 4,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    gap: 8,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        lineHeight: 1,
                                                        wordWrap: 'break-word'
                                                    }}>{entry["malware"]}</div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        lineHeight: 1,
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>{entry["group"]}</div>
                                                </div>
                                            </div>
                                            <div style={{
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    height: 20,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            borderRadius: 16,
                                                            overflow: 'hidden',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                lineHeight: 1,
                                                                letterSpacing: 0.07,
                                                                wordWrap: 'break-word'
                                                            }}>{entry["count"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    height: 20,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            paddingTop: 4,
                                                            paddingBottom: 4,
                                                            background: 'rgba(244, 63, 94, 0.25)',
                                                            borderRadius: 16,
                                                            overflow: 'hidden',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#F43F5E',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                lineHeight: 1,
                                                                letterSpacing: 0.07,
                                                                wordWrap: 'break-word'
                                                            }}>{Math.round(entry["count"] * 1000 / this.state.top10_data["total"]) / 10}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{width: '100%', height: '1px', border: '1px #27272A solid'}}/>
                                    </div>
                                )
                                :
                                <div/>
                        }


                    </div>
                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Tipper"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >
                    {
                        this.state.modal_data["priority"] && this.state.modal_data["priority"].charAt(0) === "S" ?
                            <div style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px"
                            }}
                            >
                                <div style={{
                                    width: "calc(50% - 8px)",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    color: "white",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                    }}>
                                        <div style={{color: "#F9B233"}}>
                                            Supply Chain Risk Tipper
                                        </div>
                                    </div>

                                    <div className="inner-tip-box"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}>
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt="host-icon"
                                                src="/main/tiphost.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Host Information
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="host-data">
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "138px"
                                            }} id="section1">
                                                <div>Client IP:</div>
                                                <div>Hostname:</div>
                                                <div>Host Severity:</div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px"
                                            }} id="section2">
                                                <div>{this.state.modal_data["src_ip"]}</div>
                                                <div>{this.state.modal_data["host"]}</div>
                                                <div>{this.state.modal_data["h_severity"]}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner-tip-box"
                                         style={{
                                             height: "268px"
                                         }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}>
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/breach-details-i.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Breach Details
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="group-data">
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "138px"
                                            }} id="section1">
                                                <div>Supplier Domain:</div>
                                                <div>Contacted IP:</div>
                                                <div>Country:</div>
                                                <div>Breach Date:</div>
                                                <div>Breach Type:</div>
                                                <div>Threat Group:</div>
                                                <div>Source:</div>
                                                <div>Severity:</div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px"
                                            }} id="section2">
                                                <div>{this.state.modal_data["domain"]}</div>
                                                <div>{this.state.modal_data["dest_ip"]}</div>
                                                {this.getBreachCountryRender()}
                                                {this.getBreachDate()}
                                                <div>{this.state.modal_data["breach_type"]}</div>
                                                <div>{this.state.modal_data["group"] ? this.state.modal_data["group"] : "N/A"}</div>
                                                {this.getBreachURL()}
                                                <div>{this.state.modal_data["severity"]}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "12px",
                                            width: "100%",
                                            height: "40px",
                                            fontSize: "13px",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontFamily: "Inter",
                                            marginTop: "auto"
                                        }}
                                    >
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="0"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "New" ?
                                                        "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "New" ?
                                                        "rgba(244, 63, 94, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 100)" : "white",
                                                    }}
                                                >
                                                    New
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="1"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                        "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "Pending" ?
                                                        "rgba(245, 158, 11, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 100)" : "white",
                                                    }}
                                                >
                                                    Pending
                                                </div>
                                            </div>
                                        </button>

                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="2"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%",
                                                marginLeft: "auto"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                        "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "Closed" ?
                                                        "rgba(6, 182, 212, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 100)" : "white",
                                                    }}
                                                >
                                                    Closed
                                                </div>
                                            </div>
                                        </button>

                                    </div>


                                </div>


                                <div style={{
                                    width: "calc(50% - 8px)",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    color: "white",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                    }}
                                    >
                                        <div style={{color: "#F9B233"}}>
                                            Action History
                                        </div>
                                        <button
                                            style={{
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "transparent",
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                marginLeft: "auto"
                                            }}
                                            id="close-modal"
                                            onClick={this.CloseModal}
                                        >
                                            <img
                                                alt=""
                                                src="/main/white-close.svg"
                                            />
                                        </button>
                                    </div>


                                    <div className="inner-tip-box" style={{
                                        height: "100%",
                                        gap: "16px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}
                                        >
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tiphistory.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Action History Log
                                            </div>

                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    borderRadius: "2px",
                                                    border: "1px solid #06B6D4",
                                                    backgroundColor: "transparent",
                                                    color: "#06B6D4",
                                                    position: "relative",
                                                    marginLeft: "auto"
                                                }}
                                                id="playbook"
                                                onClick={() => this.setState({
                                                    showPlaybook: true,
                                                    playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                })}
                                            >
                                                Playbook
                                            </button>

                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    borderRadius: "2px",
                                                    border: "none",
                                                    backgroundColor: "rgba(6,182,212,0.25)",
                                                    color: "#06B6D4",
                                                    position: "relative",
                                                }}
                                                id="new-update"
                                                onClick={() => this.setState({show_log_input: true})}
                                            >
                                                New Update
                                            </button>
                                        </div>


                                        <div className="scroll-bar-div" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0px 0px 16px 8px",
                                            gap: "8px",
                                            overflowY: "scroll",
                                            overflowX: "hidden",
                                            width: "100%",
                                            height: "472px"
                                        }}
                                        >

                                            {this.state.show_log_input ?
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "16px",
                                                        width: "100%",
                                                        height: "230px",
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    <MDEditor
                                                        value={this.state.log_input_text}
                                                        visibleDragbar={false}
                                                        hideToolbar={true}
                                                        preview="edit"
                                                        extraCommands={[]}
                                                        onChange={(data) => {
                                                            this.setState({log_input_text: data})
                                                        }}
                                                        height={200}
                                                    />
                                                    <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                        <button onClick={() => {
                                                            this.update_detection("", this.state.log_input_text)
                                                            this.setState({show_log_input: false, log_input_text: ""})
                                                        }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    borderRadius: "2px",
                                                                    background: "transparent",
                                                                    border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                    marginRight: "8px"
                                                                }}
                                                        >
                                                            <ISubmit style={{
                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                            }}/>
                                                            Submit
                                                        </button>
                                                        <button onClick={() => {
                                                            this.setState({show_log_input: false, log_input_text: ""})
                                                        }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    borderRadius: "2px",
                                                                    background: "transparent",
                                                                    border: "solid 1px #06B6D4",
                                                                    color: "#06B6D4",
                                                                    marginRight: "8px"
                                                                }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                                : ""
                                            }

                                            {
                                                this.state.modal_data["log"] ?

                                                    this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(item["time"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown source={item["text"]}
                                                                                   style={{
                                                                                       padding: "16px 16px 16px 16px",
                                                                                       color: "#A1A1AA",
                                                                                       background: "#18181B",
                                                                                       borderRadius: "8px",
                                                                                       whiteSpace: 'pre-wrap',
                                                                                       width: "100%",
                                                                                       height: "100%",
                                                                                       maxHeight: "100%",
                                                                                       overflow: "auto"
                                                                                   }}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                    :
                                                    ""
                                            }

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                    <div style={{color: "#737373"}}>
                                                        {get_time_string(this.state.modal_data["detected"])}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown
                                                        source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                        style={{
                                                            padding: "16px 16px 16px 16px",
                                                            color: "#A1A1AA",
                                                            background: "#18181B",
                                                            borderRadius: "8px",
                                                            whiteSpace: 'pre-wrap',
                                                            width: "100%",
                                                            height: "100%",
                                                            maxHeight: "100%",
                                                            overflow: "auto"
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </div>
                            :
                            <div style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px"
                            }}
                            >
                                <div style={{
                                    width: "calc(50% - 8px)",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    color: "white",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                    }}>
                                        <div style={{color: "#F9B233"}}>
                                            Tactical Threat Intelligence Tipper
                                        </div>
                                    </div>

                                    <div className="inner-tip-box">
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}>
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tiphost.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Host Information
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="host-data">
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "108px"
                                            }} id="section1">
                                                <div>Client IP:</div>
                                                <div>Hostname:</div>
                                                <div>Host Severity:</div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px"
                                            }} id="section2">
                                                <div>{this.state.modal_data["ip"]}</div>
                                                <div>{this.state.modal_data["host"]}</div>
                                                <div>{this.state.modal_data["h_severity"]}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner-tip-box"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}>
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tipgroup.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Attack Group
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="group-data">
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "108px"
                                            }} id="section1">
                                                <div>IoA:</div>
                                                <div>Threat Type:</div>
                                                <div>Country:</div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px"
                                            }} id="section2">
                                                <div>{this.state.modal_data["ioa"]}</div>
                                                <div>{this.state.modal_data["group"]}</div>
                                                {this.getCountryRender()}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inner-tip-box"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}
                                        >
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tipmalware.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Malware
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="malware-data"
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "108px"
                                            }} id="section1">
                                                <div>Name:</div>
                                                <div>Confidence:</div>
                                                <div>Severity:</div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "4px"
                                            }} id="section2">
                                                <div>{this.state.modal_data["name"]}</div>
                                                <div>{this.state.modal_data["confidence"]}</div>
                                                <div>{this.state.modal_data["severity"]}</div>
                                            </div>

                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    border: "none",
                                                    backgroundColor: "transparent",
                                                    height: "24px"
                                                }}
                                                onClick={() => this.props.open_threat_card(this.state.modal_data["name"])}
                                                id="threat_card_btn"
                                            >
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "24px",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                        alt=""
                                                        src="/main/show-i.svg"
                                                    />
                                                </div>
                                            </button>

                                        </div>

                                    </div>

                                    <div className="inner-tip-box"
                                         style={{
                                             height: "70px"
                                         }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}>
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tipmitre.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>ATT&CK Mapping
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4px",
                                            height: "calc(100%-24px)"
                                        }}
                                             id="malware-data">
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                color: "#A1A1AA",
                                                gap: "4px",
                                                width: "108px"
                                            }} id="section1">
                                                <div>Reference:</div>
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        height: "24px"
                                                    }}
                                                    onClick={() => this.props.open_mitre(this.state.modal_data["name"])}
                                                    id="mitre-btn"
                                                >
                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            height: "24px",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                position: "relative",
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            alt=""
                                                            src="/main/show-i.svg"
                                                        />
                                                    </div>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "12px",
                                            width: "100%",
                                            height: "40px",
                                            fontSize: "13px",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontFamily: "Inter",
                                        }}
                                    >
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="0"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "New" ?
                                                        "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "New" ?
                                                        "rgba(244, 63, 94, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 100)" : "white",
                                                    }}
                                                >
                                                    New
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="1"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                        "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "Pending" ?
                                                        "rgba(245, 158, 11, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 100)" : "white",
                                                    }}
                                                >
                                                    Pending
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="3"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "False Positive" ?
                                                        "rgba(139, 92, 246, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "False Positive" ?
                                                        "rgba(139, 92, 246, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "False Positive" ?
                                                            "rgba(139, 92, 246, 100)" : "white",
                                                    }}
                                                >
                                                    False Positive
                                                </div>
                                            </div>
                                        </button>
                                        <button
                                            className="menu-entry"
                                            onClick={this.update_detection}
                                            id="2"
                                            style={{
                                                width: "132px",
                                                minHeight: "100%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                        "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                    borderColor: this.state.modal_data["status"] === "Closed" ?
                                                        "rgba(6, 182, 212, 100)" : "transparent",
                                                    borderRadius: "2px",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "8px 16px",
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        color: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 100)" : "white",
                                                    }}
                                                >
                                                    Closed
                                                </div>
                                            </div>
                                        </button>

                                    </div>


                                </div>


                                <div style={{
                                    width: "calc(50% - 8px)",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    color: "white",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                    }}
                                    >
                                        <div style={{color: "#F9B233"}}>
                                            Action History
                                        </div>
                                        <button
                                            style={{
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "transparent",
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                marginLeft: "auto"
                                            }}
                                            id="close-modal"
                                            onClick={this.CloseModal}
                                        >
                                            <img
                                                alt=""
                                                src="/main/white-close.svg"
                                            />
                                        </button>
                                    </div>


                                    <div className="inner-tip-box" style={{
                                        height: "100%",
                                        gap: "16px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "4px",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                            alignItems: "center",
                                            height: "24px",
                                        }}
                                        >
                                            <img
                                                style={{width: "24px", height: "24px"}}
                                                alt=""
                                                src="/main/tiphistory.svg"
                                            />
                                            <div style={{
                                                color: "#a1daf8"
                                            }}>Action History Log
                                            </div>

                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    borderRadius: "2px",
                                                    border: "1px solid #06B6D4",
                                                    backgroundColor: "transparent",
                                                    color: "#06B6D4",
                                                    position: "relative",
                                                    marginLeft: "auto"
                                                }}
                                                id="playbook"
                                                onClick={() => this.setState({
                                                    showPlaybook: true,
                                                    playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                })}
                                            >
                                                Playbook
                                            </button>

                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    borderRadius: "2px",
                                                    border: "none",
                                                    backgroundColor: "rgba(6,182,212,0.25)",
                                                    color: "#06B6D4",
                                                    position: "relative",
                                                }}
                                                id="new-update"
                                                onClick={() => this.setState({show_log_input: true})}
                                            >
                                                New Update
                                            </button>
                                        </div>


                                        <div className="scroll-bar-div" style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: "0px 0px 16px 8px",
                                            gap: "8px",
                                            overflowY: "scroll",
                                            overflowX: "hidden",
                                            width: "100%",
                                            height: "472px"
                                        }}
                                        >

                                            {this.state.show_log_input ?
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "16px",
                                                        width: "100%",
                                                        height: "230px",
                                                        marginRight: "8px",
                                                    }}
                                                >
                                                    <MDEditor
                                                        value={this.state.log_input_text}
                                                        visibleDragbar={false}
                                                        hideToolbar={true}
                                                        preview="edit"
                                                        extraCommands={[]}
                                                        onChange={(data) => {
                                                            this.setState({log_input_text: data})
                                                        }}
                                                        height={200}
                                                    />
                                                    <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                        <button onClick={() => {
                                                            this.update_detection("", this.state.log_input_text)
                                                            this.setState({show_log_input: false, log_input_text: ""})
                                                        }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    borderRadius: "2px",
                                                                    background: "transparent",
                                                                    border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                    marginRight: "8px"
                                                                }}
                                                        >
                                                            <ISubmit style={{
                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                            }}/>
                                                            Submit
                                                        </button>
                                                        <button onClick={() => {
                                                            this.setState({show_log_input: false, log_input_text: ""})
                                                        }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    borderRadius: "2px",
                                                                    background: "transparent",
                                                                    border: "solid 1px #06B6D4",
                                                                    color: "#06B6D4",
                                                                    marginRight: "8px"
                                                                }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                                : ""
                                            }

                                            {
                                                this.state.modal_data["log"] ?

                                                    this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(item["time"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown source={item["text"]}
                                                                                   style={{
                                                                                       padding: "16px 16px 16px 16px",
                                                                                       color: "#A1A1AA",
                                                                                       background: "#18181B",
                                                                                       borderRadius: "8px",
                                                                                       whiteSpace: 'pre-wrap',
                                                                                       width: "100%",
                                                                                       height: "100%",
                                                                                       maxHeight: "100%",
                                                                                       overflow: "auto"
                                                                                   }}
                                                                />
                                                            </div>

                                                        </div>
                                                    )
                                                    :
                                                    ""
                                            }

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                    <div style={{color: "#737373"}}>
                                                        {get_time_string(this.state.modal_data["created"])}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown
                                                        source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                        style={{
                                                            padding: "16px 16px 16px 16px",
                                                            color: "#A1A1AA",
                                                            background: "#18181B",
                                                            borderRadius: "8px",
                                                            whiteSpace: 'pre-wrap',
                                                            width: "100%",
                                                            height: "100%",
                                                            maxHeight: "100%",
                                                            overflow: "auto"
                                                        }}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                </div>

                            </div>
                    }


                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showRFIModal}
                    contentLabel="RFIModal"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Request for Intelligence (RFI)
                                </div>
                            </div>


                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#27272A",
                                borderRadius: "8px",
                                padding: "8px",
                                width: "100%",
                                height: "90%",
                                gap: "12px",
                            }}
                            >
                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Requester:
                                    </div>
                                    {this.state.modal_data["user"]}
                                </div>

                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Threat Category:
                                    </div>
                                    {this.state.modal_data["category"]}
                                </div>

                                Intelligence Request

                                <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                                >
                                    <div style={{
                                        background: "#71717a",
                                        alignSelf: "stretch",
                                        flexShrink: "0",
                                        width: "1px"
                                    }}/>

                                    <MDEditor
                                        className={"scroll-editor"}
                                        value={this.state.modal_data["description"]}
                                        visibleDragbar={false}
                                        hideToolbar={true}
                                        preview="edit"
                                        extraCommands={[]}
                                        style={{
                                            width: "100%"
                                        }}
                                        height={108}
                                    />
                                </div>

                                Indicators of Compromise (IoC) to Investigate

                                <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                                >
                                    <div style={{
                                        background: "#71717a",
                                        alignSelf: "stretch",
                                        flexShrink: "0",
                                        width: "1px"
                                    }}/>

                                    <MDEditor
                                        className={"scroll-editor"}
                                        value={this.state.modal_data["investigate"]}
                                        visibleDragbar={false}
                                        hideToolbar={true}
                                        preview="edit"
                                        extraCommands={[]}
                                        style={{
                                            width: "100%"
                                        }}
                                        height={108}
                                    />
                                </div>

                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        RFI Urgency:
                                    </div>
                                    {this.state.modal_data["urgency"]}
                                </div>


                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Response Format:
                                    </div>
                                    {this.state.modal_data["format"]}
                                </div>

                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    gap: "12px",
                                    width: "100%",
                                    height: "40px",
                                    fontSize: "13px",
                                    letterSpacing: "0.01em",
                                    lineHeight: "20px",
                                    fontFamily: "Inter",
                                }}
                            >
                                <button
                                    className="menu-entry"
                                    onClick={() => console.log("update rfi")}
                                    id="0"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "New" ?
                                                    "rgba(244, 63, 94, 100)" : "white",
                                            }}
                                        >
                                            New
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className="menu-entry"
                                    onClick={() => console.log("update rfi")}
                                    id="1"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Pending" ?
                                                    "rgba(245, 158, 11, 100)" : "white",
                                            }}
                                        >
                                            Pending
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={() => console.log("update rfi")}
                                    id="2"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%",
                                        marginLeft: "auto"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Closed" ?
                                                    "rgba(6, 182, 212, 100)" : "white",
                                            }}
                                        >
                                            Closed
                                        </div>
                                    </div>
                                </button>

                            </div>

                        </div>


                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}
                            >
                                <div style={{color: "#F9B233"}}>
                                    Action History
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={() => {
                                        this.setState({showRFIModal: false})
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>


                            <div className="inner-tip-box" style={{
                                height: "100%",
                                gap: "16px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/tiphistory.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Action History Log
                                    </div>

                                    <button
                                        style={{
                                            cursor: "pointer",
                                            borderRadius: "2px",
                                            border: "none",
                                            backgroundColor: "rgba(6,182,212,0.25)",
                                            color: "#06B6D4",
                                            position: "relative",
                                            marginLeft: "auto"
                                        }}
                                        id="new-update"
                                        onClick={() => this.setState({show_log_input: true})}
                                    >
                                        New Update
                                    </button>
                                </div>


                                <div className="scroll-bar-div" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0px 0px 16px 8px",
                                    gap: "8px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: "100%",
                                    height: "472px"
                                }}
                                >

                                    {this.state.show_log_input ?
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "230px",
                                                marginRight: "8px",
                                            }}
                                        >
                                            <MDEditor
                                                value={this.state.log_input_text}
                                                visibleDragbar={false}
                                                hideToolbar={true}
                                                preview="edit"
                                                extraCommands={[]}
                                                onChange={(data) => {
                                                    this.setState({log_input_text: data})
                                                }}
                                                height={200}
                                            />
                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                <button onClick={() => {
                                                    if (this.state.log_input_text !== "") {
                                                        this.update_detection("", this.state.log_input_text)
                                                        this.setState({show_log_input: false, log_input_text: ""})
                                                    }
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    <ISubmit style={{
                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                    }}/>
                                                    Submit
                                                </button>
                                                <button onClick={() => {
                                                    this.setState({show_log_input: false, log_input_text: ""})
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px #06B6D4",
                                                            color: "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    {
                                        this.state.modal_data["log"] ?

                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "8px",
                                                    gap: "8px"
                                                }}
                                                     id="history-log-entry"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                        <div style={{color: "#737373"}}>
                                                            {get_time_string(item["time"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "8px",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: "#71717a",
                                                            alignSelf: "stretch",
                                                            flexShrink: "0",
                                                            width: "1px"
                                                        }}/>
                                                        <MDEditor.Markdown source={item["text"]}
                                                                           style={{
                                                                               padding: "16px 16px 16px 16px",
                                                                               color: "#A1A1AA",
                                                                               background: "#18181B",
                                                                               borderRadius: "8px",
                                                                               whiteSpace: 'pre-wrap',
                                                                               width: "100%",
                                                                               height: "100%",
                                                                               maxHeight: "100%",
                                                                               overflow: "auto"
                                                                           }}
                                                        />
                                                    </div>

                                                </div>
                                            )
                                            :
                                            ""
                                    }

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginRight: "8px",
                                        gap: "8px"
                                    }}
                                         id="history-log-entry"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                        >
                                            <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                            <div style={{color: "#737373"}}>
                                                {get_time_string(this.state.modal_data["submitted"])}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "8px",
                                            gap: "8px"
                                        }}
                                        >
                                            <div style={{
                                                background: "#71717a",
                                                alignSelf: "stretch",
                                                flexShrink: "0",
                                                width: "1px"
                                            }}/>
                                            <MDEditor.Markdown
                                                source={"New RFI Created"}
                                                style={{
                                                    padding: "16px 16px 16px 16px",
                                                    color: "#A1A1AA",
                                                    background: "#18181B",
                                                    borderRadius: "8px",
                                                    whiteSpace: 'pre-wrap',
                                                    width: "100%",
                                                    height: "100%",
                                                    maxHeight: "100%",
                                                    overflow: "auto"
                                                }}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPlaybook}
                    contentLabel="Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                            gap: "8px"
                        }}
                        >
                            <img
                                alt="playbook"
                                src="/main/playbook-i.svg"
                            />
                            <div style={{color: "#F9B233"}}>
                                {this.state.playbook.title}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPlaybook: false})}
                            >
                                <img
                                    alt="close-playbook"
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div className="inner-tip-box" style={{
                            height: "100%",
                            gap: "16px"
                        }}>
                            <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "520px"
                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/playbook-obj-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Objective
                                    </div>
                                </div>
                                <div>
                                    {this.state.playbook.objective}
                                </div>


                                {
                                    this.state.playbook["steps"] ?
                                        this.state.playbook["steps"].map((item, i) =>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src={"/main/playbook-" + (i + 1).toString() + "-i.svg"}
                                                         alt="playbook-num"/>
                                                    <div style={{color: "#a1daf8"}}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown source={item.text}
                                                                       style={{
                                                                           padding: "16px 8px 16px 8px",
                                                                           color: "#A1A1AA",
                                                                           background: "#18181B",
                                                                           borderRadius: "8px",
                                                                           width: "100%",
                                                                           height: "100%",
                                                                           maxHeight: "100%",
                                                                           overflow: "auto"
                                                                       }}
                                                    />
                                                </div>

                                            </div>
                                        )
                                        :
                                        ""
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: "8px",
                                    gap: "8px"
                                }}
                                >
                                    <div style={{alignSelf: "stretch", flexShrink: "0"}}>
                                        <img src="/main/playbook-note-i.svg" alt="playbook-note"/>
                                    </div>
                                    <div style={{color: "#A1A1AA"}}>
                                        {this.state.playbook.note}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </ReactModal>


                <div className="Pulse-Box" style={{
                    right: "630px",
                    width: "200px"
                }}>
                    <div className="pulse-div" style={{
                        backgroundColor: "rgba(12,116,220,0.3)"
                    }}/>
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    Supply Chain Pulse: {this.getLatestPulse()}ms
                </div>

                <div className="Pulse-Box" style={{
                    right: "440px"
                }}>
                    <div className="pulse-div" style={{
                        backgroundColor: "rgba(73,2,2,0.3)"
                    }}/>
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    C2 IoA Pulse: {this.getLatestPulse()}ms
                </div>


                <div className="Pulse-Box">
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    ETI Pulse: {this.getLatestPulse()}ms
                </div>

                <div style={{
                    width: "100%",
                    height: "1%",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Zero-Hour Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("Zero-Day Detections")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(239,68,68)",
                                     animation: this.state.d[0] + this.state.d[1] + this.state.d[2] + this.state.d[3] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >


                                <CircularProgressbarWithChildren
                                    value={this.state.d[0] + this.state.d[1] + this.state.d[2] + this.state.d[3]}
                                    strokeWidth={5}
                                    styles={buildStyles({
                                        pathTransitionDuration: 0.5,
                                        trailColor: '#27272A',
                                        pathColor: 'rgba(239,68,68,100)'
                                    })}>

                                    <div
                                        style={{marginBottom: -20}}>{this.state.d[0] + this.state.d[1] + this.state.d[2] + this.state.d[3]}</div>
                                    <img alt="" src="/main/dividor.svg"/>
                                    <div style={{
                                        marginTop: -20,
                                        color: 'rgba(239,68,68,0.35)'
                                    }}>{this.state.p_val[0] + this.state.p_val[1] + this.state.p_val[2] + this.state.p_val[3]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                >
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P1")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        ShadowBreach
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("ShadowBreach")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                }}
                            >
                                <CircularProgressbarWithChildren value={0} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(139,92,246)'
                                                                 })}>

                                    0
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(139,92,246,0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#8B5CF6",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            DISCOVERY
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Supply Chain Risk
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("Supply Chain Risk")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(16,185,129)",
                                     animation: this.state.d[4] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.s[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(16,185,129,0.35)'
                                                                 })}>
                                    <div style={{marginBottom: -20}}>{this.state.d[4]}</div>
                                    <img alt="" src="/main/dividor.svg"/>
                                    <div style={{
                                        marginTop: -20,
                                        color: 'rgba(16,185,129,0.35)'
                                    }}>{this.state.s_val[0]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(16,185,129,0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#10B981",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            DISCOVERY
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/endpointprot-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Endpoint Protection
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>


                                <Dropdown className="RFI-Dropdown-defaults">
                                    <Dropdown.Toggle className="RFI-Dropdown-defaults">
                                        <div style={{width: "100%", textAlign: "left", display: "flex", gap: "8px"}}>
                                            {this.state.rfi_priority}
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="RFI-Dropdown-menu">
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "MS Defender"})}>
                                            MS Defender
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "CrowdStrike"})}>
                                            CrowdStrike
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "SentinelOne"})}>
                                            SentinelOne
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "WithSecure"})}>
                                            WithSecure
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "Paloalto Cortex"})}>
                                            Paloalto Cortex
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "Cynet"})}>
                                            Cynet
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "Sophos"})}>
                                            Sophos
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "CyberReason"})}>
                                            CyberReason
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "DeepInstinct"})}>
                                            DeepInstinct
                                        </Dropdown.Item>
                                        <Dropdown.Item className="RFI-Dropdown-item"
                                                       onClick={() => this.setState({rfi_priority: "Other"})}>
                                            Other
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "white",
                                        gap: "16px",
                                        fontSize: "16px",
                                    }}>
                                        <div className="SNumberBox" style={{
                                            backgroundColor: "transparent",
                                            width: "130px",
                                            justifyContent: "flex-start"
                                        }}>
                                            Servers
                                        </div>
                                        <div className="SNumberBox" style={{
                                            backgroundColor: "transparent",
                                            width: "130px",
                                            justifyContent: "flex-start"
                                        }}>
                                            Workstations
                                        </div>
                                        <div className="SNumberBox" style={{
                                            backgroundColor: "transparent",
                                            width: "130px",
                                            justifyContent: "flex-start"
                                        }}>
                                            Mobile
                                        </div>
                                        <div className="SNumberBox" style={{
                                            backgroundColor: "transparent",
                                            width: "130px",
                                            justifyContent: "flex-start"
                                        }}>
                                            Managed
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        gap: "16px",
                                        fontSize: "16px",
                                    }}>
                                        <div className="SNumberBox">
                                            27
                                        </div>
                                        <div className="SNumberBox">
                                            50
                                        </div>
                                        <div className="SNumberBox">
                                            4
                                        </div>
                                        <div className="SNumberBox">
                                            77
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/panalysis.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Log Archiving & Retention
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Log Archiving & Retention")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div className="SNumberBox" style={{width: "100px"}}>
                                        Firewall
                                    </div>
                                    <img
                                        alt=""
                                        src="/main/kkfire-circle.svg"
                                    />

                                    <div className="SNumberBox" style={{width: "40px", backgroundColor: "transparent"}}>
                                        700MB
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div className="SNumberBox" style={{width: "100px"}}>
                                        Syslogs
                                    </div>
                                    <img
                                        alt=""
                                        src="/main/kkfire-circle.svg"
                                    />

                                    <div className="SNumberBox" style={{width: "40px", backgroundColor: "transparent"}}>
                                        600MB
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div className="SNumberBox" style={{width: "100px"}}>
                                        Eventlog
                                    </div>
                                    <img
                                        alt=""
                                        src="/main/kkfire-circle.svg"
                                    />

                                    <div className="SNumberBox" style={{width: "40px", backgroundColor: "transparent"}}>
                                        300MB
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div className="SNumberBox" style={{width: "100px"}}>
                                        ETI
                                    </div>
                                    <img
                                        alt="circle"
                                        src="/main/green-circle.svg"
                                    />

                                    <div className="SNumberBox" style={{width: "40px", backgroundColor: "transparent"}}>
                                        400MB
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "16px",
                                    fontSize: "16px",
                                    color: "white",
                                }}
                                >
                                    <div className="SNumberBox" style={{width: "100px"}}>
                                        Size
                                    </div>
                                    <img
                                        alt=""
                                        src="/main/kkfire-circle.svg"
                                    />

                                    <div className="SNumberBox" style={{width: "20px", backgroundColor: "transparent"}}>
                                        2GB
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-K2I-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/bprotection-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            K2i Brand Protection
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                    }}
                                >
                                    <div className="SNumberBox">
                                        2
                                    </div>
                                    <img
                                        style={{
                                            position: "relative",
                                            borderRadius: "999px",
                                        }}
                                        alt="trending-up"
                                        src="/main/avatar8.svg"
                                    />
                                    <div
                                        style={{
                                            flex: "1",
                                            height: "41px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            textAlign: "left",
                                            fontSize: "14px",
                                            color: "#fafafa",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Spoofed Domains
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            +2
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                    }}
                                >
                                    <div>
                                        216.194.165.146
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                    }}
                                >
                                    <div>
                                        208.91.197.27
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Col-Div"
                            style={{
                                height: this.getMapBoxHeight() - 8,
                                minWidth: "608px"
                            }}
                        >


                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                height: "50%",
                                width: "100%"
                            }}
                            >
                                <div
                                    className="Data-Card"
                                    id="packet-card"
                                    style={{
                                        maxWidth: "220px",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/panalysis.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    Packet Analysis
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Packet Analysis")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                width: "100%",
                                                height: "75%"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "left",
                                                gap: "16px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "16px"
                                                }}
                                                >
                                                    <div className="SNumberBox" style={{width: "100px"}}>
                                                        ETI
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="/main/eti-circle.svg"
                                                    />

                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            height: "41px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            justifyContent: "flex-start",
                                                            textAlign: "left",
                                                            color: "#fafafa",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                alignSelf: "stretch",
                                                                position: "relative",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            {this.getPackets()}
                                                        </div>
                                                        <div
                                                            style={{
                                                                alignSelf: "stretch",
                                                                position: "relative",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                color: "#a3a3a3",
                                                            }}
                                                        >
                                                            / sec
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "16px"
                                                }}
                                                >
                                                    <div className="SNumberBox" style={{width: "100px"}}>
                                                        ShadowFire
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="/main/kkfire-circle.svg"
                                                    />

                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            height: "41px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "flex-start",
                                                            justifyContent: "flex-start",
                                                            textAlign: "left",
                                                            color: "#fafafa",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                alignSelf: "stretch",
                                                                position: "relative",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            0
                                                        </div>
                                                        <div
                                                            style={{
                                                                alignSelf: "stretch",
                                                                position: "relative",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                color: "#a3a3a3",
                                                            }}
                                                        >
                                                            / sec
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="Data-Card"
                                    id="threat-hunting"
                                    style={{
                                        height: "100%",
                                        maxWidth: "calc(100% - 220px)"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/active-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    Threat Intelligence (Threat Hunting)
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("ETI Threat Hunting")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                                justifyContent: "flex-start",
                                                padding: "10px 10px 10px 10px",
                                                gap: "8px",
                                                textAlign: "start",
                                                fontSize: "12px",
                                                color: "#fff",
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                marginTop: "-15px",
                                                marginRight: "auto",
                                                background: "#27272a"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    overflow: "hidden"
                                                }}
                                                ref={this.eti_feed_ref}
                                            >
                                                {this.state.eti_feed.map((item, i) =>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        overflow: "hidden",
                                                        color: item.includes("kryptocti") || item.includes("KryptoKloud Ltd") ? "#06b6d4" : "white"
                                                    }}>{item}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="Data-Card"
                                id="ep-card"
                                style={{
                                    minWidth: "500px"
                                }}
                            >
                                <div
                                    style={{
                                        flex: "1",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px",
                                        gap: "24px",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "4px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                                alt=""
                                                src="/main/eponline-i.svg"
                                            />
                                            <div
                                                style={{
                                                    flex: "1",
                                                    position: "relative",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    marginLeft: "3px",
                                                }}
                                            >
                                                Endpoint Statistics
                                            </div>
                                            <MuiTooltip title={this.props.tooltip_info("Endpoint Statistics")} arrow>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        overflow: "hidden",
                                                        flexShrink: "0",
                                                    }}
                                                    alt=""
                                                    src="/main/detection-i.svg"
                                                />
                                            </MuiTooltip>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            color: "#fff",
                                            width: "100%",
                                            height: "75%"
                                        }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            gap: "16px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "16px"
                                            }}
                                            >
                                                <div className="SNumberBox">
                                                    Active
                                                </div>
                                                <img
                                                    alt=""
                                                    src="/main/active-circle.svg"
                                                />

                                                <div
                                                    style={{
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {this.getOnlineNumber()} / {this.getEndpointLimit()}
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "16px"
                                            }}
                                            >
                                                <div className="SNumberBox">
                                                    Trend
                                                </div>
                                                <img
                                                    alt=""
                                                    src="/main/trend-circle.svg"
                                                />

                                                <div
                                                    style={{
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Week in Review
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: "stretch",
                                            width: "65%",
                                            display: "flex",
                                            marginLeft: "auto",
                                        }}>

                                            <Line
                                                data={{
                                                    labels: [this.getDayLabel((new Date()).getDay() - 7), '', '', this.getDayLabel((new Date()).getDay() - 6), '', '', '', this.getDayLabel((new Date()).getDay() - 5), '', '', '', this.getDayLabel((new Date()).getDay() - 4), '', '', '', this.getDayLabel((new Date()).getDay() - 3), '', '', '', this.getDayLabel((new Date()).getDay() - 2), '', '', '', this.getDayLabel((new Date()).getDay() - 1), '', '', '', 'current'],
                                                    datasets: [
                                                        {
                                                            fill: false,
                                                            label: 'Live',
                                                            data: Array.from({length: 28}, this.getOnlineNumber),
                                                            borderColor: '#eab308',
                                                            backgroundColor: '#eab308',
                                                        },
                                                        {
                                                            fill: true,
                                                            label: 'Endpoints',
                                                            data: this.getOnlineHistory(),
                                                            borderColor: '#06b6d4',
                                                            backgroundColor: '#164E63',
                                                        },

                                                    ],
                                                }}
                                                options={{
                                                    scales: {
                                                        y: {ticks: {stepSize: 10}, min: 0},
                                                        x: {
                                                            ticks: {
                                                                autoSkip: false
                                                            }
                                                        }
                                                    },
                                                    responsive: true,
                                                    plugins: {
                                                        legend: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            render: 'label',
                                                            arc: true,
                                                            position: 'outside'
                                                        }
                                                    },
                                                    scaleShowValues: true,
                                                    animation: {duration: 500},
                                                    maintainAspectRatio: false
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className="Col-Div"
                            style={{
                                position: "relative",
                                width: "calc((100%/2.75) * 0.75 - 8px)"
                            }}
                        >
                            <div style={{
                                borderRadius: "8px",
                                justifyContent: "flexStart",
                                boxSizing: "border-box",
                                width: "100%",
                                gap: "8px",
                            }}
                                 ref={this.map_box_ref}
                                 id="Map-Card">
                                <div
                                    style={{
                                        backgroundColor: "#18181b",
                                        borderRadius: "8px",
                                        boxSizing: "border-box",
                                        gap: "24px",
                                        padding: "16px 24px 16px 16px",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/map-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                        color: "#84CC16",
                                                    }}
                                                >
                                                    K2i Cyber Threat Map
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Geo-Zone Threat Map")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>

                                        </div>

                                        <div ref={this.map_ref} style={{
                                            width: "100%",
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                marginLeft: "20px",
                                                marginTop: "20px"
                                            }}>
                                                <img width={this.getMapWidth() - 19} height={this.getMapHeight() - 60}
                                                     src="/main/crosshair.svg" alt=""/>
                                            </div>
                                            <div style={{
                                                position: "absolute",
                                                marginLeft: "20px",
                                                marginTop: "20px",
                                                transform: "scale(0.8)",
                                                animation: "rotateLine 10s linear infinite",
                                            }}>
                                                <img width={(this.getMapWidth() - 19)}
                                                     height={(this.getMapHeight() - 60)} src="/main/circle-scanner.svg"
                                                     alt=""/>
                                            </div>
                                            <div>
                                                <WorldMap backgroundColor="#18181B" color="red"
                                                          size={this.getMapWidth()} data={this.state.map_data}
                                                          styleFunction={getStyle}

                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Col-Div"
                            style={{
                                height: this.getMapBoxHeight() - 8,
                            }}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                height: "50%",
                                width: "100%"
                            }}
                            >
                                <div
                                    className="Detection-Card"
                                    id="WiFi-Risk"
                                    style={{
                                        width: "50%",
                                        minHeight: "260px"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        animation: "light-pulse 2s alternate infinite"
                                                    }}
                                                    alt=""
                                                    src="/main/wifi-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",

                                                    }}
                                                >
                                                    K2i Wi-Fi Hijacking
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Wi-Fi Risk")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    position: "relative",
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                    color: "#a3a3a3",
                                                }}
                                            >
                                                ETI Monitoring of WiFi Connections
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "20px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                maxHeight: "100%",
                                                width: "100%",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%"
                                            }}>
                                                <div style={{width: "150px"}}>
                                                    High Risk Sessions
                                                </div>

                                                <div className="SNumberBox" style={{
                                                    color: "#EF4444",
                                                    marginLeft: "auto"
                                                }}>
                                                    0
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%"
                                            }}>
                                                <div style={{width: "120px"}}>
                                                    Total Sessions
                                                </div>
                                                <div className="SNumberBox" style={{
                                                    color: "#10B981",
                                                    marginLeft: "auto"
                                                }}>
                                                    2
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="Detection-Card"
                                    id="session-hijacking"
                                    style={{
                                        width: "50%",
                                        minHeight: "260px"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/hijack-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",

                                                    }}
                                                >
                                                    K2i Session Hijacking
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Session Hijacking")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt="detection-i"
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    position: "relative",
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                    color: "#a3a3a3",
                                                }}
                                            >
                                                ETI Discovery
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "12px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                maxHeight: "100%",
                                                width: "100%",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            >
                                                <div style={{width: "130px"}}>
                                                    Hijacked Session
                                                </div>

                                                <div className="SNumberBox" style={{
                                                    color: "#EF4444"
                                                }}>
                                                    1
                                                </div>
                                                <div>
                                                    Suspicious
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <div style={{width: "100px"}}>
                                                    Fingerprinting
                                                </div>
                                                <div style={{
                                                    color: "#10B981"
                                                }}>
                                                    136,044
                                                </div>
                                                <img src="/main/avatar8.svg" alt="trending-up"/>
                                                <div
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#a3a3a3",
                                                    }}
                                                >
                                                    24h
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                height: "50%",
                                width: "100%"
                            }}
                            >
                                <div
                                    className="Detection-Card"
                                    id="insider-risk"
                                    style={{
                                        width: "50%",
                                        minHeight: "260px"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/insider-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    Insider Threat
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Insider Threat")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    position: "relative",
                                                    fontSize: "14px",
                                                    lineHeight: "20px",
                                                    color: "#a3a3a3",
                                                }}
                                            >
                                                ETI Discovery
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                maxHeight: "100%",
                                                width: "100%",
                                                overflow: "hidden"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            >
                                                <div style={{width: "162px"}}>
                                                    Suspicious Behaviour
                                                </div>

                                                <div className="SNumberBox" style={{
                                                    color: "#EF4444"
                                                }}>
                                                    3
                                                </div>
                                                <div>
                                                    Endpoints
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <div>
                                                    Benchmarked
                                                </div>
                                                <div style={{
                                                    color: "#10B981"
                                                }}>
                                                    10,102
                                                </div>
                                                <img src="/main/avatar8.svg" alt="trending-up"/>
                                                <div
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#a3a3a3",
                                                    }}
                                                >
                                                    24h
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="Detection-Card"
                                    id="phishing-card"
                                    style={{
                                        width: "50%",
                                        minHeight: "260px"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/shield-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    K2i Phishing Shield
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                maxWidth: "260px"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                gap: "12px",
                                                fontSize: "16px",
                                                width: "100%"
                                            }}>
                                                <div
                                                    style={{
                                                        alignSelf: "stretch",
                                                        position: "relative",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        color: "#a3a3a3",
                                                        width: "50%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Score
                                                </div>
                                                <div
                                                    style={{
                                                        alignSelf: "stretch",
                                                        position: "relative",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        color: "#a3a3a3",
                                                    }}
                                                >
                                                    Reported as
                                                </div>
                                            </div>


                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                gap: "12px",
                                                fontSize: "16px",
                                                alignItems: "center",
                                                width: "100%"
                                            }}>
                                                <div className="SNumberBox" style={{
                                                    width: "50%",
                                                    backgroundColor: "#991B1B"
                                                }}>
                                                    10
                                                </div>
                                                <div>
                                                    Suspicious
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                gap: "12px",
                                                fontSize: "16px",
                                                alignItems: "center",
                                                width: "100%"
                                            }}>
                                                <div className="SNumberBox" style={{
                                                    width: "50%",
                                                    backgroundColor: "#854D0E"
                                                }}>
                                                    20
                                                </div>
                                                <div>
                                                    Likely
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                gap: "12px",
                                                fontSize: "16px",
                                                alignItems: "center",
                                                width: "100%"
                                            }}>
                                                <div className="SNumberBox" style={{
                                                    width: "50%",
                                                    backgroundColor: "#155E75"
                                                }}>
                                                    5
                                                </div>
                                                <div>
                                                    Potential
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "calc(100% - 8px)"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "100%",
                            borderRadius: "8px",
                            height: "100%"
                        }}
                        >

                            <div style={{
                                padding: "20px 24px 16px 16px",
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                lineHeight: "28px",
                                fontWeight: "600",
                                fontSize: "20px",
                                gap: "16px",
                                zIndex: "10"
                            }}
                            >
                                <button onClick={() => this.setState({table_tab_idx: 0})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 0 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IActivity style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 0 ? "#65A30D" : "#365314",
                                    }}/>
                                    K2i SIEM Log
                                </button>
                                <button onClick={() => this.setState({table_tab_idx: 1})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 1 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IRFI style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 1 ? "#65A30D" : "#365314",
                                    }}/>
                                    RFI Log
                                </button>
                                <button onClick={() => {
                                }}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 2 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IReporting style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 2 ? "#65A30D" : "#365314",
                                    }}/>
                                    Reporting
                                </button>
                            </div>

                            <ThemeProvider theme={this.tableTheme}
                            >
                                {
                                    this.state.table_tab_idx === 1 ?
                                        <MaterialReactTable
                                            enableRowActions={true}
                                            displayColumnDefOptions={{
                                                "mrt-row-actions": {
                                                    header: 'RFI'
                                                },
                                            }}
                                            renderRowActions={({row}) => (
                                                <button
                                                    className="menu-entry"
                                                    onClick={() => this.setState({
                                                        showRFIModal: true,
                                                        modal_data: row["original"]
                                                    })}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                    id="show-btn"
                                                >
                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "8px",
                                                            borderRadius: "2px",
                                                            padding: "7px",
                                                            backgroundColor: "#27272A"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                fontSize: "14px",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontFamily: "Inter",
                                                                color: "#fff",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            View
                                                        </div>
                                                        <img
                                                            style={{
                                                                position: "relative",
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            alt=""
                                                            src="/main/show-i.svg"
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            columns={rfi_columns} data={this.state.stats["rfi"]}
                                            initialState={{
                                                sorting: [{
                                                    id: 'urgency',
                                                    desc: false
                                                }]
                                            }}
                                        />
                                        :
                                        <MaterialReactTable
                                            enableRowActions={true}
                                            displayColumnDefOptions={{
                                                "mrt-row-actions": {
                                                    header: 'Tipper'
                                                },
                                            }}
                                            renderRowActions={({row}) => (
                                                <button
                                                    className="menu-entry"
                                                    onClick={() => this.OpenModal(row["original"])}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                    id="show-btn"
                                                >
                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "8px",
                                                            borderRadius: "2px",
                                                            padding: "7px",
                                                            backgroundColor: "#27272A",
                                                            width: "50px"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                fontSize: "14px",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontFamily: "Inter",
                                                                color: "#fff",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            View
                                                        </div>
                                                        <img
                                                            style={{
                                                                position: "relative",
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            alt=""
                                                            src="/main/show-i.svg"
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            columns={columns} data={this.getTableData()}
                                            initialState={{
                                                sorting: [{
                                                    id: 'priority',
                                                    desc: false
                                                }]
                                            }}
                                        />
                                }
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}