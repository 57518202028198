import React, { Component } from 'react'

export default class Interest extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <iframe src={"https://www.cognitoforms.com/KryptoKloud/CyberShadowsExpressionOfInterest"}
                    width={"100%"}
                    height={"100%"}/>
        )
    }
}
