import pako from "pako";
let wait_backend = false

function isObject(object) {
    return object != null && typeof object === 'object';
}

const network = {
    post_request: async function(path, body) {
        while (wait_backend) {
            await new Promise(r => setTimeout(r, 500));
        }
        wait_backend = true;
        let return_data = undefined
        let response = undefined
        await fetch('https://ti-endpoint.cybershadows.co.uk/front/v1/' + path, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(body)
        }).then((res) => {
            response = res
        }).catch((err) => {
            console.log("failed to fetch", err)
        })
        if (response && response.ok && response.headers.has("Content-Type")) {
            const type = response.headers.get("Content-Type")
            if (type === "application/zip") {
                let data = undefined
                await response.arrayBuffer().then((res) => {
                    data = res
                }).catch((err) => {
                    console.log("failed array buffer", err)
                })
                if (!data) {
                    return return_data
                }
                const decompressor = new pako.Inflate()
                decompressor.push(data)
                if (decompressor.err) {
                    console.log("decompress error", decompressor.msg)
                } else {
                    return_data = JSON.parse(new TextDecoder().decode(decompressor.result))
                }
            } else {
                await response.json().then((res) => {
                    return_data = res
                }).catch((err) => {
                    console.log("failed json parse", err)
                    return_data = undefined
                })
                if (isObject(return_data) && "error" in return_data && return_data["error"] === "invalid session") {
                    window.location.reload()
                }
            }
        }
        wait_backend = false;
        return return_data
    }
}

export default network;
