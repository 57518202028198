import React, { Component } from 'react'
import './mitre.css'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import Collapsible from 'react-collapsible';
import ReactModal from 'react-modal';
import Switch from "react-switch";
import Select from 'react-select'

function sort(object, inner){
    if (typeof object != "object" || object instanceof Array)
        return object;
    let keys = Object.keys(object);
    if (inner)keys.sort();
    let newObject = {};
    for (let i = 0; i < keys.length; i++){
        newObject[keys[i]] = sort(object[keys[i]], true)
    }
    return newObject;
}


export default class MitreAttack extends Component {

    constructor(props) {
        super(props);
        this.state = {username: '', company: '', key: '', keys: {}, mitre_data: undefined, threat_info: {}, show_all: false, all_malware: {}, filter: [],
            show_entry: {}, filter_val: []
        };
        let data = this.props.page_data()
        this.getMitreData = this.getMitreData.bind(this)
        this.getMitreEntry = this.getMitreEntry.bind(this)
        this.getTagCount = this.getTagCount.bind(this)
        this.getFilterEntries = this.getFilterEntries.bind(this)
        this.shouldBeHidden = this.shouldBeHidden.bind(this)
        //console.log("mitre", data)
    }

    shouldBeHidden(value) {
        let ret = true
        Object.entries(value).forEach(([key, value]) => {
            if (this.getTagCount(value["sub"], key) > 0 || this.state.show_all) {
                ret = false
            }
        })
        return ret
    }

    getFilterEntries() {
        let entries = []
        Object.keys(this.state.all_malware).forEach((item, index) => {
            let entry = {}
            entry["value"] = item
            entry["label"] = item
            entries.push(entry)
        })
        return entries
    }

    getTagCount(tag, all_keys) {
        let filter = this.state.threat_info;

        if (this.state.filter.length > 0) {
            let newFilter = {}
            Object.keys(filter).forEach((item) => {
                let found =  filter[item].some(r => this.state.filter.includes(r))
                if (found) {
                    newFilter[item] = filter[item]
                }
            })
            filter = newFilter
        }

        if (!all_keys) {
            if (tag in filter) {
                return 1
            }
        } else {
            if (all_keys in filter) {
                return 99
            }
            if (tag) {
                let count = 0
                for (let key of Object.keys(tag)) {
                    if (key in filter) {
                        count++
                    }
                }
                return count
            }
        }
        return 0
    }

    getMitreData() {
        fetch('data/mitre.json',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    this.setState({mitre_data: json})
                });
            }
        });

        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company;

        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/threat_info", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            let tags = {}
                            let malware = {}
                            json.data.forEach((item, index) => {
                                let all_tags = String(item["data"]["mitre"]).split(",")
                                all_tags.forEach((tag) => {
                                    let clean_tag = tag.replaceAll(" ", "")
                                    if (!clean_tag.includes("https") && clean_tag.length > 2) {
                                        if (clean_tag in tags) {
                                            tags[clean_tag].push(item["malware"])
                                        } else {
                                            tags[clean_tag] = [item["malware"]]
                                        }
                                    }
                                });
                                if (item["malware"].length > 2 && Object.keys(item["data"]).length > 2) {
                                    malware[item["malware"]] = 1
                                }
                            });

                            let data = this.props.page_data()
                            if (data["mitre_filter"] && data["mitre_filter"] !== "") {
                                this.props.open_mitre("")
                                let array = []
                                Array.from(data["mitre_filter"]).forEach((item, index) => {
                                    array.push(item["value"])
                                })
                                this.setState({filter: array, filter_val: data["mitre_filter"]})
                            }
                            this.setState({threat_info: tags, all_malware: malware})
                        }
                    });
                }
            }).catch((err) => {
                console.log("mitre", err)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("mitre", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("mitre")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        if (!this.state.mitre_data) {
            this.getMitreData()
        }
    }
    componentWillUnmount() {

    }

    mitreColor(per, opacity, text) {
        if (per > 75) {
            return "rgba(244,63,94," + opacity + ")"
        } else if (per > 50) {
            return "rgba(249,115,22," + opacity + ")"
        } else if (per > 25) {
            return "rgba(245,158,11," + opacity + ")"
        } else if (per > 0) {
            return "rgba(234,179,8," + opacity + ")"
        }
        if (text) {
            return "rgba(161,161,170," + opacity + ")"
        }
        return "rgba(113,113,122," + opacity + ")"
    }

    getMitreEntry(code, text, sub, current, total, parent) {
        let override = false
        if (current > total) {
            if (!parent) {
                current = total
                override = true
            }
        }
        let per = current*100/total
        if (parent) per = current*100/parent
        if (override)per = 100
        let color = this.mitreColor(per, 1)
        return (
            <div className="mitre-entry" style={{
                borderColor: color,
                backgroundColor: sub ? "#18181B" : "#27272A"
            }}>
                <div style={{
                    fontWeight: "600",
                    color: color
                }}>
                    {code}
                </div>
                <div style={{
                    display: "flex",
                    textAlign: "left",
                    gap: "4px",
                    width: "100%"
                }}>
                    {text}
                    <div hidden={total < 1} style={{
                        height: "25px",
                        marginLeft: "auto",
                        marginTop: "-2px",
                        borderRadius: "16px",
                        padding: "2px 10px",
                        backgroundColor: this.mitreColor(per, 0.25),
                        color: this.mitreColor(per, 1, true),
                    }}>
                        {current}/{total}
                    </div>

                </div>
            </div>
        )
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >
                <div
                    style={{
                    display: "flex",
                    width: "100%",
                    height: "20px",
                    fontSize: "16px",
                    marginTop: "8px",
                    gap: "8px",
                    justifyContent: "flex-start",
                    alignItems: "center"
                }}
                >
                    <div style={{
                        display: "flex",
                        width: "300px",
                        justifyContent: "center",
                        gap: "8px",
                        alignItems: "center"
                    }}>
                        Tactical View
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={(checked) => this.setState({show_all: checked})}
                            checked={this.state.show_all}
                            offColor="#27272A"
                            onColor="#27272A"
                            offHandleColor="#06B6D4"
                            onHandleColor="#10B981"
                            draggable={false}
                            width={35}
                            height={20}
                        />
                        Show all
                    </div>

                    <div style={{
                        marginTop: "8px",
                        minWidth: "300px",
                    }}>
                        <Select
                            isMulti
                            name="Malware"
                            options={this.getFilterEntries()}
                            className="mitre-react-select-container"
                            classNamePrefix="mitre-react-select"
                            placeholder={"Filter by malware..."}
                            styles={{
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "#18181b",
                                    "&:hover": {
                                        backgroundColor: "rgba(234,179,8,0.05)",
                                    }
                                }),
                            }}
                            value={this.state.filter_val}
                            onChange={(val) => {
                                let array = []
                                Array.from(val).forEach((item, index) => {
                                    array.push(item["value"])
                                })
                                this.setState({filter: array, filter_val: val})
                            }}
                        />
                    </div>

                </div>

                <div
                    className="cp-scroll-bar-div"
                    style={{
                        borderRadius: "8px",
                        backgroundColor: "#131313",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        padding: "32px 16px",
                        boxSizing: "border-box",
                        gap: "16px",
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#fafafa",
                        fontFamily: "Inter",
                        overflow: "scroll"
                    }}
                >
                    {
                        this.state.mitre_data ?
                        Object.entries(this.state.mitre_data).map(([key,value]) =>
                            <div hidden={this.shouldBeHidden(value)} style={{
                                display: "flex",
                                flexDirection: "column",
                                minWidth: "250px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                                >
                                    <div style={{
                                        fontSize: "18px",
                                        color: "#a1daf8"
                                    }}>
                                        {key}
                                    </div>
                                    <div style={{marginBottom: "8px"}}>
                                        {Object.keys(value).length} Techniques
                                    </div>
                                </div>


                                {
                                    Object.entries(value).map(([key,value]) =>
                                        this.getTagCount(value["sub"], key) > 0 || this.state.show_all ?
                                        <Collapsible trigger={this.getMitreEntry(key, value["name"], false, this.getTagCount(value["sub"], key), value["sub"] ? Object.keys(value["sub"]).length : 0)}>
                                            {
                                                value["sub"] ?
                                                    Object.entries(value["sub"]).map(([sub_key, sub_value]) =>
                                                        this.getMitreEntry(sub_key, sub_value["name"], true,
                                                            this.getTagCount(value["sub"], key) >= Object.keys(value["sub"]).length ?
                                                                99
                                                                :
                                                                this.getTagCount(sub_key),
                                                            0, Object.keys(value["sub"]).length)
                                                    )
                                                    :
                                                    ""
                                            }

                                        </Collapsible>
                                            : ""
                                    )
                                }
                            </div>
                        )
                            :
                            ""
                    }



                </div>
            </div>
        )
    }
}