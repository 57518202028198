import React, { Component } from 'react'
import {Link, Navigate} from 'react-router-dom'
import sha256 from 'js-sha256';
import QRCode from "react-qr-code";
import network from '../modules/network';

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '', password: '', otp: undefined, otp_secret: undefined, invalid: false, success: false, mfa_success: false};
        this.login = this.login.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.set_invalid = this.set_invalid.bind(this);
        this.get_page = this.get_page.bind(this);
    }

    get_page() {
        let page = new URLSearchParams(window.location.search).get("page")
        if (page) {
            return "/" + page
        }
        return "/dashboard"
    }

    set_invalid() {
        this.setState({invalid: true})
        setTimeout(function () {
            this.setState({invalid: false});
        }.bind(this), 3000);
    }

    login(e) {
        let jsonData = {
            "email": this.state.email,
            "password": sha256.sha256(this.state.password)
        }
        if (this.state.otp) jsonData["otp"] = this.state.otp
        network.post_request("sign-in", jsonData).then((response) => {
            if (response) {
                if (response["keys"]) {
                    this.props.user(response)
                    this.setState({mfa_success: true})
                } else if (response["secret"]) {
                    this.setState({success: true, otp_secret: response["secret"]})
                } else {
                    this.setState({success: true})
                }
            } else {
                this.set_invalid()
            }
        }).catch((err) => {
            this.set_invalid()
        })
        // fetch('https://ti-endpoint.cybershadows.co.uk/sign-in', {
        //     method: 'POST',
        //     mode: 'cors',
        //     body: JSON.stringify(jsonData)
        // }).then((response) => {
        //     if (response.ok) {
        //         response.json().then(json => {
        //             if (json["keys"]) {
        //                 this.props.user(json)
        //                 this.setState({mfa_success: true})
        //             } else if (json["secret"]) {
        //                 this.setState({success: true, otp_secret: json["secret"]})
        //             } else {
        //                 this.setState({success: true})
        //             }
        //         }).catch((err) => {
        //             this.set_invalid()
        //         });
        //     }
        // }).catch((err) => {
        //     this.set_invalid()
        // })
        e.preventDefault()
    }

    handleOTPChange(e) {
        let otp = undefined
        if (e.target.value.length > 6) {
            e.target.value = e.target.value.slice(0,6)
        }
        if (e.target.value !== "") {
            otp = parseInt(e.target.value)
        }
        this.setState({otp: otp});
    }

    handleEmailChange(e) {
        this.setState({email: e.target.value});
    }

    handlePasswordChange(e) {
        this.setState({password: e.target.value});
    }

    render() {
        return (
            <div className="App">
                <div className="auth-wrapper" style={{
                    backgroundImage: "url(/main/background-art.png)",
                    height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain"
                }}>
                    <div className="auth-inner">
                        {
                            this.state.success ?
                                <form onSubmit={this.login}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "20px"
                                        }}>
                                            <img src="/main/CyberShadows-Logo.svg" alt="banner logo" width={350} height={120}/>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            fontWeight: "600",
                                            fontSize: "26px"
                                        }}>
                                            Verify Your Identity
                                        </div>

                                        {
                                            this.state.otp_secret ?
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                    paddingTop: 32,
                                                    gap: 16
                                                }}>
                                                    <QRCode style={{
                                                        padding: "8px 8px 8px 8px",
                                                        background: "white",
                                                    }} value={"otpauth://totp/cybershadows.co.uk:"+this.state.email+"?algorithm=SHA1&digits=6&issuer=kryptokloud&period=30&secret=" + this.state.otp_secret} />
                                                    <div style={{color: "#EAB308"}}>
                                                        Please scan this QR Code with your preferred authenticator app to setup 2FA
                                                    </div>
                                                </div>
                                                :
                                                ""
                                        }

                                    </div>

                                    <div className="mb-3" style={{marginTop: "24px"}}>
                                        <label>OTP</label>
                                        <input
                                            value={this.state.otp}
                                            onChange={this.handleOTPChange}
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter one-time code"
                                            required={true}
                                            autoFocus={true}
                                        />
                                    </div>

                                    <div className="d-grid">
                                        <button className="btn btn-primary btn-submit">
                                            Continue
                                        </button>
                                        <div hidden={!this.state.invalid}>
                                            <img alt="" src="/main/icon-l5-red.svg"/>
                                            <p className="invalid">Invalid OTP</p>
                                        </div>
                                    </div>
                                </form>
                                :
                                <form onSubmit={this.login}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "20px"
                                        }}>
                                            <img src="/main/CyberShadows-Logo.svg" alt="banner logo" width={350} height={120}/>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            fontWeight: "600",
                                            fontSize: "26px"
                                        }}>
                                            Sign in
                                        </div>
                                    </div>
                                    <div className="mb-2" hidden={true}>
                                        Don't have an account? <Link className="links" to={'/sign-up'}>Sign up</Link>
                                    </div>

                                    <div className="mb-3">
                                        <label>Email address</label>
                                        <input
                                            value={this.state.email}
                                            onChange={this.handleEmailChange}
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter email"
                                            required={true}
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Password</label>
                                        <input
                                            value={this.state.password}
                                            onChange={this.handlePasswordChange}
                                            type="password"
                                            className="form-control"
                                            placeholder="Enter password"
                                            required={true}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck1"
                                            />
                                            <label className="custom-control-label" htmlFor="customCheck1">
                                                Remember me
                                            </label>

                                            <a href="#" className="links text-right">
                                                Forgot your password?
                                            </a>

                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button className="btn btn-primary btn-submit">
                                            Sign in
                                        </button>
                                        <div hidden={!this.state.invalid}>
                                            <img alt="" src="/main/icon-l5-red.svg"/>
                                            <p className="invalid">Invalid email or password</p>
                                        </div>
                                    </div>
                                </form>
                        }
                    </div>
                </div>
                {this.state.mfa_success && (<Navigate to={this.get_page()}  replace={true} />)}
            </div>
        )
    }
}