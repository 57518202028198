import React, { Component } from 'react'
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { ReactComponent as ISubmit } from '../icons/history_log/send.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";
import {createTheme, ThemeProvider, Tooltip as MuiTooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { Line, Bar } from 'react-chartjs-2';
import WorldMap, { CountryContext } from "react-svg-worldmap"
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import MDEditor from "@uiw/react-md-editor";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function get_status_color(status, transparent) {
    switch (status) {
        case "New":
            return transparent ? "rgba(244,63,94,0.25)" : "#F43F5E"
        case "Pending":
            return transparent ? "rgba(245,158,11,0.25)" : "#F59E0B"
        case "False Positive":
            return transparent ? "rgba(139,92,246,0.25)" : "#8B5CF6"
        case "Closed":
            return transparent ? "rgba(6,182,212,0.25)" : "#06B6D4"
        default:
            return ""
    }
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

function getISO(code, iso2) {
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

const getStyle = ({
                      countryValue,
                      countryCode,
                      minValue,
                      maxValue,
                      color,
                  }: CountryContext) => ({
    fill: countryValue ? color: "rgb(85,115,85)",
    fillOpacity: 0.5,
    stroke: "white",
    strokeWidth: 1,
    cursor: "pointer",
});


const columns = [
    {
        accessorKey: 'priority',
        header: 'Priority',
        size: 150,
    },
    {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
    },
    {
        accessorKey: 'host',
        header: 'Host / User',
        size: 150,
    },
    {
        accessorKey: 'domain',
        header: 'Domain',
        size: 150,
    },
    {
        accessorKey: 'breach_type',
        header: 'Breach Type',
        size: 150,
    },
    {
        accessorFn: (row) => row.country[0],
        header: 'Country',
        size: 150,
        Cell: ({ cell }) => (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyItems: "center",
                alignItems: "center"
            }}>
                <div>
                    {
                        cell.row.original["country"] === "" ?
                            <img alt="" height={24} src="/main/no-flag.svg"/>
                            :
                            <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)} />
                    }
                </div>
                <div>
                    {getISO(cell.row.original["country"])}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    color: get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'snow_ticket',
        header: 'SNOW Ticket',
        size: 150,
    },
    {
        accessorKey: 'detected',
        header: 'First Detected',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
    {
        accessorKey: 'updated',
        header: 'Last Updated',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default class SupplyChain extends Component {
    search_placeholder = {
        priority: "P",
        ioa: "-",
        country: ["-", ""],
        malware: "-",
        first_seen: "-",
        last_seen: "-"
    };
    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });

    constructor(props) {
        super(props);
        this.map_ref = React.createRef()
        this.state = {
            username: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0],
            p: [0, 0],
            p_val: [0, 0],
            table_data: [], map_data: [],
            showModal: false,
            modal_data: {},
            playbook: {},
            eti_feed: [],
            search_result: this.search_placeholder
        };
        this.getOnlineNumber = this.getOnlineNumber.bind(this);
        this.getEndpointLimit = this.getEndpointLimit.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.set_dials = this.set_dials.bind(this);
        this.getLatestPacket = this.getLatestPacket.bind(this);
        this.getLatestPulse = this.getLatestPulse.bind(this);
        this.getMapWidth = this.getMapWidth.bind(this)
        this.OpenModal = this.OpenModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.getCountryRender = this.getCountryRender.bind(this);
        this.getBreachDate = this.getBreachDate.bind(this);
        this.getBreachURL = this.getBreachURL.bind(this);
        this.update_detection = this.update_detection.bind(this);
        this.update_feed = this.update_feed.bind(this);
        this.getSearchColor = this.getSearchColor.bind(this);
        this.feed_cache = []
        let data = this.props.page_data()
        //console.log("dashboard", data)
        this.setState({username: data.username, key: data.keys[data.company], company: data.company, keys: data.keys})
    }

    OpenModal (data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        this.setState({ modal_data: data, showModal: true });
    }

    CloseModal () {
        this.setState({ modal_data: {}, showModal: false });
    }

    getCountryRender() {
        let code = getISO(this.state.modal_data["country"], true)
        let name = getISO(this.state.modal_data["country"])
        return (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                {name}
            </div>
        )
    }
    getBreachURL() {
        let url = "N/A"
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["group"]) {
                url = this.state.modal_data["risk"]["post_url"]
            } else url = this.state.modal_data["risk"]["url"]
        }
        let image

        if (url.includes(".onion")) {
            image = "/main/onion-link-i.svg"
            url = url.replaceAll(".", "[.]")
        } else {
            image = "/main/link-i.svg"
        }

        let full_url = url
        if (url.length > 40) {
            url = url.slice(0, 40) + "..."
        }
        return (
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                <img src={image} alt={"url-icon"}/>
                <a href={full_url} target="_blank"
                   rel="noreferrer"
                   style={{
                       color: "#06B6D4"
                   }}
                >
                    {url}
                </a>
            </div>
        )
    }

    getBreachDate() {
        let time = 0
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["risk"]["date"]) {
                time = this.state.modal_data["risk"]["date"]
            } else time = this.state.modal_data["risk"]["published"]
        }
        return (
            <div>
                {get_time_string(time)}
            </div>
        )
    }

    update_feed() {
        if (this.feed_cache.length > 1) {
            let feed = this.state.eti_feed
            if (feed.length > 3) {
                feed.splice(0, 1)
            }
            feed.push(this.feed_cache[0])
            this.feed_cache.splice(0, 1)
            if (this.feed_cache.length > 10) {
                this.feed_cache.length = []
            }
            this.setState({eti_feed: feed})
        }
    }

    getSearchColor() {
        switch(this.state.search_result.priority) {
            case "P1":
                return "#EF4444"
            case "P2":
                return "#F97316"
            case "P3":
                return "#EAB308"
            case "P4":
                return "#06B6D4"
            default:
                return "#A1A1AA"
        }
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company;
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/risk_stats", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        this.feed_cache = this.feed_cache.concat(json["feed"])
                        this.setState({stats: json})
                    });
                }
            }).catch((err) => {
                console.log("dashboard", err)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("riskpanel", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("riskpanel")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({username: data.username, key: data.keys[data.company], company: data.company, keys: data.keys, live_data: {}})
        }

        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );

        this.feed_update = setInterval(
            () => this.update_feed(),
            200
        );
    }

    update_detection(e, log) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "data": log ? log : parseInt(e.currentTarget.id)
        }
        // fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/update_detection", {
        //     method: 'POST',
        //     mode: 'cors',
        //     body: JSON.stringify(jsonData)
        // }).then((response) => {
        //     if (response.ok) {
        //         response.json().then(json => {
        //             if (json.status) {
        //                 let modal_data = this.state.modal_data
        //                 modal_data["status"] = json.status
        //                 this.setState({modal_data: modal_data})
        //             }
        //             if (json.log) {
        //                 let modal_data = this.state.modal_data
        //                 modal_data["log"] = json.log
        //                 this.setState({modal_data: modal_data})
        //             }
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("update_detection", err)
        // })
    }

    getMapWidth() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetWidth
        }
        return 200
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
        clearInterval(this.feed_update);
    }

    getOnlineNumber() {
        if (this.state.stats.online) {
            return this.state.stats.online[0]
        } else {
            return 0
        }
    }

    getEndpointLimit() {
        if (this.state.stats.limit) {
            return this.state.stats.limit
        } else {
            return 0
        }
    }

    getTableData() {
        let d = [0, 0]
        if (this.state.stats.detections) {
            let items = []
            let map_data = []
            for (let item of this.state.stats.detections) {
                items.push(item)
                switch (item.priority.charAt(0)) {
                    case 'C':
                        d[0]++
                        break
                    case 'S':
                        d[1]++
                        break
                }

                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function(key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            if (!deepEqual(this.state.table_data, items)) {
                this.setState({table_data: items, d: d, map_data: map_data})
            }
        }
        if (this.state.stats.s) {
            this.set_dials(d)
        }
        return this.state.table_data
    }


    set_dials(d) {
        let s = (d[1] / this.state.stats.s) * 100
        if (s !== this.state.p[1] || this.state.stats.s !== this.state.p_val[1]) {
            this.setState({
                p: [
                    d[0],
                    s
                ],
                p_val: [
                    0,
                    this.state.stats.s
                ]
            })
        }
    }

    getLatestPacket() {
        if (this.state.stats.packets) {
            return this.state.stats.packets.slice(-1)[0]
        } else {
            return 0
        }
    }

    getLatestPulse() {
        if (this.state.stats.pulse) {
            return this.state.stats.pulse
        } else {
            return 0
        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPlaybook}
                    contentLabel="Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "670px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                            gap: "8px"
                        }}
                        >
                            <img
                                alt="playbook"
                                src="/main/playbook-i.svg"
                            />
                            <div style={{color: "#F9B233"}}>
                                {this.state.playbook.title}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPlaybook: false})}
                            >
                                <img
                                    alt="close-playbook"
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div className="inner-tip-box" style={{
                            height: "100%",
                            gap: "16px"
                        }}>
                            <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "520px"
                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/playbook-obj-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Objective
                                    </div>
                                </div>
                                <div>
                                    {this.state.playbook.objective}
                                </div>


                                {
                                    this.state.playbook["steps"] ?
                                        this.state.playbook["steps"].map((item, i) =>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src={"/main/playbook-" + (i + 1).toString() + "-i.svg"}
                                                         alt="playbook-num"/>
                                                    <div style={{color: "#a1daf8"}}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown source={item.text}
                                                                       style={{
                                                                           padding: "16px 8px 16px 8px",
                                                                           color: "#A1A1AA",
                                                                           background: "#18181B",
                                                                           borderRadius: "8px",
                                                                           width: "100%",
                                                                           height: "100%",
                                                                           maxHeight: "100%",
                                                                           overflow: "auto"
                                                                       }}
                                                    />
                                                </div>

                                            </div>
                                        )
                                        :
                                        ""
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: "8px",
                                    gap: "8px"
                                }}
                                >
                                    <div style={{alignSelf: "stretch", flexShrink: "0"}}>
                                        <img src="/main/playbook-note-i.svg" alt="playbook-note"/>
                                    </div>
                                    <div style={{color: "#A1A1AA"}}>
                                        {this.state.playbook.note}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Risk Tipper"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Supply Chain Risk Tipper
                                </div>
                            </div>

                            <div className="inner-tip-box"
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt="host-icon"
                                        src="/main/tiphost.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Host Information
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="host-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "138px"
                                    }} id="section1">
                                        <div>Client IP:</div>
                                        <div>Hostname:</div>
                                        <div>Host Severity:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["src_ip"]}</div>
                                        <div>{this.state.modal_data["host"]}</div>
                                        <div>{this.state.modal_data["h_severity"]}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "268px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/breach-details-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Breach Details
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="group-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "138px"
                                    }} id="section1">
                                        <div>Supplier Domain:</div>
                                        <div>Contacted IP:</div>
                                        <div>Country:</div>
                                        <div>Breach Date:</div>
                                        <div>Breach Type:</div>
                                        <div>Threat Group:</div>
                                        <div>Source:</div>
                                        <div>Severity:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["domain"]}</div>
                                        <div>{this.state.modal_data["dest_ip"]}</div>
                                        {this.getCountryRender()}
                                        {this.getBreachDate()}
                                        <div>{this.state.modal_data["breach_type"]}</div>
                                        <div>{this.state.modal_data["group"] ? this.state.modal_data["group"] : "N/A"}</div>
                                        {this.getBreachURL()}
                                        <div>{this.state.modal_data["severity"]}</div>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    gap: "12px",
                                    width: "100%",
                                    height: "40px",
                                    fontSize: "13px",
                                    letterSpacing: "0.01em",
                                    lineHeight: "20px",
                                    fontFamily: "Inter",
                                    marginTop: "auto"
                                }}
                            >
                                <button
                                    className="menu-entry"
                                    onClick={this.update_detection}
                                    id="0"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "New" ?
                                                    "rgba(244, 63, 94, 100)" : "white",
                                            }}
                                        >
                                            New
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className="menu-entry"
                                    onClick={this.update_detection}
                                    id="1"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Pending" ?
                                                    "rgba(245, 158, 11, 100)" : "white",
                                            }}
                                        >
                                            Pending
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_detection}
                                    id="2"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%",
                                        marginLeft: "auto"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Closed" ?
                                                    "rgba(6, 182, 212, 100)" : "white",
                                            }}
                                        >
                                            Closed
                                        </div>
                                    </div>
                                </button>

                            </div>


                        </div>


                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}
                            >
                                <div style={{color: "#F9B233"}}>
                                    Action History
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={this.CloseModal}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>


                            <div className="inner-tip-box" style={{
                                height: "100%",
                                gap: "16px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/tiphistory.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Action History Log
                                    </div>

                                    <button
                                        style={{
                                            cursor: "pointer",
                                            borderRadius: "2px",
                                            border: "1px solid #06B6D4",
                                            backgroundColor: "transparent",
                                            color: "#06B6D4",
                                            position: "relative",
                                            marginLeft: "auto"
                                        }}
                                        id="playbook"
                                        onClick={() => this.setState({
                                            showPlaybook: true,
                                            playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                        })}
                                    >
                                        Playbook
                                    </button>

                                    <button
                                        style={{
                                            cursor: "pointer",
                                            borderRadius: "2px",
                                            border: "none",
                                            backgroundColor: "rgba(6,182,212,0.25)",
                                            color: "#06B6D4",
                                            position: "relative",
                                        }}
                                        id="new-update"
                                        onClick={() => this.setState({show_log_input: true})}
                                    >
                                        New Update
                                    </button>
                                </div>


                                <div className="scroll-bar-div" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0px 0px 16px 8px",
                                    gap: "8px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: "100%",
                                    height: "472px"
                                }}
                                >

                                    {this.state.show_log_input ?
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "230px",
                                                marginRight: "8px",
                                            }}
                                        >
                                            <MDEditor
                                                value={this.state.log_input_text}
                                                visibleDragbar={false}
                                                hideToolbar={true}
                                                preview="edit"
                                                extraCommands={[]}
                                                onChange={(data) => {
                                                    this.setState({log_input_text: data})
                                                }}
                                                height={200}
                                            />
                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                <button onClick={() => {
                                                    this.update_detection("", this.state.log_input_text)
                                                    this.setState({show_log_input: false, log_input_text: ""})
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    <ISubmit style={{
                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                    }}/>
                                                    Submit
                                                </button>
                                                <button onClick={() => {
                                                    this.setState({show_log_input: false, log_input_text: ""})
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px #06B6D4",
                                                            color: "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    {
                                        this.state.modal_data["log"] ?

                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "8px",
                                                    gap: "8px"
                                                }}
                                                     id="history-log-entry"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                        <div style={{color: "#737373"}}>
                                                            {get_time_string(item["time"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "8px",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: "#71717a",
                                                            alignSelf: "stretch",
                                                            flexShrink: "0",
                                                            width: "1px"
                                                        }}/>
                                                        <MDEditor.Markdown source={item["text"]}
                                                                           style={{
                                                                               padding: "16px 16px 16px 16px",
                                                                               color: "#A1A1AA",
                                                                               background: "#18181B",
                                                                               borderRadius: "8px",
                                                                               whiteSpace: 'pre-wrap',
                                                                               width: "100%",
                                                                               height: "100%",
                                                                               maxHeight: "100%",
                                                                               overflow: "auto"
                                                                           }}
                                                        />
                                                    </div>

                                                </div>
                                            )
                                            :
                                            ""
                                    }

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginRight: "8px",
                                        gap: "8px"
                                    }}
                                         id="history-log-entry"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                        >
                                            <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                            <div style={{color: "#737373"}}>
                                                {get_time_string(this.state.modal_data["detected"])}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "8px",
                                            gap: "8px"
                                        }}
                                        >
                                            <div style={{
                                                background: "#71717a",
                                                alignSelf: "stretch",
                                                flexShrink: "0",
                                                width: "1px"
                                            }}/>
                                            <MDEditor.Markdown
                                                source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                style={{
                                                    padding: "16px 16px 16px 16px",
                                                    color: "#A1A1AA",
                                                    background: "#18181B",
                                                    borderRadius: "8px",
                                                    whiteSpace: 'pre-wrap',
                                                    width: "100%",
                                                    height: "100%",
                                                    maxHeight: "100%",
                                                    overflow: "auto"
                                                }}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>

                </ReactModal>

                <div className="Pulse-Box">
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    ETI Pulse: {this.getLatestPulse()}ms
                </div>

                <div style={{
                    width: "100%",
                    height: "1%",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card"
                            ref={this.map_ref}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        VIP Zoning
                                    </div>
                                    <img
                                        style={{
                                            position: "relative",
                                            width: "24px",
                                            height: "24px",
                                            overflow: "hidden",
                                            flexShrink: "0",
                                        }}
                                        alt=""
                                        src="/main/detection-i.svg"
                                    />
                                </div>
                            </div>
                            <WorldMap backgroundColor="#18181B" color="red"
                                      size={this.getMapWidth()/1.5} data={this.state.map_data}
                                      styleFunction={getStyle}

                            />
                        </div>
                        <div
                            className="Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        ShadowBreach
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("ShadowBreach")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(139,92,246)",
                                     animation: this.state.d[0] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(139,92,246)'
                                                                 })}>
                                    <div>
                                        {this.state.d[0]}
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(139,92,246,0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#8B5CF6",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            DISCOVERY
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Supply Chain Risk
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("Supply Chain Risk")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(16,185,129)",
                                     animation: this.state.d[1] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(16,185,129,0.35)'
                                                                 })}>
                                    <div style={{marginBottom: -20}}>{this.state.d[1]}</div>
                                    <img alt="" src="/main/dividor.svg"/>
                                    <div style={{
                                        marginTop: -20,
                                        color: 'rgba(16,185,129,0.35)'
                                    }}>{this.state.p_val[1]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(16,185,129,0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#10B981",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            DISCOVERY
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="Detection-Card"
                            id="WiFi-Risk"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                animation: "light-pulse 2s alternate infinite"
                                            }}
                                            alt=""
                                            src="/main/wifi-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",

                                            }}
                                        >
                                            Wi-Fi Risk
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Wi-Fi Risk")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            position: "relative",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            color: "#a3a3a3",
                                        }}
                                    >
                                        ETI Monitoring of WiFi Connections
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "20px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxHeight: "100%",
                                        width: "100%",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div style={{width: "150px"}}>
                                            High Risk Sessions
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444",
                                            marginLeft: "auto"
                                        }}>
                                            0
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div style={{width: "120px"}}>
                                            Total Sessions
                                        </div>
                                        <div className="SNumberBox" style={{
                                            color: "#10B981",
                                            marginLeft: "auto"
                                        }}>
                                            2
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card"
                            id="VIP-Protection"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/vip-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Active Directory Integration
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxHeight: "100%",
                                        width: "100%",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div>
                                            Joiners
                                        </div>
                                        <img src="/main/avatar8.svg" alt="trending-up"
                                        style={{
                                            marginLeft: "auto"
                                        }}
                                        />
                                        <div className="SNumberBox" style={{
                                            color: "#10B981"
                                        }}>
                                            5
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div>
                                            Movers
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444",
                                            marginLeft: "auto"
                                        }}>
                                            0
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div>
                                            Leavers
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444",
                                            marginLeft: "auto"
                                        }}>
                                            0
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}>
                                        <div>
                                            PlumHound
                                        </div>
                                        <div className="SNumberBox" style={{
                                            color: "#10B981",
                                            marginLeft: "auto"
                                        }}>
                                            Active
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="Detection-Card"
                            id="session-hijacking"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/hijack-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",

                                            }}
                                        >
                                            Session Hijacking
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Session Hijacking")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt="detection-i"
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            position: "relative",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            color: "#a3a3a3",
                                        }}
                                    >
                                        ETI Discovery
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxHeight: "100%",
                                        width: "100%",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    >
                                        <div style={{width: "130px"}}>
                                            Hijacked Session
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444"
                                        }}>
                                            1
                                        </div>
                                        <div>
                                            Suspicious
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{width: "100px"}}>
                                            Fingerprinting
                                        </div>
                                        <div style={{
                                            color: "#10B981"
                                        }}>
                                            136,044
                                        </div>
                                        <img src="/main/avatar8.svg" alt="trending-up"/>
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            24h
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card"
                            id="insider-risk"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/insider-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Insider Risk
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Insider Risk")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt="detection-i"
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            position: "relative",
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            color: "#a3a3a3",
                                        }}
                                    >
                                        ETI Discovery
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxHeight: "100%",
                                        width: "100%",
                                        overflow: "hidden"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    >
                                        <div style={{width: "162px"}}>
                                            Suspicious Behaviour
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444"
                                        }}>
                                            3
                                        </div>
                                        <div>
                                            Endpoints
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <div>
                                            Benchmarked
                                        </div>
                                        <div style={{
                                            color: "#10B981"
                                        }}>
                                            10,102
                                        </div>
                                        <img src="/main/avatar8.svg" alt="trending-up"/>
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            24h
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="Detection-Card"
                            id="brand-card"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/bprotection-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Brand Protection (24h)
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Brand Protection")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                    }}
                                >
                                    <div className="SNumberBox">
                                        2
                                    </div>
                                    <img
                                        style={{
                                            position: "relative",
                                            borderRadius: "999px",
                                        }}
                                        alt="trending-up"
                                        src="/main/avatar8.svg"
                                    />
                                    <div
                                        style={{
                                            flex: "1",
                                            height: "41px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            textAlign: "left",
                                            fontSize: "14px",
                                            color: "#fafafa",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Spoofed Domains
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            +2
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        minWidth: "260px",
                                        width: "100%"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                                width: "50%",
                                                maxWidth: "120px",
                                                textAlign: "left"
                                            }}
                                        >
                                            Status
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            Domain
                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            maxWidth: "120px",
                                            backgroundColor: "#991B1B"
                                        }}>
                                            Identified
                                        </div>
                                        <div>
                                            None Outstanding
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            maxWidth: "120px",
                                            backgroundColor: "#854D0E"
                                        }}>
                                            Pending
                                        </div>
                                        <div>
                                            None Outstanding
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="Detection-Card"
                            id="phishing-card"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/shield-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Phishing Shield (24h)
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxWidth: "260px"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}>
                                    <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                                width: "50%",
                                                textAlign: "left"
                                            }}
                                        >
                                            Score
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            Reported as
                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            backgroundColor: "#991B1B"
                                        }}>
                                            10
                                        </div>
                                        <div>
                                            Suspicious
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            backgroundColor: "#854D0E"
                                        }}>
                                            20
                                        </div>
                                        <div>
                                            Likely
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            backgroundColor: "#155E75"
                                        }}>
                                            5
                                        </div>
                                        <div>
                                            Potential
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="Detection-Card"
                            id="dns-tunneling"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/dns-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            DNS Tunneling
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("DNS Tunneling")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt="detection-i"
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        maxWidth: "260px"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                                width: "50%",
                                                textAlign: "left"
                                            }}
                                        >
                                            Count
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            Severity
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            backgroundColor: "#854D0E"
                                        }}>
                                            1
                                        </div>
                                        <div>
                                            Medium
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            backgroundColor: "#155E75"
                                        }}>
                                            2
                                        </div>
                                        <div>
                                            Low
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card"
                            id="pickpocket"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/pickpocket-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            PickPocket
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("PickPocket")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        fontSize: "16px",
                                        color: "#fff",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                    }}
                                    >
                                        <div style={{width: "162px"}}>
                                            Pocket Litter ID
                                        </div>

                                        <div className="SNumberBox" style={{
                                            color: "#EF4444",
                                            width: "200px"
                                        }}>
                                            FIJFUE2341A
                                        </div>
                                    </div>
                                    <div className="console-div" style={{
                                        border: "1px solid grey",
                                        height: "100px"
                                    }}
                                    >
                                        <div className="inner-console-div">
                                            <div>
                                                IP:
                                            </div>
                                            <div>
                                                Country:
                                            </div>
                                            <div>
                                                Timestamp:
                                            </div>
                                        </div>
                                        <div className="inner-console-div" style={{
                                            color: "white"
                                        }}>
                                            <div>
                                                {this.state.search_result.ioa}
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "5px"
                                            }}>
                                                {
                                                    this.state.search_result.country[1] === "" ?
                                                        <img alt="" height={20} src="/main/no-flag.svg"/>
                                                        :
                                                        <CircleFlag height={20}
                                                                    countryCode={this.state.search_result.country[1]}/>
                                                }

                                                {this.state.search_result.country[0]}
                                            </div>
                                            <div>
                                                {this.state.search_result.first_seen}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card"
                            id="domain-takedown"
                            style={{
                                minHeight: "260px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/domain-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            Domain Takedown
                                        </div>
                                        <MuiTooltip title={this.props.tooltip_info("Domain Takedown")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt=""
                                                src="/main/detection-i.svg"
                                            />
                                        </MuiTooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        color: "#fff",
                                        minWidth: "260px",
                                        width: "100%"
                                    }}
                                >
                                    <Form.Control style={{
                                        background: "#27272A",
                                        color: "white"
                                    }} type="text" placeholder="Search Domain"/>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        width: "100%"
                                    }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                                width: "50%",
                                                maxWidth: "120px",
                                                textAlign: "left"
                                            }}
                                        >
                                            Status
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                color: "#a3a3a3",
                                            }}
                                        >
                                            Domain
                                        </div>
                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            maxWidth: "120px",
                                            backgroundColor: "#991B1B"
                                        }}>
                                            Reported
                                        </div>
                                        <div>
                                            None Outstanding
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "12px",
                                        fontSize: "16px",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                        <div className="SNumberBox" style={{
                                            width: "50%",
                                            maxWidth: "120px",
                                            backgroundColor: "#854D0E"
                                        }}>
                                            Pending
                                        </div>
                                        <div>
                                            None Outstanding
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "calc(100% - 8px)"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "100%",
                            borderRadius: "8px",
                            height: "100%"
                        }}
                        >

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "16px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <div style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    borderRadius: "4px 4px 0 0",
                                    alignItems: "center",
                                    backgroundColor: "#27272A",
                                    color: "#F9B233",
                                    padding: "4px 16px",
                                    gap: "4px",
                                    marginBottom: "-10px"
                                }}>
                                    <IDetections style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#65A30D",
                                    }}/>
                                    Risk Log
                                </div>
                            </div>


                            <ThemeProvider theme={this.tableTheme}>
                                <MaterialReactTable
                                    enableRowActions={true}

                                    renderRowActions={({row}) => (
                                        <button
                                            className="menu-entry"
                                            onClick={() => this.OpenModal(row["original"])}
                                            style={{
                                                width: "100px"
                                            }}
                                            id="show-btn"
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    borderRadius: "8px",
                                                    padding: "7px",
                                                    backgroundColor: "#27272A"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontSize: "14px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    TTO
                                                </div>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                    alt=""
                                                    src="/main/show-i.svg"
                                                />
                                            </div>
                                        </button>
                                    )}
                                    columns={columns} data={this.getTableData()}
                                    initialState={{
                                        sorting: [{
                                            id: 'updated',
                                            desc: true,
                                        }]
                                    }}
                                >
                                </MaterialReactTable>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}