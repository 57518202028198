import React, {Component} from 'react'
import {ReactComponent as IPlus} from '../icons/plus-i.svg';
import {ReactComponent as ITemplate} from '../icons/template-i.svg';
import {ReactComponent as ICOBRA} from '../icons/grc/cobra.svg';
import {ReactComponent as ICOBRAP} from '../icons/grc/cobra+.svg';
import {ReactComponent as IShieldCheck} from '../icons/shield-check-i.svg';
import {ReactComponent as IDORA} from '../icons/grc/dora.svg';
import {ReactComponent as IEssentials} from '../icons/grc/essentials.svg';
import {ReactComponent as IISO} from '../icons/grc/iso.svg';
import {ReactComponent as INIS} from '../icons/grc/nis.svg';
import {ReactComponent as INIST} from '../icons/grc/nist.svg';
import {ReactComponent as ITrash} from '../icons/trash-i.svg';
import {ReactComponent as IChecked} from '../icons/fda/box-checked-i.svg';
import {ReactComponent as IUnChecked} from '../icons/fda/box-unchecked-i.svg';
import {ReactComponent as IArrowsDown} from '../icons/arrows-down.svg';
import {ReactComponent as IArrowsUp} from '../icons/arrows-up.svg';
import {ReactComponent as ITextInput} from '../icons/text_input.svg';
import {ReactComponent as IFingerprint} from '../icons/tipper/fingerprint-i.svg';
import {ReactComponent as ISupplyChain} from '../icons/menu/SupplyChainRisk.svg';
import {ReactComponent as ITrophy} from '../icons/trophy-i.svg';
import {ReactComponent as IScan} from '../icons/scan-i.svg';
import {ReactComponent as IRisk} from '../icons/table/activity.svg';
import {ReactComponent as IGauge} from '../icons/gauge-i.svg';
import {ReactComponent as IInfo} from '../icons/info.svg';
import {ReactComponent as IMed} from '../icons/grc/med-i.svg';
import {ReactComponent as ILow} from '../icons/grc/low-i.svg';
import {ReactComponent as IIndustry} from '../icons/grc/industry-i.svg';
import {ReactComponent as IGeometry} from '../icons/grc/geometry-i.svg';
import 'react-circular-progressbar/dist/styles.css';
import ReactModal from 'react-modal';
import Dropdown from "react-bootstrap/Dropdown";
import {Tooltip as MuiTooltip} from "@mui/material";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {CircleFlag} from "react-circle-flags";
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";

function getScoreColor(percentage, per) {
    if (!per) per = 1
    const colorGreen = "rgba(16,185,129," + per + ")"
    const colorAmber = "rgba(245,158,11," + per + ")"
    const colorRed = "rgba(244,63,94," + per + ")"
    const colorZinc = "rgba(161,161,170," + per + ")"
    if (percentage >= 98) {
        return colorGreen
    } else if (percentage >= 50) {
        return colorAmber
    }
    if (percentage < -900) {
        return colorZinc
    }
    return colorRed
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function isNumber(str) {
    return /^-?\d+(\.\d+)?$/.test(str);
}

function formatDate(date) {
    let localDate = new Date(date - date.getTimezoneOffset() * 60 * 1000);
    const day = localDate.getDate();
    const month = localDate.toLocaleString('default', { month: 'long' });
    const year = localDate.getFullYear();
    const time = localDate.toTimeString().split(' ')[0];
    return `${day} ${month} ${year} - ${time}`;
}

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table === 1) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        if (table === 2) return formatDate(date)
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function template_sorter(a, b) {
    return parseInt(a[0].substring(1)) - parseInt(b[0].substring(1))
}

export default class ShadowGRC extends Component {

    constructor(props) {
        super(props);
        let data = this.props.page_data()
        this.state = {username: data.username, company: data.company, token: data.token, key: data.keys[data.company], keys: data.keys, showBuilder: false,
            risk_detections: {credentials: [], sentry: []},
            showCRQ: false,
            templates: [],
            ss_data: [],
            answers: [],
            focused_answer: {},
            baseline_answer: {},
            baseline_template: {},
            focused_assessment: "",
            focused_section: "S1",
            selected_industry: "",
            hovered_template_item: "",
            hovered_dropdown: "",
            template_saved: false,
            showPicker: false,
            modal_data: {
                name: "",
                sub: "",
                description: "",
                version: "1.0",
                sections: {}
            }
        };
        this.get_section_numbers_sum = this.get_section_numbers_sum.bind(this)
        this.template_new_section = this.template_new_section.bind(this)
        this.template_new_question = this.template_new_question.bind(this)
        this.template_delete_section = this.template_delete_section.bind(this)
        this.template_new_answer = this.template_new_answer.bind(this)
        this.template_delete_question = this.template_delete_question.bind(this)
        this.template_delete_answer = this.template_delete_answer.bind(this)
        this.template_save = this.template_save.bind(this)
        this.shift_entry = this.shift_entry.bind(this)
        this.get_total_weight = this.get_total_weight.bind(this)
        this.save_progress = this.save_progress.bind(this)
        this.submit_grc_answers = this.submit_grc_answers.bind(this)
        this.get_question_count = this.get_question_count.bind(this)
        this.get_final_score = this.get_final_score.bind(this)
        this.get_section_numbers = this.get_section_numbers.bind(this)
        this.all_focused_answered = this.all_focused_answered.bind(this)
        this.get_section_recommendations = this.get_section_recommendations.bind(this)
        this.add_grc_answers = this.add_grc_answers.bind(this)
        this.get_icon = this.get_icon.bind(this)
        this.get_tags = this.get_tags.bind(this)
        this.tick = this.tick.bind(this)
    }

    get_section_numbers_sum() {
        let num = [0,0,0]
        for (const [key, section] of Object.entries(this.state.focused_answer.sections)) {
            let numbers = this.get_section_numbers(section)
            num[0] += numbers[0]
            num[1] += numbers[1]
            num[2] += numbers[2]
        }
        return num
    }

    all_focused_answered() {
        for (const [key, section] of Object.entries(this.state.focused_answer.sections)) {
            let numbers = this.get_section_numbers(section);
            let count = numbers[0] + numbers[1] + numbers[2];
            if (count !== Object.keys(section.questions).length) {
                return false
            }
        }
        return true
    }

    get_section_recommendations(section) {
        let res_obj = []
        if (!section) return res_obj
        Object.values(section.questions).forEach(question => {
            let totalPercentage = 0;
            let recommendation = ""
            let level = 0
            Object.values(question.answers).forEach(answer => {
                if (answer.selected === true) {
                    totalPercentage += question.weight === "0" ? 100 : Number.parseInt(answer.weight);
                }
                if (answer.recommendation !== undefined && answer.recommendation.length > 0) {
                    recommendation = answer.recommendation
                }
            });
            const questionPercentage = Math.min(totalPercentage, 100);
            if (questionPercentage < 1) {
                level = 0
            } else if (questionPercentage > 0 && questionPercentage < 50) {
                level = 1
            }
            else if (questionPercentage > 50 && questionPercentage < 100) {
                level = 2
            }
            if (recommendation.length > 0 && questionPercentage < 100) {
                res_obj.push({
                    question: question.name,
                    recommendation: recommendation,
                    level: level
                })
            }
        });
        return res_obj
    }

    get_section_numbers(section) {
        let numbers = [0,0,0]
        if (!section) return numbers
        Object.values(section.questions).forEach(question => {
            let totalPercentage = 0;
            let has_selected = false
            Object.values(question.answers).forEach(answer => {
                if (answer.selected === true) {
                    totalPercentage += question.weight === "0" ? 100 : Number.parseInt(answer.weight);
                }
                has_selected = answer.selected !== undefined
            });
            const questionPercentage = Math.min(totalPercentage, 100);
            if (questionPercentage >= 100 || (question.type === "Free Text" && question.answer && question.answer.length > 0)) numbers[0]++
            else if (questionPercentage > 0) numbers[1]++
            else if (has_selected)numbers[2]++
        });
        return numbers
    }

    get_tags(sections) {
        let tags = {}
        let tag_count = {}
        if (!sections) return tags
        Object.entries(sections).sort(template_sorter).forEach(([a_key, section]) => {
                if (!(section.tag in tags)) {
                    tags[section.tag] = 0
                    tag_count[section.tag] = 0
                }
                tag_count[section.tag]++
                let sectionScore = 0;
                let has_selected = false
                Object.values(section.questions).forEach(question => {
                    let totalPercentage = 0;
                    Object.values(question.answers).forEach(answer => {
                        if (answer.selected === true) {
                            totalPercentage += question.weight === "0" ? 100 : Number.parseInt(answer.weight);
                        }
                        has_selected = answer.selected !== undefined
                    });
                    const questionPercentage = Math.min(totalPercentage, 100);
                    sectionScore += (question.weight === "0" ? 100 : Number.parseInt(question.weight)) * (questionPercentage / 100);
                });
                tags[section.tag] += has_selected ? Math.floor((sectionScore / Number.parseInt(section.weight)) * 100) : -1000
            }
        )
        Object.keys(tags).forEach(tag => {
            if (tag_count[tag] !== 0) {
                tags[tag] /= tag_count[tag];
            }
        });
        return tags
    }

    get_final_score(item) {
        if (!item || !item.sections) return 0
        let sections = item.sections

        let currentScore = 0;
        let maxScore = 0;
        Object.values(sections).forEach(section => {
            let sectionMax = Number.parseInt(section.weight)
            maxScore += sectionMax;
            let sectionScore = 0;
            Object.values(section.questions).forEach(question => {
                let totalPercentage = 0;
                Object.values(question.answers).forEach(answer => {
                    if (answer.selected) {
                        totalPercentage += Number.parseInt(answer.weight);
                    }
                });
                const questionPercentage = Math.min(totalPercentage, 100);
                sectionScore += Number.parseInt(question.weight) * (questionPercentage / 100);
            });
            currentScore += Math.min(sectionScore, sectionMax);
        });
        let finalScore = Math.floor((currentScore / maxScore) * 100)
        if (finalScore > 100) return 100
        else if (finalScore < 0) return 0
        return finalScore;
    }

    get_icon(item, color) {
        let name = item["name"].toLowerCase()
        if (name.includes("cobra")) {
            if (name.includes("+") || name.includes("plus")) {
                return (
                    <ICOBRAP style={{
                        width: 64,
                        height: 64
                    }}/>
                )
            }
            return (
                <ICOBRA style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        if (name.includes("dora")) {
            return (
                <IDORA style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        if (name.includes("essentials")) {
            return (
                <IEssentials style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        if (name.includes("iso")) {
            return (
                <IISO style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        if (name.includes("nist")) {
            return (
                <INIST style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        if (name.includes("nis")) {
            return (
                <INIS style={{
                    width: 64,
                    height: 64
                }}/>
            )
        }
        return (
            <ITemplate style={{
                color: color ? color : (item["published"] ? "#10B981" : "#F59E0B"),
                width: 64,
                height: 64
            }}/>
        )
    }

    get_total_weight() {
        let total = 0
        Object.keys(this.state.modal_data["sections"]).forEach(function (key, index) {
            total += Number.parseInt(this[key].weight)
        }, this.state.modal_data["sections"]);
        return total
    }

    get_question_count() {
        let total = 0
        Object.keys(this.state.modal_data["sections"]).forEach(function (key, index) {
            total += Object.keys(this[key].questions).length
        }, this.state.modal_data["sections"]);
        return total
    }

    shift_entry(itemType, up, sectionKey, questionKey, answerKey) {
        this.setState(prevState => {
            let modalData = structuredClone(prevState.modal_data);
            const getKeyDetails = (key) => {
                const prefix = key[0];
                const number = parseInt(key.slice(1));
                return {prefix, number};
            };

            const swapKeys = (obj, key1, key2) => {
                const updatedObj = {...obj};
                const temp = updatedObj[key1];
                updatedObj[key1] = updatedObj[key2];
                updatedObj[key2] = temp;
                return updatedObj;
            };

            if (itemType === "section") {
                const {number} = getKeyDetails(sectionKey);
                if (!up || number > 1) {
                    const newKey = up ? `S${number - 1}` : `S${number + 1}`;
                    if (modalData.sections[newKey]) {
                        modalData.sections = swapKeys(modalData.sections, sectionKey, newKey)
                    }
                }
            } else if (itemType === "question") {
                const {number} = getKeyDetails(questionKey);
                if (!up || number > 1) {
                    const newKey = up ? `Q${number - 1}` : `Q${number + 1}`;
                    if (modalData.sections[sectionKey].questions[newKey]) {
                        modalData.sections[sectionKey].questions = swapKeys(modalData.sections[sectionKey].questions, questionKey, newKey)
                    }
                }
            } else if (itemType === "answer") {
                const {number} = getKeyDetails(answerKey);
                const answers = modalData.sections[sectionKey].questions[questionKey].answers;
                if (!up || number > 1) {
                    const newKey = up ? `A${number - 1}` : `A${number + 1}`;
                    if (answers[newKey]) {
                        modalData.sections[sectionKey].questions[questionKey].answers = swapKeys(answers, answerKey, newKey);
                    }
                }
            }

            return {
                modal_data: modalData
            };
        });
    }

    add_grc_answers(focused, data) {
        let jsonData = {
            "token": this.state.token,
            "company": this.state.company,
            "data": data
        }
        this.props.post_request("add_grc_answers", jsonData).then((response) => {
            if (response.success) {
                data["oid"] = response["oid"]
                this.setState({
                    focused_assessment: focused,
                    focused_answer: data,
                    focused_section: "S1"
                })
            }
        }).catch((err) => {
            console.log("add_grc_answers", err)
        })
    }

    save_progress(focused_answer) {
        let jsonData = {
            "token": this.state.token,
            "company": this.state.company,
            "data": focused_answer ? focused_answer : this.state.focused_answer
        }
        this.props.post_request("save_grc_answers", jsonData).then((response) => {
            if (response && response.success) {
                this.setState({answers: []})
            }
        }).catch((err) => {
            console.log("save_grc_answers", err)
        })
    }

    submit_grc_answers() {
        let focused_answer = this.state.focused_answer
        focused_answer["submitted"] = true
        this.save_progress(focused_answer)
    }

    tick() {
        let data = this.props.page_data()
        if (this.state.company !== data.company || this.state.token !== data.token) {
            this.setState({username: data.username, company: data.company, token: data.token, key: data.keys[data.company], keys: data.keys, templates: [], answers: [], baseline_answer: {}, baseline_template: {}})
        }
        if (data.token.length > 0) {
            let jsonData = {
                "token": data.token,
                "company": data.company
            }
            if (data.company.startsWith("KryptoKloud")) {
                this.props.post_request("get_grc_templates", jsonData).then((response) => {
                    if (response.data) {
                        if (!deepEqual(this.state.templates, response.data)) {
                            this.setState({templates: response.data})
                            response.data.forEach(function(entry, index) {
                                if (entry.name.toLowerCase() === "grc baseline") {
                                    this.setState({baseline_template: entry})
                                }
                            }, this);
                        }
                    } else {
                        console.log("failed get_grc_templates")
                    }
                }).catch((err) => {
                    console.log("get_grc_templates", err)
                })
            }
            this.props.post_request("get_grc_answers", jsonData).then((response) => {
                if (response.data) {
                    if (!deepEqual(this.state.answers, response.data)) {
                        this.setState({answers: response.data})
                        response.data.forEach(function(entry, index) {
                            if (entry.name.toLowerCase() === "grc baseline") {
                                this.setState({baseline_answer: entry})
                            }
                        }, this);
                    }
                } else {
                    console.log("failed get_grc_answers")
                }
            }).catch((err) => {
                console.log("get_grc_answers", err)
            })

            if (this.state.focused_answer.submitted) {
                this.props.post_request("risk_detections", jsonData).then((response) => {
                    if (response.risk_detections) {
                        let risk_detection = {credentials: [], sentry: []}
                        let now = Math.floor(new Date().getTime()/1000.0)
                        let one_year_ago = now - (86400 * 365)
                        response.risk_detections.forEach(function(entry, index) {
                            if (entry["updated"] > one_year_ago) {
                                if (entry["host"] === "Supply Chain Sentry") {
                                    risk_detection.sentry.push(entry)
                                }
                                if (entry["priority"][0] === 'C') {
                                    risk_detection.credentials.push(entry)
                                }
                            }
                        }, this);
                        if (!deepEqual(this.state.risk_detections, risk_detection)) {
                            this.setState({risk_detections: risk_detection})
                        }
                    } else {
                        console.log("failed risk_detections")
                    }
                }).catch((err) => {
                    console.log("risk_detections", err)
                })
            }

            let ssJSONData = {
                "key": data.keys[data.company],
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/supply_sentry", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(ssJSONData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (!deepEqual(this.state.ss_data, json["sentry"])) {
                            this.setState({ss_data: json["sentry"]})
                        }
                    });
                }
            }).catch((err) => {
                console.log("ss", err)
            })
        }
    }

    template_save() {
        this.setState({template_saved: false})
        let jsonData = {
            "token": this.state.token,
            "company": this.state.company,
            "data": this.state.modal_data
        }
        this.props.post_request("save_grc_template", jsonData).then((response) => {
            if (response) {
                if (response["success"]) {
                    this.setState({template_saved: true})
                } else {
                    this.setState({template_saved: false})
                }
            } else {
                this.setState({template_saved: false})
            }
        }).catch((err) => {
            console.log("save_grc_template", err)
        })
    }

    template_new_answer(section, question) {
        this.setState(prevState => {
            const answers = {...prevState.modal_data.sections[section].questions[question].answers}
            const answerKeys = Object.keys(answers)
            let newKey = "A1"

            if (answerKeys.length > 0) {
                const existingNumbers = answerKeys.map(key => parseInt(key.slice(1))).sort((a, b) => a - b)
                for (let i = 1; i <= existingNumbers.length + 1; i++) {
                    if (!existingNumbers.includes(i)) {
                        newKey = `A${i}`
                        break
                    }
                }
            }

            answers[newKey] = {
                name: "",
                weight: "0",
                comment: false,
                correlations: []
            }

            const sortedAnswers = Object.keys(answers)
                .sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
                .reduce((acc, key) => {
                    acc[key] = answers[key]
                    return acc
                }, {})

            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: {
                        ...prevState.modal_data.sections,
                        [section]: {
                            ...prevState.modal_data.sections[section],
                            questions: {
                                ...prevState.modal_data.sections[section].questions,
                                [question]: {
                                    ...prevState.modal_data.sections[section].questions[question],
                                    answers: sortedAnswers
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    template_delete_answer(section, question, answer) {
        this.setState(prevState => {
            const sections = {...prevState.modal_data.sections}
            delete sections[section].questions[question].answers[answer]
            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: sections
                }
            }
        })
    }

    template_new_question(section) {
        this.setState(prevState => {
            const questions = {...prevState.modal_data.sections[section].questions}
            const questionKeys = Object.keys(questions)
            let newKey = "Q1"

            if (questionKeys.length > 0) {
                const existingNumbers = questionKeys.map(key => parseInt(key.slice(1))).sort((a, b) => a - b)
                for (let i = 1; i <= existingNumbers.length + 1; i++) {
                    if (!existingNumbers.includes(i)) {
                        newKey = `Q${i}`
                        break
                    }
                }
            }

            questions[newKey] = {
                name: "",
                type: "Single Select",
                weight: "0",
                answers: {}
            }

            const sortedQuestions = Object.keys(questions)
                .sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
                .reduce((acc, key) => {
                    acc[key] = questions[key]
                    return acc
                }, {})

            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: {
                        ...prevState.modal_data.sections,
                        [section]: {
                            ...prevState.modal_data.sections[section],
                            questions: sortedQuestions
                        }
                    }
                }
            }
        });
    }

    template_delete_question(section, question) {
        this.setState(prevState => {
            const sections = {...prevState.modal_data.sections}
            delete sections[section].questions[question]
            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: sections
                }
            }
        })
    }

    template_new_section() {
        this.setState(prevState => {
            const sections = {...prevState.modal_data.sections}
            const sectionKeys = Object.keys(sections)
            let newKey = "S1"

            if (sectionKeys.length > 0) {
                const existingNumbers = sectionKeys.map(key => parseInt(key.slice(1))).sort((a, b) => a - b)
                for (let i = 1; i <= existingNumbers.length + 1; i++) {
                    if (!existingNumbers.includes(i)) {
                        newKey = `S${i}`
                        break
                    }
                }
            }

            sections[newKey] = {
                name: "",
                sub: "",
                tag: "",
                weight: "0",
                questions: {}
            }

            const sortedSections = Object.keys(sections)
                .sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)))
                .reduce((acc, key) => {
                    acc[key] = sections[key]
                    return acc
                }, {})

            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: sortedSections
                }
            }
        });
    }
    
    template_delete_section(key) {
        this.setState(prevState => {
            const sections = {...prevState.modal_data.sections}
            delete sections[key]
            return {
                modal_data: {
                    ...prevState.modal_data,
                    sections: sections
                }
            }
        })
    }
    
    componentDidMount() {
        let state = this.props.page_state("shadowgrc")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                token: data.token,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        if (prevState.template_saved && this.state.template_saved && prevState.hovered_dropdown === this.state.hovered_dropdown && prevState.hovered_template_item === this.state.hovered_template_item) {
            this.setState({template_saved: false})
        }
        this.props.page_state("shadowgrc", this.state)
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
    }

    render() {
        return (
            <div
                className="cp-scroll-bar-div"
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "16px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto",
                    paddingTop: 8,
                    paddingRight: 8
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showCRQ}
                    contentLabel="Cyber Risk Quantification"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "750px",
                            height: "810px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}} onClick={() => console.log(this.state)}>
                                Cyber Risk Quantification
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({
                                    showCRQ: false
                                })}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            width: '100%',
                            height: '100%',
                            paddingTop: 16,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 8,
                            display: 'inline-flex'
                        }}>
                            <div style={{
                                width: "100%",
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'inline-flex',
                                gap: 8,
                            }}>
                                <div style={{
                                    flex: '1 1 0',
                                    alignSelf: 'stretch',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        height: 130,
                                        padding: 8,
                                        background: '#27272A',
                                        borderRadius: 4,
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            height: 96,
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'flex',
                                            gap: 16,
                                        }}>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                color: 'white',
                                                fontSize: 24,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                wordWrap: 'break-word'
                                            }}>Manufacturing Industry Analysis
                                            </div>
                                            <div style={{
                                                width: 'calc(100% - 16px)',
                                                height: "1px",
                                                marginLeft: 16,
                                                borderBottom: '1px #A1A1AA dashed'
                                            }} />
                                            <div style={{
                                                flex: '1 1 0',
                                                height: 40,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                gap: 8,
                                            }}
                                            >
                                                <div style={{
                                                    width: 142,
                                                    height: 40,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Likelihood
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Of Attack
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: 75,
                                                    height: 40,
                                                    padding: 8,
                                                    background: '#881337',
                                                    borderRadius: 4,
                                                    overflow: 'hidden',
                                                    border: '1px #FB7185 solid',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        textAlign: 'center',
                                                        color: '#FB7185',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>MEDIUM
                                                    </div>
                                                </div
                                                >
                                                <div style={{
                                                    width: 142,
                                                    height: 40,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Risk
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Of Attack
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: 57,
                                                    height: 40,
                                                    background: '#881337',
                                                    borderRadius: 4,
                                                    overflow: 'hidden',
                                                    border: '1px #FB7185 solid',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        width: 57,
                                                        textAlign: 'center',
                                                        color: '#FB7185',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>7/10
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: 142,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Frequency
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Of Attack
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: 57,
                                                    height: 40,
                                                    background: '#713F12',
                                                    borderRadius: 4,
                                                    overflow: 'hidden',
                                                    border: '1px #FACC15 solid',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        width: 57,
                                                        textAlign: 'center',
                                                        color: '#FACC15',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>4/10
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        background: '#27272A',
                                        borderRadius: 4,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'inline-flex',
                                        gap: 8,
                                    }}
                                    >
                                        <div style={{
                                            alignSelf: 'stretch',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                flex: '1 1 0',
                                                alignSelf: 'stretch',
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingRight: 8,
                                                background: '#27272A',
                                                borderRadius: 4,
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                gap: 8,
                                                display: 'flex'
                                            }}
                                            >
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        paddingLeft: 16,
                                                        paddingRight: 16,
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        background: '#18181B',
                                                        borderRadius: 4,
                                                        overflow: 'hidden',
                                                        border: '1px #A1A1AA solid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#A1A1AA',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',

                                                            letterSpacing: 0.03,
                                                            wordWrap: 'break-word'
                                                        }}>£ 240,000
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',

                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Primary Loss
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                flex: '1 1 0',
                                                alignSelf: 'stretch',
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                background: '#27272A',
                                                borderRadius: 4,
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                gap: 8,
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    paddingTop: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        paddingLeft: 16,
                                                        paddingRight: 16,
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        background: '#18181B',
                                                        borderRadius: 4,
                                                        overflow: 'hidden',
                                                        border: '1px #A1A1AA solid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#A1A1AA',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                            letterSpacing: 0.03,
                                                            wordWrap: 'break-word'
                                                        }}>£ 700,000
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Secondary Loss
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                flex: '1 1 0',
                                                alignSelf: 'stretch',
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingLeft: 8,
                                                background: '#27272A',
                                                borderRadius: 4,
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                gap: 8,
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    paddingTop: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        paddingLeft: 16,
                                                        paddingRight: 16,
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        background: '#18181B',
                                                        borderRadius: 4,
                                                        overflow: 'hidden',
                                                        border: '1px #A1A1AA solid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#A1A1AA',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',

                                                            letterSpacing: 0.03,
                                                            wordWrap: 'break-word'
                                                        }}>£ 780,000
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',

                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Cost of Remediation
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                flex: '1 1 0',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    height: 69,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: '#52525B',
                                                    borderRadius: 4,
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        flex: '1 1 0',
                                                        alignSelf: 'stretch',
                                                        paddingTop: 4,
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            paddingLeft: 16,
                                                            paddingRight: 16,
                                                            paddingTop: 8,
                                                            paddingBottom: 8,
                                                            background: '#18181B',
                                                            borderRadius: 4,
                                                            overflow: 'hidden',
                                                            border: '1px #A1A1AA solid',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '500',
                                                                letterSpacing: 0.03,
                                                                wordWrap: 'break-word'
                                                            }}>£ 1,720,000
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>Total Estimated Financial Loss
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                alignSelf: 'stretch',
                                height: 404,
                                paddingLeft: 8,
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'flex'
                            }}>
                                <div style={{
                                    alignSelf: 'stretch',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}>
                                    <div style={{
                                        width: "100%",
                                        alignSelf: 'stretch',
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 16,
                                        background: '#27272A',
                                        borderRadius: 4,
                                        overflow: 'hidden',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        display: 'inline-flex',
                                        gap: 16,
                                    }}>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            color: 'white',
                                            fontSize: 24,
                                            fontFamily: 'Inter',
                                            fontWeight: '600',
                                            wordWrap: 'break-word'
                                        }}>CRQ Heatmap
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            height: 340,
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            letterSpacing: 0.06,
                                            display: 'flex'
                                        }}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    width: 80,
                                                    height: 60,
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Unlikely<br/>(1)
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    borderTopLeftRadius: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    borderTopRightRadius: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    width: 80,
                                                    height: 60,
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
                                                    gap: 8,
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Seldom<br/>(2)
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#EF4444',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>High risk. Further action necessary.
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    width: 80,
                                                    height: 60,
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Occasional<br/>(3)
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#EF4444',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>High risk. Further action necessary.
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    width: 80,
                                                    height: 60,
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Likely<br/>(4)
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#10B981',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Low risk<br/>No further Action
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#EF4444',
                                                    border: '2px #18181B solid',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>High risk. Further action necessary.
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                height: 60,
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    width: 80,
                                                    height: 60,
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Definite<br/>(5)
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 60,
                                                    padding: 4,
                                                    background: '#F59E0B',
                                                    borderTopLeftRadius: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Medium risk. Further action optional
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#EF4444',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>High risk. Further action necessary.
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    padding: 4,
                                                    background: '#9F1239',
                                                    borderTopLeftRadius: 4,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        textAlign: 'center',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Extreme risk. ACT NOW
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                height: 40,
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    width: 80,
                                                }} />
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: 'rgba(255, 255, 255, 0)',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>Insignificant<br/>(1)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: 'rgba(255, 255, 255, 0)',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>Marginal<br/>(2)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: 'rgba(255, 255, 255, 0)',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>Moderate<br/>(3)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: 'rgba(255, 255, 255, 0)',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>Critical<br/>(4)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                    background: 'rgba(255, 255, 255, 0)',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 32,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>Catastrophic<br/>(5)
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showBuilder}
                    contentLabel="Template Builder"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "2140px",
                            height: "1200px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}} onClick={() => console.log(this.state)}>
                                Template Builder
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({
                                    showBuilder: false,
                                    modal_data: {name: "", sub: "", description: "", version: "1.0", sections: {}}
                                })}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            width: '100%',
                            height: '40px',
                            padding: 8,
                            background: 'transparent',
                            borderRadius: 8,
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            color: "white",
                            gap: 8,
                            display: 'inline-flex'
                        }}
                        >
                            <div style={{
                                width: '48px',
                                height: '32px',
                                padding: 8,
                                background: '#3F3F46',
                                borderRadius: 4,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <div style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    wordWrap: 'break-word'
                                }}>V{this.state.modal_data.version}
                                </div>
                            </div>
                            <div style={{
                                width: 120,
                                padding: "8px 8px",
                                borderRadius: 2,
                                border: '1px #06B6D4 solid',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: 'rgba(6,182,212,0.25)',
                                gap: 4,
                                display: 'flex',
                                cursor: "pointer"
                            }}
                                 onClick={this.template_save}
                            >
                                <div style={{
                                    color: '#06B6D4',
                                    fontSize: 12,
                                    fontFamily: 'Inter',
                                    fontWeight: '500',
                                    letterSpacing: 0.06,
                                    whiteSpace: 'nowrap'
                                }}>
                                    {
                                        this.state.template_saved ?
                                            "Saved" : "Save Progress"
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: 8
                        }}
                        >
                            Total points:
                            <div style={{
                                width: '58px',
                                height: '32px',
                                padding: 8,
                                background: '#3F3F46',
                                borderRadius: 4,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <div style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    wordWrap: 'break-word'
                                }}>{this.get_total_weight()}
                                </div>
                            </div>
                            Number of questions:
                            <div style={{
                                width: '58px',
                                height: '32px',
                                padding: 8,
                                background: '#3F3F46',
                                borderRadius: 4,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <div style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    wordWrap: 'break-word'
                                }}>
                                    {this.get_question_count()}
                                </div>
                            </div>
                        </div>


                        <div style={{
                            width: 'calc(100% - 8px)',
                            height: '72px',
                            padding: 8,
                            background: '#3F3F46',
                            borderRadius: 8,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 8,
                            display: 'inline-flex'
                        }}
                        >
                            <div style={{
                                width: "100%",
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <div style={{
                                    color: 'white',
                                    fontSize: 16,
                                    fontFamily: 'Inter',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                }}>Template name:
                                </div>
                                <input className="transparent-input" type='text' name='template_name'
                                       value={this.state.modal_data.name}
                                       style={{
                                           backgroundColor: "#27272A",
                                           paddingLeft: 8,
                                           borderRadius: 4
                                       }}
                                       onChange={(data) => {
                                           this.setState(prevState => {
                                               return {
                                                   modal_data: {
                                                       ...prevState.modal_data,
                                                       name: data.target.value
                                                   }
                                               }
                                           })
                                       }}
                                />
                                <div style={{
                                    width: 120,
                                    padding: "0 8px",
                                    borderRadius: 2,
                                    border: '1px #06B6D4 solid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex',
                                    cursor: "pointer"
                                }}
                                     onClick={this.template_new_section}
                                >
                                    <IPlus style={{
                                        color: '#06B6D4',
                                        width: 20,
                                        height: 20
                                    }}/>

                                    <div style={{
                                        color: '#06B6D4',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        letterSpacing: 0.06,
                                        whiteSpace: 'nowrap'
                                    }}>Add Section
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "100%",
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <IInfo style={{color: "white", width: 20, height: 20}}/>
                                <input className="transparent-input" type='text' name='template_sub'
                                       value={this.state.modal_data.sub}
                                       style={{
                                           backgroundColor: "#27272A",
                                           paddingLeft: 8,
                                           borderRadius: 4
                                       }}
                                       onChange={(data) => {
                                           this.setState(prevState => {
                                               return {
                                                   modal_data: {
                                                       ...prevState.modal_data,
                                                       sub: data.target.value
                                                   }
                                               }
                                           })
                                       }}
                                />
                            </div>
                        </div>

                        <div style={{
                            width: 'calc(100% - 8px)',
                            height: 'auto',
                            padding: 8,
                            background: '#3F3F46',
                            borderRadius: 8,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 8,
                            display: 'inline-flex'
                        }}
                        >
                            <div style={{
                                color: 'white',
                                fontSize: 16,
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                            }}>Description:
                            </div>
                            <textarea className="transparent-input" name='template_name'
                                      value={this.state.modal_data.description}
                                      style={{
                                          backgroundColor: "#27272A",
                                          paddingLeft: 8,
                                          borderRadius: 4,
                                          minHeight: 26,
                                          height: 26
                                      }}
                                      onChange={(data) => {
                                          this.setState(prevState => {
                                              return {
                                                  modal_data: {
                                                      ...prevState.modal_data,
                                                      description: data.target.value
                                                  }
                                              }
                                          })
                                      }}
                            />
                        </div>

                        <div
                            className="cp-scroll-bar-div"
                            style={{
                                userSelect: "none",
                                width: '100%',
                                height: '100%',
                                display: "flex",
                                flexDirection: "column",
                                gap: 16,
                                paddingRight: 8,
                                overflow: "auto"
                            }}>
                            {
                                Object.entries(this.state.modal_data.sections).sort(template_sorter).map(([key, value]) =>
                                    <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
                                        <div style={{
                                            width: '100%',
                                            height: '72px',
                                            padding: 8,
                                            background: '#3F3F46',
                                            borderRadius: 8,
                                            borderBottomLeftRadius: value.questions && Object.entries(value.questions).length > 0 ? 0 : 8,
                                            borderBottomRightRadius: value.questions && Object.entries(value.questions).length > 0 ? 0 : 8,
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{display: "flex", flexDirection: "row"}}
                                                >
                                                    <div style={{cursor: "pointer"}}
                                                         hidden={Object.keys(this.state.modal_data.sections).every(sectionKey => parseInt(sectionKey.substring(1)) >= parseInt(key.substring(1)))}
                                                         onClick={() => this.shift_entry("section", true, key)}>
                                                        <IArrowsUp style={{
                                                            color: '#06B6D4',
                                                            width: 20,
                                                            height: 20
                                                        }}/>
                                                    </div>
                                                    <div style={{cursor: "pointer"}}
                                                         hidden={Object.keys(this.state.modal_data.sections).every(sectionKey => parseInt(sectionKey.substring(1)) <= parseInt(key.substring(1)))}
                                                         onClick={() => this.shift_entry("section", false, key)}>
                                                        <IArrowsDown style={{
                                                            color: '#06B6D4',
                                                            width: 20,
                                                            height: 20
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    width: 32,
                                                    fontWeight: '600',
                                                    whiteSpace: 'nowrap',
                                                }}>{key}
                                                </div>
                                                <input className="transparent-input" type='text' name='section_name'
                                                       value={this.state.modal_data.sections[key].name}
                                                       style={{
                                                           backgroundColor: "#27272A",
                                                           paddingLeft: 8,
                                                           borderRadius: 4
                                                       }}
                                                       onChange={(data) => {
                                                           let m_data = this.state.modal_data
                                                           m_data.sections[key].name = data.target.value
                                                           this.setState({modal_data: m_data})
                                                       }}
                                                />
                                                <div style={{
                                                    width: 120,
                                                    padding: "0 8px",
                                                    borderRadius: 2,
                                                    border: '1px #06B6D4 solid',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex',
                                                    cursor: "pointer"
                                                }}
                                                     onClick={() => this.template_new_question(key)}
                                                >
                                                    <IPlus style={{
                                                        color: '#06B6D4',
                                                        width: 20,
                                                        height: 20
                                                    }}/>

                                                    <div style={{
                                                        color: '#06B6D4',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        letterSpacing: 0.06,
                                                        whiteSpace: 'nowrap'
                                                    }}>Add Question
                                                    </div>
                                                </div>
                                                <input className="transparent-input" type='text' name='section_weight'
                                                       value={this.state.modal_data.sections[key].weight}
                                                       style={{
                                                           backgroundColor: "#D4D4D8",
                                                           color: "black",
                                                           textAlign: "center",
                                                           fontSize: 16,
                                                           fontWeight: '600',
                                                           width: 42,
                                                           borderRadius: 4
                                                       }}
                                                       onChange={(data) => {
                                                           let value = data.target.value
                                                           if (isNumber(value) && value.length < 5) {
                                                               let m_data = this.state.modal_data
                                                               m_data.sections[key].weight = value
                                                               this.setState({modal_data: m_data})
                                                           }
                                                       }}
                                                />
                                                <div style={{display: "flex", cursor: "pointer"}}
                                                     onClick={() => this.template_delete_section(key)}
                                                     onMouseOver={() => {
                                                         if (this.state.hovered_template_item !== key) {
                                                             this.setState({hovered_template_item: key})
                                                         }
                                                     }}
                                                     onMouseLeave={() => this.setState({hovered_template_item: ""})}
                                                >
                                                    <ITrash style={{
                                                        opacity: this.state.hovered_template_item === key ? 1 : 0,
                                                        color: '#F43F5E',
                                                        width: 20,
                                                        height: 20,
                                                    }}/>
                                                </div>
                                            </div>
                                            <div style={{
                                                width: "100%",
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <IInfo style={{color: "white", width: 20, height: 20}}/>
                                                <input className="transparent-input" type='text' name='section_sub'
                                                       value={this.state.modal_data.sections[key].sub}
                                                       style={{
                                                           backgroundColor: "#27272A",
                                                           paddingLeft: 8,
                                                           borderRadius: 4
                                                       }}
                                                       onChange={(data) => {
                                                           let m_data = this.state.modal_data
                                                           m_data.sections[key].sub = data.target.value
                                                           this.setState({modal_data: m_data})
                                                       }}
                                                />
                                                <Dropdown className="RFI-Dropdown-defaults"
                                                          style={{width: 198, height: 28, borderRadius: 4}}>
                                                    <Dropdown.Toggle className="RFI-Dropdown-defaults"
                                                                     style={{
                                                                         width: 198,
                                                                         height: 28,
                                                                         borderRadius: 4
                                                                     }}>
                                                        <input className="transparent-input" type='text'
                                                               name='section_tag'
                                                               value={this.state.modal_data.sections[key].tag}

                                                               onChange={(data) => {
                                                                   let m_data = this.state.modal_data
                                                                   m_data.sections[key].tag = data.target.value
                                                                   this.setState({modal_data: m_data})
                                                               }}
                                                        />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="RFI-Dropdown-menu">
                                                        {
                                                            Object.entries(this.state.modal_data.sections).sort(template_sorter).map(([tag_key, tag_value]) =>
                                                                <div>
                                                                    {
                                                                        tag_value.tag && tag_value.tag.length > 0 ?
                                                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                                                           onClick={() => {
                                                                                               let m_data = this.state.modal_data
                                                                                               m_data.sections[key].tag = tag_value.tag
                                                                                               this.setState({modal_data: m_data})
                                                                                           }}>
                                                                                {tag_value.tag}
                                                                            </Dropdown.Item> : ""
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        {value.questions ?
                                            Object.entries(value.questions).sort(template_sorter).map(([q_key, q_value]) =>
                                                <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
                                                    <div style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        padding: 8,
                                                        background: '#27272A',
                                                        borderLeft: '4px #3F3F46 solid',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        zIndex: this.state.hovered_dropdown === key + q_key ? 10 : 1,
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}
                                                    >
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <div style={{cursor: "pointer"}}
                                                                 hidden={Object.keys(value.questions).every(questionKey => parseInt(questionKey.substring(1)) >= parseInt(q_key.substring(1)))}
                                                                 onClick={() => this.shift_entry("question", true, key, q_key)}>
                                                                <IArrowsUp style={{
                                                                    color: '#06B6D4',
                                                                    width: 20,
                                                                    height: 20
                                                                }}/>
                                                            </div>
                                                            <div style={{cursor: "pointer"}}
                                                                 hidden={Object.keys(value.questions).every(questionKey => parseInt(questionKey.substring(1)) <= parseInt(q_key.substring(1)))}
                                                                 onClick={() => this.shift_entry("question", false, key, q_key)}>
                                                                <IArrowsDown style={{
                                                                    color: '#06B6D4',
                                                                    width: 20,
                                                                    height: 20
                                                                }}/>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            width: 32,
                                                            fontWeight: '600',
                                                            whiteSpace: 'nowrap',
                                                        }}>{q_key}
                                                        </div>
                                                        <textarea className="transparent-input" name='question_name'
                                                                  value={this.state.modal_data.sections[key].questions[q_key].name}
                                                                  style={{
                                                                      backgroundColor: "#18181B",
                                                                      minHeight: 26,
                                                                      height: 26,
                                                                      paddingLeft: 8,
                                                                      borderRadius: 4
                                                                  }}
                                                                  onChange={(data) => {
                                                                      let m_data = this.state.modal_data
                                                                      m_data.sections[key].questions[q_key].name = data.target.value
                                                                      this.setState({modal_data: m_data})
                                                                  }}
                                                        />
                                                        <div
                                                            onMouseOver={() => {
                                                                if (this.state.hovered_dropdown !== key + q_key) {
                                                                    this.setState({hovered_dropdown: key + q_key})
                                                                }
                                                            }}
                                                            onMouseLeave={() => this.setState({hovered_dropdown: ""})}
                                                        >
                                                            <Dropdown className="RFI-Dropdown-defaults"
                                                                      style={{width: 198, height: 28, borderRadius: 4}}>
                                                                <Dropdown.Toggle className="RFI-Dropdown-defaults"
                                                                                 style={{
                                                                                     width: 198,
                                                                                     height: 28,
                                                                                     borderRadius: 4
                                                                                 }}>
                                                                    <div style={{
                                                                        width: 198,
                                                                        textAlign: "left",
                                                                        display: "flex",
                                                                        gap: "8px"
                                                                    }}>
                                                                        {this.state.modal_data.sections[key].questions[q_key].type}
                                                                    </div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="RFI-Dropdown-menu">
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => {
                                                                                       let m_data = this.state.modal_data
                                                                                       m_data.sections[key].questions[q_key].type = "Single Select"
                                                                                       this.setState({modal_data: m_data})
                                                                                   }}>
                                                                        Single Select
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => {
                                                                                       let m_data = this.state.modal_data
                                                                                       m_data.sections[key].questions[q_key].type = "Multi Select"
                                                                                       this.setState({modal_data: m_data})
                                                                                   }}>
                                                                        Multi Select
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => {
                                                                                       let m_data = this.state.modal_data
                                                                                       m_data.sections[key].questions[q_key].type = "Free Text"
                                                                                       m_data.sections[key].questions[q_key].answers = {}
                                                                                       this.setState({modal_data: m_data})
                                                                                   }}>
                                                                        Free Text
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        {
                                                            q_value.type !== "Free Text" ?
                                                                <div style={{
                                                                    width: 120,
                                                                    padding: "0 8px",
                                                                    borderRadius: 2,
                                                                    border: '1px #06B6D4 solid',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: 4,
                                                                    display: 'flex',
                                                                    cursor: "pointer"
                                                                }}
                                                                     onClick={() => this.template_new_answer(key, q_key)}
                                                                >
                                                                    <IPlus style={{
                                                                        color: '#06B6D4',
                                                                        width: 20,
                                                                        height: 20
                                                                    }}/>

                                                                    <div style={{
                                                                        color: '#06B6D4',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '500',
                                                                        letterSpacing: 0.06,
                                                                        whiteSpace: 'nowrap'
                                                                    }}>Add Answer
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        }
                                                        <input className="transparent-input" type='text'
                                                               name='question_weight'
                                                               value={q_value.weight}
                                                               style={{
                                                                   backgroundColor: "#D4D4D8",
                                                                   color: "black",
                                                                   textAlign: "center",
                                                                   fontSize: 16,
                                                                   fontWeight: '600',
                                                                   width: 42,
                                                                   borderRadius: 4
                                                               }}
                                                               onChange={(data) => {
                                                                   let value = data.target.value
                                                                   if (isNumber(value) && value.length < 5) {
                                                                       let m_data = this.state.modal_data
                                                                       m_data.sections[key].questions[q_key].weight = data.target.value
                                                                       this.setState({modal_data: m_data})
                                                                   }
                                                               }}
                                                        />
                                                        <div style={{display: "flex", cursor: "pointer"}}
                                                             onClick={() => this.template_delete_question(key, q_key)}
                                                             onMouseOver={() => {
                                                                 if (this.state.hovered_template_item !== key + q_key) {
                                                                     this.setState({hovered_template_item: key + q_key})
                                                                 }
                                                             }}
                                                             onMouseLeave={() => this.setState({hovered_template_item: ""})}
                                                        >
                                                            <ITrash style={{
                                                                opacity: this.state.hovered_template_item === key + q_key ? 1 : 0,
                                                                color: '#F43F5E',
                                                                width: 20,
                                                                height: 20,
                                                            }}/>
                                                        </div>
                                                    </div>

                                                    {
                                                        Object.entries(q_value.answers).sort(template_sorter).map(([a_key, a_value]) =>
                                                            <div style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                                padding: 8,
                                                                background: '#18181B',
                                                                borderLeft: '4px #3F3F46 solid',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    width: '100%',
                                                                    gap: 8,
                                                                    display: "inline-flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        <div style={{cursor: "pointer"}}
                                                                             hidden={Object.keys(q_value.answers).every(answerKey => parseInt(answerKey.substring(1)) >= parseInt(a_key.substring(1)))}
                                                                             onClick={() => this.shift_entry("answer", true, key, q_key, a_key)}>
                                                                            <IArrowsUp style={{
                                                                                color: '#06B6D4',
                                                                                width: 20,
                                                                                height: 20
                                                                            }}/>
                                                                        </div>
                                                                        <div style={{cursor: "pointer"}}
                                                                             hidden={Object.keys(q_value.answers).every(answerKey => parseInt(answerKey.substring(1)) <= parseInt(a_key.substring(1)))}
                                                                             onClick={() => this.shift_entry("answer", false, key, q_key, a_key)}>
                                                                            <IArrowsDown style={{
                                                                                color: '#06B6D4',
                                                                                width: 20,
                                                                                height: 20
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        width: 32,
                                                                        fontWeight: '600',
                                                                        whiteSpace: 'nowrap',
                                                                    }}>{a_key}
                                                                    </div>
                                                                    <textarea className="transparent-input"
                                                                              name='answer_name'
                                                                              value={a_value.name}
                                                                              style={{
                                                                                  backgroundColor: "#3F3F46",
                                                                                  minHeight: 26,
                                                                                  height: 26,
                                                                                  paddingLeft: 8,
                                                                                  borderRadius: 4
                                                                              }}
                                                                              onChange={(data) => {
                                                                                  let m_data = this.state.modal_data
                                                                                  m_data.sections[key].questions[q_key].answers[a_key].name = data.target.value
                                                                                  this.setState({modal_data: m_data})
                                                                              }}
                                                                    />
                                                                    <div style={{
                                                                        display: "flex",
                                                                        gap: 8,
                                                                        fontSize: 14,
                                                                        color: "white",
                                                                        whiteSpace: "nowrap",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        {
                                                                            a_value.comment ?
                                                                                <div style={{cursor: "pointer"}}
                                                                                     onClick={() => {
                                                                                         let m_data = this.state.modal_data
                                                                                         m_data.sections[key].questions[q_key].answers[a_key].comment = false
                                                                                         this.setState({modal_data: m_data})
                                                                                     }}
                                                                                >
                                                                                    <IChecked style={{
                                                                                        color: '#06B6D4',
                                                                                        width: 18,
                                                                                        height: 18,
                                                                                    }}/>
                                                                                </div>
                                                                                :
                                                                                <div style={{cursor: "pointer"}}
                                                                                     onClick={() => {
                                                                                         let m_data = this.state.modal_data
                                                                                         m_data.sections[key].questions[q_key].answers[a_key].comment = true
                                                                                         this.setState({modal_data: m_data})
                                                                                     }}
                                                                                >
                                                                                    <IUnChecked style={{
                                                                                        color: '#A1A1AA',
                                                                                        width: 18,
                                                                                        height: 18,
                                                                                    }}/>
                                                                                </div>
                                                                        }

                                                                        Comment Required
                                                                    </div>
                                                                    <input className="transparent-input" type='text'
                                                                           name='answer_weight'
                                                                           value={a_value.weight}
                                                                           style={{
                                                                               backgroundColor: "#D4D4D8",
                                                                               color: "black",
                                                                               textAlign: "center",
                                                                               fontSize: 16,
                                                                               fontWeight: '600',
                                                                               width: 42,
                                                                               borderRadius: 4
                                                                           }}
                                                                           onChange={(data) => {
                                                                               let value = data.target.value
                                                                               if (isNumber(value) && value.length < 5 && Number.parseInt(value) < 101) {
                                                                                   let m_data = this.state.modal_data
                                                                                   m_data.sections[key].questions[q_key].answers[a_key].weight = data.target.value
                                                                                   this.setState({modal_data: m_data})
                                                                               }
                                                                           }}
                                                                    />
                                                                    <div style={{color: '#06B6D4', fontWeight: '600'}}>
                                                                        %
                                                                    </div>
                                                                    <div style={{display: "flex", cursor: "pointer"}}
                                                                         onClick={() => this.template_delete_answer(key, q_key, a_key)}
                                                                         onMouseOver={() => {
                                                                             if (this.state.hovered_template_item !== key + q_key + a_key) {
                                                                                 this.setState({hovered_template_item: key + q_key + a_key})
                                                                             }
                                                                         }}
                                                                         onMouseLeave={() => this.setState({hovered_template_item: ""})}
                                                                    >
                                                                        <ITrash style={{
                                                                            opacity: this.state.hovered_template_item === key + q_key + a_key ? 1 : 0,
                                                                            color: '#F43F5E',
                                                                            width: 20,
                                                                            height: 20,
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    width: '100%',
                                                                    gap: 8,
                                                                    display: "inline-flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                                     hidden={Number.parseInt(q_value.weight) <= 0 || Number.parseInt(a_value.weight) > 99}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        width: 148,
                                                                        fontWeight: '600',
                                                                        whiteSpace: 'nowrap',
                                                                    }}>
                                                                        Recommendation:
                                                                    </div>
                                                                    <textarea className="transparent-input"
                                                                              name='answer_name'
                                                                              value={a_value.recommendation}
                                                                              style={{
                                                                                  backgroundColor: "#3F3F46",
                                                                                  minHeight: 26,
                                                                                  height: 26,
                                                                                  paddingLeft: 8,
                                                                                  borderRadius: 4
                                                                              }}
                                                                              onChange={(data) => {
                                                                                  let m_data = this.state.modal_data
                                                                                  m_data.sections[key].questions[q_key].answers[a_key].recommendation = data.target.value
                                                                                  this.setState({modal_data: m_data})
                                                                              }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ) : ""
                                        }

                                    </div>
                                )
                            }
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPicker}
                    contentLabel="Compliance Framework Selection"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "2140px",
                            height: "1200px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}} onClick={() => console.log(this.state)}>
                            Compliance Framework Selection
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({
                                    showPicker: false,
                                })}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>
                        <div style={{
                            display: "flex",
                            gap: 16,
                            flexWrap: "wrap",
                        }}>
                            {
                                this.state.templates.map((item, i) => {
                                        if (item.name.includes("Baseline")) return ""
                                        return (
                                            <div style={{
                                                userSelect: "none",
                                                width: '368px',
                                                height: '208px',
                                                padding: 8,
                                                background: '#27272A',
                                                borderRadius: 8,
                                                border: '2px #A1A1AA dashed',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                cursor: "pointer"
                                            }}
                                                 onClick={() => {
                                                     this.add_grc_answers(item.name, item)
                                                     this.setState({
                                                         showPicker: false
                                                     })
                                                 }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    paddingTop: 8,
                                                    gap: 16,
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        width: '100%',
                                                        paddingLeft: 8,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 10,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            wordWrap: 'break-word'
                                                        }}>V{item["version"]}
                                                        </div>
                                                        <div style={{
                                                            marginLeft: "auto",
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            wordWrap: 'break-word'
                                                        }}>{get_time_string(item["updated"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        height: 48,
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            width: 64, height: 64
                                                        }}>
                                                            {this.get_icon(item)}
                                                        </div>
                                                        <div style={{width: 1, height: "100%", background: "#A1A1AA"}}/>
                                                        <div style={{
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                alignSelf: 'stretch',
                                                                color: 'white',
                                                                fontSize: 28,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>{item["name"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        paddingLeft: 8,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>{item["description"]}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                </ReactModal>

                <div style={{display: "flex", width: "100%", height: "136px", gap: 8, marginBottom: -8}}
                >
                    <div style={{
                        width: "100%",
                        alignItems: "center",
                        borderRadius: 8,
                        padding: 8,
                        display: "flex",
                        flexDirection: "row",
                        background: "#3F3F46",
                        gap: 8,
                    }}
                    >
                        <div style={{
                            height: "70px",
                            position: "relative",
                            color: 'white',
                            fontSize: 28,
                            fontFamily: 'Inter',
                            display: "flex",
                            gap: 8,
                        }}
                        >
                            <div style={{
                                width: '100%',
                                height: '100%',
                                paddingLeft: 4,
                                paddingRight: 8,
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >

                                {
                                    this.state.focused_assessment !== "" ?
                                        this.get_icon(this.state.focused_answer)
                                        :
                                        <ITemplate style={{
                                            color: "#A1DAF8",
                                            width: 64,
                                            height: 64
                                        }}/>
                                }


                                <div style={{width: 1, height: "100%", backgroundColor: "#A1A1AA"}}/>
                                <div style={{
                                    height: '100%',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: "column"
                                }}
                                >
                                    <div style={{
                                        alignItems: 'flex-start',
                                        fontWeight: '600',
                                        display: 'flex'
                                    }}>
                                        {
                                            this.state.focused_assessment !== "" ?
                                                this.state.focused_answer.name
                                                :
                                                "Governance Risk & Compliance"
                                        }
                                    </div>
                                    {
                                        this.state.focused_assessment !== "" ?
                                            <div style={{
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>
                                                {this.state.focused_answer.sub}
                                            </div>
                                            : ""
                                    }

                                    {
                                        this.state.focused_assessment !== "" ?
                                            <div style={{
                                                marginTop: 4,
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                gap: 8,

                                            }}>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    letterSpacing: 0.06,
                                                    wordWrap: 'break-word'
                                                }}>Last Updated:
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    letterSpacing: 0.06,
                                                    wordWrap: 'break-word'
                                                }}>{get_time_string(this.state.focused_answer.updated, 2)}
                                                </div>
                                            </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{
                            marginLeft: "auto",
                            height: 'auto',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            width: "1200px",
                            flexDirection: "row",
                            marginRight: 16,
                            flexWrap: "wrap",
                            display: 'inline-flex',
                            gap: 8
                        }}>
                            {
                                Object.entries(this.get_tags(this.state.focused_assessment !== "" ? this.state.focused_answer.sections :
                                    this.state.baseline_answer.sections ? this.state.baseline_answer.sections : this.state.baseline_template.sections)).map(([tag, percentage]) =>
                                    <div style={{
                                        height: '24px',
                                        padding: "2px 8px 2px 8px",
                                        color: '#18181B',
                                        background: getScoreColor(percentage),
                                        borderRadius: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontFamily: 'Inter',
                                        whiteSpace: 'nowrap',
                                        fontWeight: '600',
                                        fontSize: 16,
                                        display: 'inline-flex'
                                    }}>
                                        {tag}
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div style={{
                        width: "186px",
                        alignItems: "center",
                        borderRadius: 8,
                        padding: 8,
                        display: "flex",
                        flexDirection: "column",
                        background: "#3F3F46",
                        gap: 8,
                    }}
                    >
                        <div style={{width: "60%"}}>
                            {
                                (() => {
                                    let score = this.get_final_score(this.state.focused_assessment !== "" ? this.state.focused_answer : this.state.baseline_answer)
                                    return (<CircularProgressbarWithChildren
                                        value={score} background={true}
                                        strokeWidth={5}
                                        styles={buildStyles({
                                            textSize: '25px',
                                            pathTransitionDuration: 0.5,
                                            trailColor: getScoreColor(score, 0.25),
                                            pathColor: getScoreColor(score),
                                            backgroundColor: "#27272A"
                                        })}
                                    >
                                        <div style={{
                                            color: 'white',
                                            fontSize: 28,
                                            fontFamily: 'Inter',
                                            fontWeight: '600'
                                        }}>{score}</div>
                                    </CircularProgressbarWithChildren>)
                                })()
                            }
                        </div>

                        <div style={{
                            color: '#A1A1AA',
                            fontSize: 16,
                            fontFamily: 'Inter',
                            fontWeight: '600',
                            wordWrap: 'break-word'
                        }}>
                            {
                                this.state.focused_assessment !== "" ?
                                    "Alignment Score"
                                    :
                                    "GRC Baseline Score"
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.focused_assessment === "" ?
                        <div style={{width: "100%", display: "flex"}}>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                height: 1,
                                width: "81%",
                                flexDirection: "row",
                                gap: 16
                            }}
                            >
                                {
                                    this.state.company.startsWith("KryptoKloud") ?
                                        <div style={{
                                            userSelect: "none",
                                            width: '368px',
                                            height: '208px',
                                            padding: "16px 8px 8px",
                                            background: '#27272A',
                                            borderRadius: 8,
                                            border: '2px #F43F5E dashed',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex',
                                            cursor: "pointer",
                                            gap: 16,
                                        }}
                                             onClick={() => this.setState({showBuilder: true})}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    width: 64, height: 64
                                                }}>
                                                    <IPlus style={{color: "#F43F5E", width: 64, height: 64}}/>
                                                </div>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        color: 'white',
                                                        fontSize: 28,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Create New Template
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                paddingLeft: 8,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 10,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>Create a new template based on an existing one or start from scratch.
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }

                                <div style={{
                                    userSelect: "none",
                                    width: '368px',
                                    height: '208px',
                                    padding: "16px 8px 8px",
                                    background: '#27272A',
                                    borderRadius: 8,
                                    border: '2px #A1DAF8 dashed',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex',
                                    cursor: "pointer",
                                    gap: 16,
                                }}
                                     onClick={() => {
                                         if (this.state.answers.length > 0) {
                                            this.setState({showPicker: true})
                                         } else {
                                             let data = {}
                                             let focused = ""
                                             this.state.templates.forEach(function (template, index) {
                                                 if (template.name.toLowerCase() === "grc baseline") {
                                                     focused = template.name
                                                     data = template
                                                 }
                                             }, this.state.templates);
                                             this.add_grc_answers(focused, data)
                                         }
                                     }}
                                >
                                    <div style={{
                                        alignSelf: 'stretch',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}
                                    >
                                        <div style={{
                                            width: 64, height: 64
                                        }}>
                                            <IPlus style={{color: "#A1DAF8", width: 64, height: 64}}/>
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                color: 'white',
                                                fontSize: 28,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                wordWrap: 'break-word'
                                            }}>
                                                {
                                                    this.state.answers.length > 0 ?
                                                        "New GRC Assessment" : "New Baseline Assessment"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        paddingLeft: 8,
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'inline-flex'
                                    }}
                                    >
                                        <div style={{
                                            color: 'white',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '400',
                                            wordWrap: 'break-word'
                                        }}>
                                            {
                                                this.state.answers.length > 0 ?
                                                    "Start a new self assessment based on one of the templates" : "Start the baseline GRC assessment"
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.answers.map((item, i) =>
                                        <div style={{
                                            userSelect: "none",
                                            width: '368px',
                                            height: '208px',
                                            padding: 8,
                                            background: '#27272A',
                                            borderRadius: 8,
                                            border: '1px #A1A1AA solid',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex',
                                            cursor: "pointer"
                                        }}
                                             onClick={() => {
                                                 this.setState({
                                                     focused_assessment: item.name,
                                                     focused_answer: item,
                                                     focused_section: "S1"
                                                 })
                                             }}
                                        >
                                            <div style={{
                                                width: '100%',
                                                height: '100%',
                                                paddingTop: 8,
                                                gap: 16,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    paddingLeft: 8,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 10,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>V{item["version"]}
                                                    </div>
                                                    <div style={{
                                                        marginLeft: "auto",
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>{get_time_string(item["updated"])}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    height: 48,
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{width: "64px"}}>
                                                        {
                                                            (() => {
                                                                let score = this.get_final_score(item)
                                                                return (<CircularProgressbarWithChildren
                                                                    value={score} background={true}
                                                                    strokeWidth={5}
                                                                    styles={buildStyles({
                                                                        textSize: '25px',
                                                                        pathTransitionDuration: 0.5,
                                                                        trailColor: getScoreColor(score, 0.25),
                                                                        pathColor: getScoreColor(score),
                                                                        backgroundColor: "#27272A"
                                                                    })}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 20,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600'
                                                                    }}>{score}</div>
                                                                </CircularProgressbarWithChildren>)
                                                            })()
                                                        }
                                                    </div>
                                                    <div style={{width: 1, height: "100%", background: "#A1A1AA"}}/>
                                                    <div style={{
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>{item["name"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    paddingLeft: 8,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>{item["description"]}
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    color: "white",
                                                    gap: 8,
                                                    marginTop: "auto",
                                                    alignItems: "center"
                                                }}>
                                                    <ITextInput style={{width: 24, height: 24}}/>
                                                    <div style={{fontSize: 16, fontFamily: 'Inter', fontWeight: '400'}}>
                                                        {item["note"]}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.company.startsWith("KryptoKloud") ?
                                        this.state.templates.map((item, i) =>
                                            <div style={{
                                                userSelect: "none",
                                                width: '368px',
                                                height: '208px',
                                                padding: 8,
                                                background: '#27272A',
                                                borderRadius: 8,
                                                border: '2px #A1DAF8 dashed',
                                                borderColor: item["published"] ? "#10B981" : "#F59E0B",
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                cursor: "pointer"
                                            }}
                                                 onClick={() => {
                                                     this.setState({modal_data: item, showBuilder: true})
                                                 }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    paddingTop: 8,
                                                    gap: 16,
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        width: '100%',
                                                        paddingLeft: 8,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 10,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            wordWrap: 'break-word'
                                                        }}>V{item["version"]}
                                                        </div>
                                                        <div style={{
                                                            marginLeft: "auto",
                                                            color: 'white',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                            wordWrap: 'break-word'
                                                        }}>{get_time_string(item["updated"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        height: 48,
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            width: 64, height: 64
                                                        }}>
                                                            {this.get_icon(item)}
                                                        </div>
                                                        <div style={{width: 1, height: "100%", background: "#A1A1AA"}}/>
                                                        <div style={{
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                alignSelf: 'stretch',
                                                                color: 'white',
                                                                fontSize: 28,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>{item["name"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        paddingLeft: 8,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>{item["description"]}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""
                                }
                            </div>
                            <div style={{display: "flex", flexDirection: "column", width: "19%"}}>
                                <div className="inner-tip-box"
                                     style={{
                                         height: "100px",
                                         paddingLeft: "16px",
                                         justifyContent: "center",
                                         alignItems: "flex-start"
                                     }}
                                >
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 16
                                    }}
                                         id="number-suppliers">
                                        <div style={{
                                            color: 'white',
                                            fontSize: 48,
                                            width: "85px",
                                            textAlign: "center",
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                        }}>
                                            {this.state.ss_data.length}
                                        </div>
                                        <div style={{
                                            color: '#A1DAF8',
                                            fontSize: 16,
                                            fontFamily: 'Inter',
                                            fontWeight: '600',
                                            letterSpacing: 0.03,
                                            width: 325,
                                            wordWrap: 'break-word'
                                        }}>
                                            Supplier(s) Proactively Monitored by Supply Chain Sentry
                                        </div>
                                    </div>
                                </div>
                                <div className="cp-scroll-bar-div" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0px 8px 16px 8px",
                                    gap: "8px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    height: "850px"
                                }}
                                >
                                    {
                                        this.state.ss_data.length > 0 ?
                                            this.state.ss_data.map((item, i) =>
                                                <div style={{
                                                    width: "100%",
                                                    marginTop: 8,
                                                    flexDirection: 'column',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        width: "100%",
                                                        paddingLeft: 4,
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'flex'
                                                    }}
                                                    >
                                                        <CircleFlag height={24}
                                                                    countryCode={getISO(item["country"], true)}/>
                                                        <div style={{
                                                            minHeight: 40,
                                                            paddingRight: 4,
                                                            width: 340,
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                flexDirection: 'column',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {item["domain"]}
                                                                </div>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    letterSpacing: 0.06,
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {item["supplier"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            height: 32,
                                                            paddingRight: 4,
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                width: 135,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                letterSpacing: 0.06,
                                                                wordWrap: 'break-word',
                                                            }}>
                                                                {get_time_string(item["date"])}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 1,
                                                        background: '#52525B'
                                                    }}/>
                                                </div>
                                            )
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>

                        :
                        <div style={{display: "flex", width: "100%", height: "auto",
                            gap: 8, flexDirection: "column", marginBottom: 8}}>
                            <div style={{
                                width: '100%',
                                height: '52px',
                                padding: 8,
                                background: '#3F3F46',
                                borderRadius: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <div style={{
                                    width: 512,
                                    paddingRight: 4,
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'flex'
                                }}
                                >
                                    <ITextInput
                                        style={{
                                            color: 'white',
                                        }}
                                    />
                                    <input className="transparent-input" type='text' name='section_weight'
                                           value={this.state.focused_answer.note}
                                           style={{
                                               border: '1px #A1A1AA solid',
                                               color: "white",
                                               textAlign: "left",
                                               paddingLeft: 8,
                                               fontSize: 16,
                                               fontWeight: '600',
                                               width: 182,
                                               borderRadius: 4
                                           }}
                                           onChange={(data) => {
                                               if (!this.state.focused_answer.submitted) {
                                                   let m_data = this.state.focused_answer
                                                   m_data.note = data.target.value
                                                   this.setState({focused_answer: m_data})
                                               }
                                           }}
                                    />
                                </div>
                                <div style={{
                                    flex: '1 1 0',
                                    height: 36,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: 8,
                                    display: 'flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>V{this.state.focused_answer.version}
                                    </div>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: '#713F12',
                                        borderRadius: 4,
                                        border: '1px #FACC15 solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                        display: 'flex',
                                        gap: 4,
                                    }}
                                        onClick={() => this.setState({showCRQ: true})}
                                    >
                                        <IShieldCheck style={{color: "#FACC15"}}/>
                                        <div style={{
                                            color: '#FACC15',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            whiteSpace: 'nowrap',
                                            userSelect: "none",
                                        }}>Cyber Risk Quantification
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 115,
                                        alignSelf: 'stretch',
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: 'rgba(6, 182, 212, 0.25)',
                                        borderRadius: 4,
                                        border: '1px #06B6D4 solid',
                                        justifyContent: 'center',
                                        whiteSpace: 'nowrap',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                        display: 'flex'
                                    }}
                                         onClick={() => this.save_progress()}
                                         hidden={this.state.focused_answer.submitted}
                                    >
                                        <div style={{
                                            color: '#06B6D4',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            wordWrap: 'break-word',
                                            userSelect: "none"
                                        }}>Save Progress
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            let all_answered = this.all_focused_answered() && this.state.focused_answer.note && this.state.focused_answer.note.length > 0
                                            if (this.state.focused_answer.submitted) return ("")
                                            return (<div style={{
                                                width: 73,
                                                alignSelf: 'stretch',
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                background: all_answered ? 'rgba(6, 182, 212, 0.25)' : 'rgba(113, 113, 122, 0.25)',
                                                borderRadius: 4,
                                                border: all_answered ? '1px #06B6D4 solid' : '1px #A1A1AA solid',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: all_answered ? "pointer" : "initial",
                                                display: 'flex'
                                            }}
                                                         onClick={() => {
                                                             if (all_answered) this.submit_grc_answers()
                                                         }}>
                                                <div style={{
                                                    color: all_answered ? '#06B6D4' : '#A1A1AA',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    userSelect: "none",
                                                    fontWeight: '500',
                                                }}>Submit
                                                </div>
                                            </div>)
                                        })()
                                    }
                                    <div style={{
                                        alignSelf: 'stretch',
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: 'rgba(113, 113, 122, 0.25)',
                                        borderRadius: 4,
                                        border: '1px #A1A1AA solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }} hidden={!this.state.focused_answer.submitted}>
                                        <div style={{
                                            color: '#A1A1AA',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            whiteSpace: 'nowrap',
                                            userSelect: "none",
                                        }}>Generate AI Powered Report
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 1,
                                        height: "100%",
                                        background: "#71717A"
                                    }}/>
                                    <div style={{
                                        width: 140,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: 'rgba(6, 182, 212, 0.25)',
                                        borderRadius: 4,
                                        border: '1px #06B6D4 solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                        display: 'flex'
                                    }}
                                         onClick={() => {
                                             this.setState({
                                                 focused_assessment: "",
                                                 focused_answer: {},
                                                 focused_section: "S1"
                                             })
                                         }}
                                    >
                                        <div style={{
                                            color: '#06B6D4',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            userSelect: "none",
                                            whiteSpace: 'nowrap',
                                        }}>Close Assessment
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 160,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: 'rgba(6, 182, 212, 0.25)',
                                        borderRadius: 4,
                                        border: '1px #06B6D4 solid',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                        display: 'flex'
                                    }}
                                         onClick={() => {
                                             let focused = this.state.focused_answer
                                             focused.submitted = !focused.submitted
                                             this.setState({
                                                 focused_answer: focused
                                             })
                                         }}
                                         hidden={this.state.focused_answer.submitted === undefined}
                                    >
                                        <div style={{
                                            color: '#06B6D4',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            userSelect: "none",
                                            whiteSpace: 'nowrap',
                                        }}>Toggle Recommendation
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 90,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        background: 'rgba(244, 63, 94, 0.25)',
                                        borderRadius: 4,
                                        border: '1px #FB7185 solid',
                                        color: '#FB7185',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 4,
                                        display: 'flex'
                                    }}
                                         hidden={this.state.focused_answer.submitted}
                                    >
                                        <ITrash style={{
                                            width: 18,
                                            height: 18,
                                        }}/>
                                        <div style={{
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            userSelect: "none",
                                            wordWrap: 'break-word'
                                        }}>
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                display: 'inline-flex',
                                gap: 10
                            }}
                                 hidden={!this.state.focused_answer.submitted}
                            >
                                <div style={{
                                    alignSelf: 'stretch',
                                    padding: 8,
                                    background: '#3F3F46',
                                    borderRadius: 8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: 8,
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        width: 350,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}
                                    >
                                        <div style={{
                                            height: 36,
                                            paddingRight: 4,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}>
                                            <IRisk style={{width: 24, height: 24, color: 'white'}}/>
                                            <div style={{
                                                color: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                wordWrap: 'break-word'
                                            }}>30-Day Risk Summary
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                let numbers = this.get_section_numbers_sum()
                                                return (
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        display: 'inline-flex',
                                                        fontWeight: '700',
                                                        height: 36,
                                                        gap: 8,
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: '#34D399',
                                                            alignSelf: 'stretch',
                                                            width: 64,
                                                            borderRadius: 4,
                                                            fontSize: 16,
                                                            border: '1px #064E3B solid',
                                                            color: "#064E3B",
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            {numbers[0]}
                                                        </div>
                                                        <div style={{
                                                            background: '#FBBF24',
                                                            color: "#78350F",
                                                            alignSelf: 'stretch',
                                                            width: 64,
                                                            borderRadius: 4,
                                                            fontSize: 16,
                                                            border: '1px #064E3B solid',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            {numbers[1]}
                                                        </div>
                                                        <div style={{
                                                            background: '#FB7185',
                                                            color: "#881337",
                                                            alignSelf: 'stretch',
                                                            width: 64,
                                                            borderRadius: 4,
                                                            fontSize: 16,
                                                            border: '1px #064E3B solid',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            {numbers[2]}
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }
                                    </div>
                                    <div style={{
                                        width: 1,
                                        height: "100%",
                                        transformOrigin: '0 0',
                                        border: '1px #A1A1AA dashed'
                                    }}/>
                                    <div style={{
                                        width: 350,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{
                                            height: 36,
                                            paddingRight: 4,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <ISupplyChain style={{width: 24, height: 24, color: 'white'}}/>
                                            <div style={{
                                                color: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                whiteSpace: 'nowrap'
                                            }}>Supply Chain Alerts
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            height: 36,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}>
                                            <div style={{
                                                width: 64,
                                                alignSelf: 'stretch',
                                                background: '#064E3B',
                                                borderRadius: 4,
                                                border: '1px #34D399 solid',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: '#34D399',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                display: "flex",
                                                fontWeight: '700'
                                            }}>
                                                {this.state.risk_detections.sentry.length}
                                            </div>
                                            <div style={{
                                                flex: '1 1 0',
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>Count of 3rd Party Suppliers that Suffered a Breach over
                                                the last 12 months
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 1,
                                        height: "100%",
                                        border: '1px #A1A1AA dashed'
                                    }}/>
                                    <div style={{
                                        width: 350,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{
                                            height: 36,
                                            paddingRight: 4,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}>
                                            <IFingerprint style={{width: 24, height: 24, color: 'white'}}/>
                                            <div style={{
                                                color: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                wordWrap: 'break-word'
                                            }}>Compromised Credentials
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            height: 36,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}>
                                            <div style={{
                                                width: 64,
                                                alignSelf: 'stretch',
                                                background: '#581C87',
                                                borderRadius: 4,
                                                border: '1px #C084FC solid',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: '#C084FC',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                display: "flex",
                                                fontWeight: '700'
                                            }}>
                                                {this.state.risk_detections.credentials.length}
                                            </div>
                                            <div style={{
                                                flex: '1 1 0',
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400',
                                                wordWrap: 'break-word'
                                            }}>Count of Credentials Discovered for Sale on Dark Web Marketplaces
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: 1,
                                        height: "100%",
                                        border: '1px #A1A1AA dashed'
                                    }}/>
                                    <div style={{
                                        width: 350,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}>
                                        <div style={{
                                            height: 36,
                                            paddingRight: 4,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}>
                                            <IScan style={{width: 24, height: 24, color: 'white'}}/>
                                            <div style={{
                                                color: 'white',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                fontWeight: '600',
                                                wordWrap: 'break-word'
                                            }}>Shadow Scan
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: 'stretch',
                                            height: 36,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 9,
                                            display: 'inline-flex'
                                        }}>
                                            <div style={{
                                                width: 64,
                                                alignSelf: 'stretch',
                                                background: '#7C2D12',
                                                borderRadius: 4,
                                                border: '1px #FB923C solid',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: 16,
                                                color: '#FB923C',
                                                fontFamily: 'Inter',
                                                fontWeight: '700',
                                                display: 'flex'
                                            }}>
                                                0
                                            </div>
                                            <div style={{
                                                flex: '1 1 0',
                                                color: 'white',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '400',
                                                letterSpacing: 0.06,
                                                wordWrap: 'break-word'
                                            }}>Count of Externally Exploited Vulnerabilities
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        alignSelf: 'stretch',
                                        flex: '1 1 0',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: 8,
                                        display: 'inline-flex'
                                    }}
                                         hidden={!this.state.focused_answer.name || !this.state.focused_answer.name.toLowerCase().includes("cobra")}
                                    >
                                        <div style={{
                                            width: 1,
                                            height: "100%",
                                            border: '1px #A1A1AA dashed'
                                        }}/>
                                        <div style={{
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                background: '#0C4A6E',
                                                borderRadius: 4,
                                                border: '1px #38BDF8 solid',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: "35px",
                                                padding: "8px 16px",
                                                color: '#38BDF8',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '500',
                                                wordWrap: 'break-word',
                                                display: 'inline-flex',
                                                userSelect: "none",
                                                gap: 4
                                            }}>
                                                <IGauge style={{width: 18, height: 18, color: '#38BDF8'}}/>
                                                100 Day Re-Audit
                                            </div>
                                            <div style={{
                                                alignSelf: 'stretch',
                                                padding: "8px 16px",
                                                background: '#38BDF8',
                                                color: '#0C4A6E',
                                                fontSize: 12,
                                                fontFamily: 'Inter',
                                                fontWeight: '500',
                                                wordWrap: 'break-word',
                                                borderRadius: 4,
                                                border: '1px #0C4A6E solid',
                                                userSelect: "none",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'inline-flex',
                                                gap: 4
                                            }}>
                                                <IShieldCheck
                                                    style={{width: 18, height: 18, color: '#0C4A6E'}}/>
                                                ASM Scan
                                            </div>
                                        </div>
                                        <div style={{
                                            width: 68,
                                            alignSelf: 'stretch',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}>
                                            {this.get_icon(this.state.focused_answer)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                height: "950px",
                                gap: 8,
                                flexDirection: "row"
                            }}>
                                <div style={{
                                    gap: 8,
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <div style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: 8,
                                        background: '#18181B',
                                        padding: 16,
                                        gap: 16,
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                    >
                                        <div style={{
                                            color: '#F9B233',
                                            fontSize: 16,
                                            fontFamily: 'Inter',
                                            fontWeight: '600'
                                        }}
                                             onClick={() => console.log(this.state)}
                                        >{this.state.focused_answer.name} Assessment
                                        </div>
                                        <div
                                            className="grc-scroll-bar-div"
                                            style={{
                                                background: "#27272A",
                                                borderRadius: 4,
                                                width: '100%',
                                                height: "972px",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 16,
                                                padding: 8,
                                                paddingTop: 16,
                                                overflow: "auto"
                                            }}
                                        >
                                            {
                                                Object.entries(this.state.focused_answer.sections).sort(template_sorter).map(([key, value]) =>
                                                    <div style={{
                                                        width: '100%',
                                                        height: '48px',
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 8,
                                                        display: 'inline-flex',
                                                        borderBottom: '1px #A1A1AA dashed',
                                                        cursor: "pointer"
                                                    }}
                                                         onClick={() => {
                                                             this.setState({focused_section: key})
                                                         }}
                                                    >
                                                        {
                                                            (() => {
                                                                let numbers = this.get_section_numbers(value)
                                                                let color = "rgba(161,161,170,1)"
                                                                if (numbers[2] > 0) {
                                                                    color = "rgba(244,63,94,1)"
                                                                } else if (numbers[1] > 0) {
                                                                    color = "rgba(245,158,11,1)"
                                                                } else if (numbers[0] > 0) {
                                                                    color = "rgba(16,185,129,1)"
                                                                }
                                                                return (
                                                                    <div style={{
                                                                        width: "100%",
                                                                        height: 32,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        gap: 8,
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            width: 24,
                                                                            height: 24,
                                                                            padding: 4,
                                                                            background: 'rgba(113, 113, 122, 0.25)',
                                                                            borderRadius: 16,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'inline-flex'
                                                                        }}>
                                                                            <div style={{
                                                                                width: 16,
                                                                                height: 16,
                                                                                position: 'relative',
                                                                                background: color,
                                                                                borderRadius: 999
                                                                            }}/>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column"
                                                                        }}>
                                                                            <div style={{
                                                                                alignSelf: 'stretch',
                                                                                color: 'white',
                                                                                fontSize: 16,
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600',
                                                                                wordWrap: 'break-word'
                                                                            }}>{key} - {value.name}
                                                                            </div>
                                                                            <div style={{
                                                                                color: '#A1A1AA',
                                                                                fontSize: 12,
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '400',
                                                                                wordWrap: 'break-word'
                                                                            }}>{value.sub}
                                                                            </div>
                                                                        </div>

                                                                        <div style={{
                                                                            width: 112,
                                                                            alignSelf: 'stretch',
                                                                            marginLeft: "auto",
                                                                            justifyContent: 'flex-end',
                                                                            alignItems: 'flex-start',
                                                                            gap: 8,
                                                                            display: 'flex'
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                width: 32,
                                                                                height: 20,
                                                                                background: numbers[0] === 0 ? '#71717A' : '#10B981',
                                                                                borderRadius: 4,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600',
                                                                                fontSize: 12,
                                                                                display: 'flex'
                                                                            }}>
                                                                                {numbers[0] === 0 ? "-" : numbers[0]}
                                                                            </div>
                                                                            <div style={{
                                                                                width: 32,
                                                                                height: 20,
                                                                                background: numbers[1] === 0 ? '#71717A' : '#F59E0B',
                                                                                borderRadius: 4,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600',
                                                                                fontSize: 12,
                                                                                display: 'flex'
                                                                            }}>
                                                                                {numbers[1] === 0 ? "-" : numbers[1]}
                                                                            </div>
                                                                            <div style={{
                                                                                width: 32,
                                                                                height: 20,
                                                                                background: numbers[2] === 0 ? '#71717A' : '#F43F5E',
                                                                                borderRadius: 4,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600',
                                                                                fontSize: 12,
                                                                                display: 'flex'
                                                                            }}>
                                                                                {numbers[2] === 0 ? "-" : numbers[2]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })()
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.focused_answer.submitted ?
                                        <div style={{
                                            height: "100%",
                                            width: "50%",
                                            borderRadius: 4,
                                            background: 'transparent',
                                            display: "flex",
                                            gap: 8,
                                            flexDirection: "column",
                                        }}
                                        >
                                            <div style={{
                                                height: "620px",
                                                width: "100%",
                                                borderRadius: 4,
                                                background: '#18181B',
                                                padding: 8,
                                                flex: "1 1 0",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                            >
                                                <div style={{
                                                    color: '#F9B233',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    marginBottom: 16
                                                }}>Risks & Recommendations
                                                </div>

                                                {
                                                    (() => {
                                                        let numbers = this.get_section_numbers(this.state.focused_answer.sections[this.state.focused_section])
                                                        let color = "rgba(161,161,170,1)"
                                                        if (numbers[2] > 0) {
                                                            color = "rgba(244,63,94,1)"
                                                        } else if (numbers[1] > 0) {
                                                            color = "rgba(245,158,11,1)"
                                                        } else if (numbers[0] > 0) {
                                                            color = "rgba(16,185,129,1)"
                                                        }
                                                        return (
                                                            <div style={{
                                                                width: "100%",
                                                                height: 48,
                                                                background: "rgb(39, 39, 42)",
                                                                borderTopLeftRadius: 4,
                                                                borderTopRightRadius: 4,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                padding: 8,
                                                                paddingTop: 16,
                                                                gap: 8,
                                                            }}>
                                                                <div style={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    padding: 4,
                                                                    background: 'rgba(113, 113, 122, 0.25)',
                                                                    borderRadius: 16,
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        width: 16,
                                                                        height: 16,
                                                                        position: 'relative',
                                                                        background: color,
                                                                        borderRadius: 999
                                                                    }}/>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.focused_section} - {this.state.focused_answer.sections[this.state.focused_section].name}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.focused_answer.sections[this.state.focused_section].sub}
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    width: 112,
                                                                    alignSelf: 'stretch',
                                                                    marginLeft: "auto",
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'flex-start',
                                                                    gap: 8,
                                                                    display: 'flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        width: 32,
                                                                        height: 20,
                                                                        background: numbers[0] === 0 ? '#71717A' : 'transparent',
                                                                        border: numbers[0] === 0 ? 'revert' : '1px #10B981 solid',
                                                                        borderRadius: 4,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        fontSize: 12,
                                                                        display: 'flex'
                                                                    }}>
                                                                        {numbers[0] === 0 ? "-" : numbers[0]}
                                                                    </div>
                                                                    <div style={{
                                                                        width: 32,
                                                                        height: 20,
                                                                        background: numbers[1] === 0 ? '#71717A' : '#F59E0B',
                                                                        borderRadius: 4,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        fontSize: 12,
                                                                        display: 'flex'
                                                                    }}>
                                                                        {numbers[1] === 0 ? "-" : numbers[1]}
                                                                    </div>
                                                                    <div style={{
                                                                        width: 32,
                                                                        height: 20,
                                                                        background: numbers[2] === 0 ? '#71717A' : '#F43F5E',
                                                                        borderRadius: 4,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        fontSize: 12,
                                                                        display: 'flex'
                                                                    }}>
                                                                        {numbers[2] === 0 ? "-" : numbers[2]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        let recommendations = this.get_section_recommendations(this.state.focused_answer.sections[this.state.focused_section])
                                                        let numbers = this.get_section_numbers(this.state.focused_answer.sections[this.state.focused_section])
                                                        if (recommendations.length > 0 && (numbers[1] > 0 || numbers[2] > 0)) {
                                                            return (
                                                                <div
                                                                    className="grc-scroll-bar-div"
                                                                    style={{
                                                                        background: "rgb(39, 39, 42)",
                                                                        paddingRight: 8,
                                                                        borderBottomLeftRadius: 4,
                                                                        borderBottomRightRadius: 4,
                                                                        height: "550px",
                                                                        width: '100%',
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        overflow: "auto",
                                                                        padding: "0 8px 8px",
                                                                    }}
                                                                >
                                                                    <div style={{display: "flex", gap: 8}}>
                                                                        <div style={{
                                                                            height: "100%",
                                                                            width: 23,
                                                                            display: "flex",
                                                                            justifyContent: "center"
                                                                        }}>
                                                                            <div style={{
                                                                                width: 2,
                                                                                height: "100%",
                                                                                background: "#71717A"
                                                                            }}/>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            flexDirection: "column",
                                                                            display: "flex",
                                                                            padding: 8,
                                                                            gap: 8
                                                                        }}>
                                                                        <div style={{display: "flex"}}>
                                                                            <div style={{
                                                                                width: 100,
                                                                                color: '#F9B233',
                                                                                fontSize: 16,
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                Risk:
                                                                            </div>
                                                                            <div style={{
                                                                                color: '#F9B233',
                                                                                fontSize: 16,
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                Recommendations:
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            recommendations.map((recommendation, index) =>
                                                                                <div style={{display: "flex"}}>
                                                                                    <div style={{
                                                                                        width: "100px",
                                                                                        paddingTop: 4,
                                                                                        color: '#F9B233',
                                                                                        fontSize: 16,
                                                                                        fontFamily: 'Inter',
                                                                                        fontWeight: '600'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            width: '82px',
                                                                                            height: '24px',
                                                                                            paddingLeft: 4,
                                                                                            paddingRight: 4,
                                                                                            paddingTop: 2,
                                                                                            paddingBottom: 2,
                                                                                            background: recommendation.level === 0 ? '#F43F5E' : recommendation.level === 1 ? "#F97316" : "#F59E0B",
                                                                                            borderRadius: 4,
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            gap: 10,
                                                                                            display: 'inline-flex'
                                                                                        }}
                                                                                        >
                                                                                            <div style={{
                                                                                                flex: '1 1 0',
                                                                                                height: 16,
                                                                                                justifyContent: 'center',
                                                                                                alignItems: 'center',
                                                                                                gap: 4,
                                                                                                display: 'flex'
                                                                                            }}>
                                                                                                {
                                                                                                    recommendation.level === 0 ?
                                                                                                        <IRisk style={{
                                                                                                            width: 16,
                                                                                                            height: 16,
                                                                                                            color: '#881337'
                                                                                                        }}/>
                                                                                                        : recommendation.level === 1 ?
                                                                                                            <IMed style={{
                                                                                                                width: 16,
                                                                                                                height: 16,
                                                                                                                color: '#7C2D12'
                                                                                                            }}/>
                                                                                                            :
                                                                                                            <ILow style={{
                                                                                                                width: 16,
                                                                                                                height: 16,
                                                                                                                color: '#78350F'
                                                                                                            }}/>
                                                                                                }

                                                                                                <div style={{
                                                                                                    textAlign: 'center',
                                                                                                    color: recommendation.level === 0 ? '#881337' : recommendation.level === 1 ? "#7C2D12" : "#78350F",
                                                                                                    fontSize: 12,
                                                                                                    fontFamily: 'Inter',
                                                                                                    fontWeight: '600',
                                                                                                    wordWrap: 'break-word'
                                                                                                }}>
                                                                                                    {recommendation.level === 0 ? "HIGH" : recommendation.level === 1 ? "MEDIUM" : "LOW"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: '80%',
                                                                                    }}
                                                                                    >
                                                                                        <div style={{
                                                                                            color: 'white',
                                                                                            fontSize: 16,
                                                                                            fontFamily: 'Inter',
                                                                                            fontWeight: '600',
                                                                                            wordWrap: 'break-word'
                                                                                        }}>{recommendation.question}
                                                                                        </div>
                                                                                        <div style={{
                                                                                            color: '#A1A1AA',
                                                                                            fontSize: 12,
                                                                                            fontFamily: 'Inter',
                                                                                            fontWeight: '400',
                                                                                            wordWrap: 'break-word'
                                                                                        }}>{recommendation.recommendation}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <div style={{
                                                                width: '100%',
                                                                height: "100%",
                                                                justifyContent: 'center',
                                                                background: "rgb(39, 39, 42)",
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: 8,
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        height: 64,
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        gap: 8,
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            alignSelf: 'stretch',
                                                                            flex: '1 1 0',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            gap: 10,
                                                                            display: 'inline-flex'
                                                                        }}>
                                                                            <ITrophy style={{
                                                                                width: 24,
                                                                                height: 24,
                                                                                color: "#A1A1AA"
                                                                            }}/>
                                                                        </div>
                                                                        <div style={{
                                                                            alignSelf: 'stretch',
                                                                            textAlign: 'center',
                                                                            color: '#A1A1AA',
                                                                            fontSize: 20,
                                                                            fontFamily: 'Inter',
                                                                            fontWeight: '600',
                                                                            wordWrap: 'break-word'
                                                                        }}>Congratulations!
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        textAlign: 'center',
                                                                        color: '#A1A1AA',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>There are no immediate risks in the selected
                                                                        section
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })()
                                                }
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                background: "#18181B",
                                                padding: 8,
                                                borderRadius: 8,
                                                gap: 8,
                                                height: "30%",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 8,
                                                    width: "100%"
                                                }}
                                                >
                                                    <div style={{
                                                        color: '#F9B233',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Supply Chain Risk
                                                    </div>

                                                    <div style={{
                                                        width: "100%",
                                                        height: 350,
                                                        background: "rgb(39, 39, 42)",
                                                        borderRadius: 4,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        padding: 8,
                                                        gap: 8,
                                                    }}
                                                    >
                                                        <div style={{
                                                            width: "100%",
                                                            height: 48,
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                        }}>
                                                            <div style={{
                                                                width: 24,
                                                                height: 24,
                                                                padding: 4,
                                                                background: 'rgba(113, 113, 122, 0.25)',
                                                                borderRadius: 16,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    background: "#10B981",
                                                                    position: 'relative',
                                                                    borderRadius: 999
                                                                }}/>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column"
                                                            }}>
                                                                <div style={{
                                                                    alignSelf: 'stretch',
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Supply Chain Risk
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Supply Chain Sentry Detections in the past 12 months
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="grc-scroll-bar-div" style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: "8px",
                                                            gap: "8px",
                                                            overflowY: "scroll",
                                                            overflowX: "hidden",
                                                            width: "100%",
                                                            height: "150px"
                                                        }}>

                                                            {
                                                                this.state.risk_detections.sentry.map((item, i) =>
                                                                    <div style={{
                                                                        gap: "8px",
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        <div style={{
                                                                            alignSelf: 'stretch',
                                                                            justifyContent: 'flex-start',
                                                                            alignItems: "center",
                                                                            display: 'inline-flex',
                                                                            gap: 8
                                                                        }}
                                                                        >
                                                                            <CircleFlag height={24}
                                                                                            countryCode={getISO(item["country"], true)}/>
                                                                            <div style={{
                                                                                width: "52%",
                                                                                flexDirection: 'column',
                                                                                justifyContent: 'flex-start',
                                                                                alignItems: 'flex-start',
                                                                                display: 'inline-flex'
                                                                            }}>

                                                                                <div style={{
                                                                                    alignSelf: 'stretch',
                                                                                    color: 'white',
                                                                                    fontSize: 16,
                                                                                    fontFamily: 'Inter',
                                                                                    fontWeight: '600',
                                                                                    wordWrap: 'break-word'
                                                                                }}>{item["risk"]["post_title"]}
                                                                                </div>
                                                                                <div style={{
                                                                                    alignSelf: 'stretch',
                                                                                    color: 'white',
                                                                                    fontSize: 14,
                                                                                    fontFamily: 'Inter',
                                                                                    fontWeight: '400',
                                                                                    wordWrap: 'break-word'
                                                                                }}>{item["domain"]}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                color: '#A1A1AA',
                                                                                fontSize: 12,
                                                                                fontFamily: 'Inter',
                                                                                fontWeight: '400',
                                                                                wordWrap: 'break-word'
                                                                            }}>
                                                                                Breach Date: {get_time_string(item["risk"]["date"] ? item["risk"]["date"] : item["risk"]["published"])}
                                                                                <br/>Breach Type: {item["breach_type"]}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            width: "100%",
                                                                            height: 1,
                                                                            borderBottom: '1px #A1A1AA dashed'
                                                                        }}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 8,
                                                    width: "100%"
                                                }}
                                                >
                                                    <div style={{
                                                        color: '#F9B233',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>Dark Web Analytics
                                                    </div>

                                                    <div style={{
                                                        width: "100%",
                                                        height: 350,
                                                        background: "rgb(39, 39, 42)",
                                                        borderRadius: 4,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        padding: 8,
                                                        gap: 8,
                                                    }}
                                                    >
                                                        <div style={{
                                                            width: "100%",
                                                            height: 48,
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                        }}>
                                                            <div style={{
                                                                width: 24,
                                                                height: 24,
                                                                padding: 4,
                                                                background: 'rgba(113, 113, 122, 0.25)',
                                                                borderRadius: 16,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    width: 16,
                                                                    height: 16,
                                                                    background: "#A855F7",
                                                                    position: 'relative',
                                                                    borderRadius: 999
                                                                }}/>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column"
                                                            }}>
                                                                <div style={{
                                                                    alignSelf: 'stretch',
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Compromised Credentials
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Discovered credentials on the dark web in the
                                                                    past 12 months
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            width: '100%',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex',
                                                            gap: 8,
                                                        }}>

                                                            <div className="grc-scroll-bar-div" style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                padding: "8px",
                                                                gap: "8px",
                                                                overflowY: "scroll",
                                                                overflowX: "hidden",
                                                                width: "100%",
                                                                height: "150px"
                                                            }}>
                                                                {
                                                                    this.state.risk_detections.credentials.map((item, i) =>
                                                                        <div style={{
                                                                            gap: "8px",
                                                                            display: "flex",
                                                                            flexDirection: "column"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                alignSelf: 'stretch',
                                                                                justifyContent: 'flex-start',
                                                                                alignItems: "center",
                                                                                display: 'inline-flex',
                                                                                gap: 8
                                                                            }}
                                                                            >
                                                                                <div style={{
                                                                                    width: "70%",
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'flex-start',
                                                                                    alignItems: 'flex-start',
                                                                                    display: 'inline-flex'
                                                                                }}
                                                                                >
                                                                                    <div style={{
                                                                                        alignSelf: 'stretch',
                                                                                        color: 'white',
                                                                                        fontSize: 16,
                                                                                        fontFamily: 'Inter',
                                                                                        fontWeight: '600',
                                                                                        wordWrap: 'break-word'
                                                                                    }}>{item["Credentials"][0]["Domain"]}
                                                                                    </div>
                                                                                    <div style={{
                                                                                        alignSelf: 'stretch',
                                                                                        color: 'white',
                                                                                        fontSize: 14,
                                                                                        fontFamily: 'Inter',
                                                                                        fontWeight: '400',
                                                                                        wordWrap: 'break-word'
                                                                                    }}>{item["Credentials"][0]["DeviceType"]}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'flex-start',
                                                                                    alignItems: 'flex-start',
                                                                                    display: 'inline-flex'
                                                                                }}
                                                                                >
                                                                                    <div style={{
                                                                                        color: 'white',
                                                                                        fontSize: 16,
                                                                                        fontFamily: 'Inter',
                                                                                        fontWeight: '600',
                                                                                        wordWrap: 'break-word'
                                                                                    }}>Malware:
                                                                                    </div>
                                                                                    <div style={{
                                                                                        color: 'white',
                                                                                        fontSize: 14,
                                                                                        fontFamily: 'Inter',
                                                                                        fontWeight: '400',
                                                                                        wordWrap: 'break-word'
                                                                                    }}>{item["Credentials"][0]["Malware"]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                width: "100%",
                                                                                height: 1,
                                                                                borderBottom: '1px #A1A1AA dashed'
                                                                            }}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{
                                            height: "100%",
                                            width: "50%",
                                            borderRadius: 4,
                                            background: '#18181B',
                                            padding: 16,
                                            gap: 16,
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                        >
                                            <div style={{
                                                color: '#F9B233',
                                                fontSize: 16,
                                                fontFamily: 'Inter',
                                                fontWeight: '600'
                                            }}>Questionnaire
                                            </div>
                                            <div
                                                className="grc-scroll-bar-div"
                                                style={{
                                                    background: "#18181B",
                                                    paddingRight: 8,
                                                    borderRadius: 4,
                                                    width: '100%',
                                                    height: "972px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    overflow: "auto"
                                                }}
                                            >
                                                <div style={{
                                                    background: "#3F3F46",
                                                    height: 40,
                                                    borderTopRightRadius: 4,
                                                    borderTopLeftRadius: 4,
                                                    justifyContent: 'flex-start',
                                                    fontFamily: 'Inter',
                                                    alignItems: 'center',
                                                    padding: 8,
                                                    paddingLeft: 12,
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>{this.state.focused_section}
                                                    </div>
                                                    -
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>{this.state.focused_answer.sections[this.state.focused_section].name}
                                                    </div>
                                                </div>

                                                {
                                                    Object.entries(this.state.focused_answer.sections[this.state.focused_section].questions).sort(template_sorter).map(([key, value]) =>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <div style={{
                                                                background: "#27272A",
                                                                minHeight: 40,
                                                                justifyContent: 'flex-start',
                                                                fontFamily: 'Inter',
                                                                alignItems: 'center',
                                                                borderLeft: '2px #3F3F46 solid',
                                                                borderRight: '2px #3F3F46 solid',
                                                                padding: 8,
                                                                paddingLeft: 12,
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>{key}
                                                                </div>
                                                                -
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>{value.name}
                                                                </div>
                                                                {
                                                                    value.type !== "Single Select" ?
                                                                        <div style={{
                                                                            marginLeft: "auto",
                                                                            color: 'black',
                                                                            padding: "0 8px",
                                                                            borderRadius: 4,
                                                                            fontSize: 12,
                                                                            fontWeight: '400',
                                                                            background: 'white',
                                                                            whiteSpace: 'nowrap'
                                                                        }}>{value.type}
                                                                        </div> : ""
                                                                }
                                                            </div>
                                                            <div style={{
                                                                borderLeft: '2px #3F3F46 solid',
                                                                borderRight: '2px #3F3F46 solid',
                                                                background: "#18181B",
                                                                display: "flex",
                                                                fontSize: 16,
                                                                flexDirection: "column",
                                                                gap: 8,
                                                                padding: "8px 16px"
                                                            }}
                                                            >
                                                                {
                                                                    value.type === "Single Select" || value.type === "Multi Select" ?
                                                                        Object.entries(value.answers).sort(template_sorter).map(([a_key, a_value]) =>
                                                                            <div style={{
                                                                                background: a_value.selected ? "rgba(6,182,212,0.25)" : "transparent",
                                                                                border: a_value.selected ? '1px #06B6D4 solid' : '1px #3F3F46 solid',
                                                                                minHeight: 28,
                                                                                paddingLeft: 8,
                                                                                borderRadius: 4,
                                                                                cursor: "pointer"
                                                                            }}
                                                                                 onClick={() => {
                                                                                     let m_value = this.state.focused_answer
                                                                                     Object.entries(m_value.sections[this.state.focused_section].questions[key].answers).forEach(([a_key, a_value]) => {
                                                                                         if (a_value.selected === undefined || value.type === "Single Select") a_value.selected = false
                                                                                     })
                                                                                     m_value.sections[this.state.focused_section].questions[key].answers[a_key].selected = value.type === "Single Select" ?
                                                                                         true : !m_value.sections[this.state.focused_section].questions[key].answers[a_key].selected
                                                                                     this.setState({focused_answer: m_value})
                                                                                 }}
                                                                            >
                                                                                {a_value.name}
                                                                            </div>
                                                                        ) : value.type === "Free Text" ?
                                                                            <textarea className="transparent-input"
                                                                                      name='answer_value'
                                                                                      value={value.answer ? value.answer : ""}
                                                                                      style={{
                                                                                          border: '1px #3F3F46 solid',
                                                                                          backgroundColor: "#18181B",
                                                                                          minHeight: 28,
                                                                                          height: 28,
                                                                                          paddingLeft: 8,
                                                                                          borderRadius: 4
                                                                                      }}
                                                                                      onChange={(data) => {
                                                                                          let m_value = this.state.focused_answer
                                                                                          m_value.sections[this.state.focused_section].questions[key].answer = data.target.value
                                                                                          this.setState({focused_answer: m_value})
                                                                                      }}
                                                                            />
                                                                            :
                                                                            ""
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                <div style={{height: 2, background: "#3F3F46"}}/>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }
}