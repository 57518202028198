import React, { Component } from 'react'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip as MuiTooltip, Tooltip as MUITooltip} from "@mui/material";
import { ReactComponent as IPatch } from '../icons/eti_management/patch.svg';
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { ReactComponent as IRefresh } from '../icons/eti_management/refresh.svg'
import { ReactComponent as IRefreshDynamic } from '../icons/eti_management/refresh-dynamic.svg'
import { ReactComponent as IUp_right_arrow } from '../icons/eti_management/up_right_arrow.svg';
import { ReactComponent as IBoxChecked } from '../icons/fda/box-checked-i.svg';
import { ReactComponent as IBoxEmpty } from '../icons/fda/box-unchecked-i.svg';
import { ReactComponent as IBoxIndeterminate } from '../icons/fda/box-indeterminate.svg';
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import { Doughnut } from "react-chartjs-2";
import Switch from "react-switch";
import swipe from "bootstrap/js/src/util/swipe";
import MDEditor from "@uiw/react-md-editor";

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function isObject(object) {
    return object != null && typeof object === 'object';
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function get_time_string(time, table) {
    if (!isNaN(time)) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function get_user_status_text(flag) {
    switch (flag) {
        case 0:
            return "Logged on (User logged on to Device)"
        case 1:
            return "Connected (Device connected to client)"
        case 2:
            return "Connecting (In the process of connecting to client)"
        case 3:
            return "Shadowing (Shadowing another Device)"
        case 4:
            return "Disconnected (Device logged on without client)"
        case 5:
            return "Idle (Waiting for client to connect)"
        case 6:
            return "Listen (Device is listening for connection)"
        case 7:
            return "Reset (Device is being reset)"
        case 8:
            return "Down (Device is down due to error)"
        case 9:
            return "Init (Device in initialization)"
        default:
            return "Unknown state"
    }
}

function get_reboot_required(data) {
    if ("updates" in data) {
        for (const key of Object.keys(data["updates"])) {
            if (data["updates"][key]["reboot_required"]) {
                return true
            }
        }
    }
    return false
}

function get_patch_severity(data) {
    let severity = 5
    if ("updates" in data) {
        if (data["updates"]["msg"] && data["updates"]["msg"].toLowerCase().startsWith("error")) {
            severity = 6
        } else {
            Object.keys(data["updates"]).forEach(function (key, index) {
                if (isObject(data["updates"][key]) && "severity" in data["updates"][key]) {
                    if (data["updates"][key]["severity"] < severity) {
                        severity = data["updates"][key]["severity"]
                    }
                }
            }, data["updates"]);
        }
    } else {
        severity = 7
    }
    return severity;
}

function get_patch_severity_chip(severity) {
    let color = "rgba(251,113,133,1)"
    let color_back = "rgba(251,113,133,0.25)"
    let text = "Critical"
    switch (severity) {
        case 1:
            color = "rgba(249,115,22,1)"
            color_back = "rgba(249,115,22,0.25)"
            text = "Important"
            break
        case 2:
            color = "rgba(234,179,8,1)"
            color_back = "rgba(234,179,8,0.25)"
            text = "Moderate"
            break
        case 3:
            color = "rgba(6,182,212,1)"
            color_back = "rgba(6,182,212,0.25)"
            text = "Low"
            break
        case 4:
            color = "rgba(161,218,248,1)"
            color_back = "rgba(161,218,248,0.25)"
            text = "Undefined"
            break
    }
    return (
        <div
            style={{
                borderRadius: "16px",
                backgroundColor: color_back,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 8px",
                color: color
            }}
        >
            <div
                style={{
                    position: "relative",
                    fontWeight: "600"
                }}
            >
                {text}
            </div>
        </div>
    )
}

function get_patch_severity_render(data, card) {
    let reboot_required = get_reboot_required(data)
    switch (get_patch_severity(data)) {
        case 0:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/critical-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Critical
            </div>))
        case 1:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/important-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Important
            </div>))
        case 2:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/moderate-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Moderate
            </div>))
        case 3:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/low-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Low
            </div>))
        case 4:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 22,
                    height: card ? 20 : 22,
                }}/> : ""}
                <img src="/main/undefined-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Undefined
            </div>))
        case 5:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/updated-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Patched
            </div>))
        case 6:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/critical-i.svg" width={card?20:24} alt="patch-icon"/>
                {data["updates"]["msg"]}
            </div>))
        case 7:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/critical-i.svg" width={card?20:24} alt="patch-icon"/>
                N/A
            </div>))
    }
}

function safe_index(data, key1, key2, key3) {
    if (key3) {
        if (key1 in data && key2 in data[key1] && key3 in data[key1][key2] && data[key1][key2][key3] !== undefined && data[key1][key2][key3] !== null) {
            return data[key1][key2][key3]
        }
    } else if (key2) {
        if (key1 in data && key2 in data[key1] && data[key1][key2] !== undefined && data[key1][key2] !== null) {
            return data[key1][key2]
        }
    } else if (key1 in data && data[key1] !== undefined && data[key1] !== null) {
        return data[key1]
    }
    return "N/A"
}

function check_server(data) {
    return "sys_info" in data && "full_name" in data["sys_info"]
        && data["sys_info"]["full_name"].toLowerCase().includes("server")
}

function check_kfire(data) {
    return "sys_info" in data && "firewall" in data["sys_info"]
}

export default class ETIManagement extends Component {
    wait_backend = false;
    skip_refresh = 0;
    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });

    columns = [
        {
            accessorKey: 'host',
            header: 'Hostname',
            size: 150,
            Cell: ({ cell }) => (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px"
                }}>
                    <div style={{
                        fontSize: 16
                    }}>
                        {cell.row.original["host"]}
                    </div>

                    <div style={{
                        fontSize: 14,
                        color: "#A3A3A3"
                    }}>
                        {
                            check_kfire(cell.row.original) ?
                                cell.row.original["sys_info"]["firewall"]
                                :
                                this.getLastUser(cell.row.original["users"])
                        }
                    </div>
                </div>
            ),
        },
        {
            accessorKey: 'ip',
            header: 'IP Address',
            size: 150,
        },
        {
            accessorKey: 'sys_info.full_name',
            header: 'Type / OS',
            size: 150,
            Cell: ({cell}) => (
                <div>
                    {this.getOSRender(cell.row.original)}
                </div>
            ),
        },
        {
            accessorKey: 'version',
            header: 'Status',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {this.getStatusRender(cell.row.original)}
                </div>
            ),
        },
        {
            accessorKey: 'authority',
            header: 'Authority to Isolate',
            size: 150,
            Cell: ({cell}) => (
                <div style={{
                    display: "flex",
                    gap: "8px"
                }}>
                    {
                        check_kfire(cell.row.original) ?
                            (<div style={{display: "flex", gap: 8, alignItems: "center"}}>
                                <img src="/main/double-cross-i.svg" alt="flag"/>N/A
                            </div>)
                            :
                            check_server(cell.row.original) ?
                                this.state.server_flags[1] ?
                                    (<div style={{display: "flex", gap: 8, alignItems: "center"}}>
                                        <img src="/main/double-check-i.svg" alt="flag"/>Yes
                                    </div>)
                                    :
                                    (<div style={{display: "flex", gap: 8, alignItems: "center"}}>
                                        <img src="/main/double-cross-i.svg" alt="flag"/>No
                                    </div>)
                                :
                                this.state.workstation_flags[1] ?
                                    (<div style={{display: "flex", gap: 8, alignItems: "center"}}>
                                        <img src="/main/double-check-i.svg" alt="flag"/>Yes
                                    </div>)
                                    :
                                    (<div style={{display: "flex", gap: 8, alignItems: "center"}}>
                                        <img src="/main/double-cross-i.svg" alt="flag"/>No
                                    </div>)
                    }
                </div>
            ),
        },
        {
            accessorKey: 'patch_status',
            header: 'Patch Status',
            size: 150,
            Cell: ({cell}) => (
                <div style={{
                    display: "flex",
                    gap: "8px"
                }}>
                    {
                        get_patch_severity_render(cell.row.original, false)
                    }
                </div>
            ),
        },
        {
            accessorKey: 'first_seen',
            header: 'First Seen',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        },
        {
            accessorKey: 'last_seen',
            header: 'Last Seen',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        },
    ];

    constructor(props) {
        super(props);
        let data = this.props.page_data()
        this.getDayLabel = this.getDayLabel.bind(this)
        this.get_stats = this.get_stats.bind(this)
        this.getStatusRender = this.getStatusRender.bind(this)
        this.isolation_route = this.isolation_route.bind(this)
        this.OpenModal = this.OpenModal.bind(this)
        this.getOSRender = this.getOSRender.bind(this)
        this.getIcon = this.getIcon.bind(this)
        this.getLastUser = this.getLastUser.bind(this)
        this.close_hic = this.close_hic.bind(this)
        this.refresh_table = this.refresh_table.bind(this)
        this.update_table = this.update_table.bind(this)
        this.getTableData = this.getTableData.bind(this)
        this.export_patch_csv = this.export_patch_csv.bind(this)
        this.getPlaybookFlags = this.getPlaybookFlags.bind(this)
        this.check_update_filters = this.check_update_filters.bind(this)
        this.update_patch_entries = this.update_patch_entries.bind(this)
        this.get_patch_statistics = this.get_patch_statistics.bind(this)
        this.query_device_updates = this.query_device_updates.bind(this)
        this.send_patch_command = this.send_patch_command.bind(this)
        this.has_pushable_update = this.has_pushable_update.bind(this)
        this.send_reboot_command = this.send_reboot_command.bind(this)
        this.export_devices_csv = this.export_devices_csv.bind(this)
        this.query_device_update_commands = this.query_device_update_commands.bind(this)
        this.state = {username: data.username, token: data.token, email: data.email, company: data.company, key: data.keys[data.company], keys: data.keys,
            table_refresh: 0,
            showHIC: false,
            showPU: false,
            showRH: false,
            showPatch: false,
            hic_state: false,
            invalid_otp: false,
            show_device_card: false,
            command_update_states: {},
            selected_updates: {},
            hic_data: {},
            hic_otp: "",
            minute_ago: 0,
            month_ago: 0,
            filter_index: 0,
            filter_patch_index: 0,
            filter_patch_text: "",
            filer_patch_entries: [],
            filer_patch_stats: [0,0,0,0,0,0],
            table_data: [],
            table_cache: [],
            cache_updated: false,
            active_table: [],
            workstations: [],
            kryptofire: [],
            device_data: {},
            workstation_flags: [false, false],
            server_flags: [false, false],
            patch_stats: {
                workstations: {
                    labels:[],
                    colors: [],
                    stats:[]
                },
                servers: {
                    labels:[],
                    colors: [],
                    stats:[]
                }
            },
            workstation_stats: {
                online: 0,
                offline: 0,
                isolated: 0,
                stale: 0
            },
            server_stats: {
                online: 0,
                offline: 0,
                isolated: 0,
                stale: 0
            },
            kfire_stats: {
                online: 0,
                offline: 0,
                stale: 0
            },
            servers: [],
            online: [],
            limit: 0,
            pulse: 0
        };
    }

    has_pushable_update() {
        if (typeof this.state.device_data["updates"] == "object") {
            for (const key of Object.keys(this.state.device_data["updates"])) {
                if (typeof this.state.device_data["updates"][key] == "object") {
                    let update = this.state.device_data["updates"][key]
                    if (!update["reboot_required"] && !(update["id"] in this.state.command_update_states)) {
                        return true
                    }
                }
            }
        }
        return false
    }

    query_device_update_commands() {
        let jsonData = {
            "key": this.state.key,
            "host": this.state.device_data["key"],
            "type": 0,
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/query_active_commands", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json["commands"]) {
                        let command_states = {}
                        for (const command of json["commands"]) {
                            if ("update" in command) {
                                command_states[command["update_id"]] = command["update"]
                            }
                        }
                        this.setState({command_update_states: command_states})
                    }
                });
            }
        }).catch((err) => {
            console.log("query_active_commands", err)
        })
    }

    query_device_updates() {
        let jsonData = {
            "key": this.state.key,
            "host": this.state.device_data["key"],
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/query_device_updates", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json["updates"]) {
                        let device_data = this.state.device_data
                        device_data["updates"] = json["updates"]
                        if (Object.keys(this.state.device_data["updates"]).length !== Object.keys(device_data["updates"]).length) {
                            this.setState({device_data: device_data})
                            this.setState({filter_patch_index: 0, selected_updates: {}})
                            this.update_patch_entries(0, this.state.filter_patch_text, device_data)
                        }
                    }
                });
            }
        }).catch((err) => {
            console.log("query_device_updates", err)
        })
        this.query_device_update_commands()
    }

    send_reboot_command() {
        let data = []

        let entry = {
            type: 1,
            force: !!this.state.hic_data["force"]
        }

        if (this.state.hic_data["msg"]) {
            entry["msg"] = this.state.hic_data["msg"];
        }

        if (this.state.hic_data["delay"]) {
            entry["delay"] = this.state.hic_data["delay"];
        }

        data.push(entry)


        let jsonData = {
            "key": this.state.key,
            "email": this.state.email,
            "host": this.state.device_data["key"],
            "data": data,
            "otp": parseInt(this.state.hic_otp)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/send_device_command", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json["success"]) {
                        this.close_hic()
                        this.tick()
                        setTimeout(function () {
                            this.refresh_table()
                        }.bind(this), 800);
                        this.setState({selected_updates: {}})
                    } else {
                        this.setState({invalid_otp: true})
                        setTimeout(function () {
                            this.setState({invalid_otp: false});
                        }.bind(this), 3000);
                    }
                });
            }
        }).catch((err) => {
            console.log("send_reboot_command", err)
        })
    }

    send_patch_command() {
        let data = []

        if (Object.keys(this.state.selected_updates).length > 0) {
            for (const key of Object.keys(this.state.selected_updates)) {
                let entry = {
                    type: 0,
                    update_id: key
                }
                data.push(entry)
            }
        } else {
            for (const key of Object.keys(this.state.device_data["updates"])) {
                if (typeof this.state.device_data["updates"][key] === 'object' &&
                    this.state.device_data["updates"][key]["id"] &&
                    !(this.state.device_data["updates"][key]["id"] in this.state.command_update_states) &&
                    !this.state.device_data["updates"][key]["reboot_required"]) {
                    let entry = {
                        type: 0,
                        update_id: this.state.device_data["updates"][key]["id"]
                    }
                    data.push(entry)
                }
            }
        }

        let jsonData = {
            "key": this.state.key,
            "email": this.state.email,
            "host": this.state.device_data["key"],
            "data": data,
            "otp": parseInt(this.state.hic_otp)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/send_device_command", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json["success"]) {
                        this.close_hic()
                        this.tick()
                        setTimeout(function () {
                            this.refresh_table()
                        }.bind(this), 800);
                        this.setState({selected_updates: {}})
                    } else {
                        this.setState({invalid_otp: true})
                        setTimeout(function () {
                            this.setState({invalid_otp: false});
                        }.bind(this), 3000);
                    }
                });
            }
        }).catch((err) => {
            console.log("send_device_command", err)
        })
    }

    isolation_route() {
        let jsonData = {
            "token": this.state.token,
            "company": this.state.company,
            "host": this.state.hic_data["key"],
            "isolate": this.state.hic_state,
            "otp": parseInt(this.state.hic_otp)
        }
        this.props.post_request("isolation", jsonData).then((response) => {
            if (response) {
                if (response["success"]) {
                    this.close_hic()
                    this.tick()
                    setTimeout(function () {
                        this.refresh_table()
                    }.bind(this), 800);
                } else {
                    this.setState({invalid_otp: true})
                    setTimeout(function () {
                        this.setState({invalid_otp: false});
                    }.bind(this), 3000);
                }
            } else {
                console.log("failed isolation_route")
            }
        }).catch((err) => {
            console.log("isolation_route", err)
        })
    }

    close_hic() {
        this.setState({hic_data: {}, showHIC: false, showPU: false, showRH: false, hic_otp: ""})
    }

    refresh_table() {
        this.setState({cache_updated: false, active_table: this.state.table_data, minute_ago: Math.floor(new Date().getTime() / 1000.0) - 60, month_ago: Math.floor(new Date().getTime()/1000.0) - (60 * 43800)})
    }

    update_table(e) {
        this.setState({cache_updated: false, filter_index: parseInt(e.currentTarget.id)})
    }

    check_update_filters(index, search, update) {
        let show = true
        switch (index) {
            case 1:
                show = update["severity"] === 0
                break
            case 2:
                show = update["severity"] === 1
                break
            case 3:
                show = update["severity"] === 2
                break
            case 4:
                show = update["severity"] === 3
                break
            case 5:
                show = update["severity"] === 4
                break
        }
        return show
    }


    get_patch_statistics() {
        let patch_stats = {
                workstations: {
                    labels: [],
                    colors: [],
                    data: [],
                    stats: [0,0,0,0,0,0,0],
                    percent_patched: 0
                },
                servers: {
                    labels: [],
                    colors: [],
                    data: [],
                    stats: [0,0,0,0,0,0,0],
                    percent_patched: 0
                }
        }

        let patched_server_sum = 0
        let patched_workstation_sum = 0
        let workstation_sum = 0;
        let server_sum = 0;

        this.state.table_data.forEach(function(part, index) {
            if (!check_kfire(this.state.table_data[index]) && this.state.table_data[index]["last_seen"] > this.state.month_ago) {
                let isServer = check_server(this.state.table_data[index])
                switch (get_patch_severity(this.state.table_data[index])) {
                    case 0:
                        isServer ? patch_stats.servers.stats[2]++ : patch_stats.workstations.stats[2]++;
                        break
                    case 1:
                        isServer ? patch_stats.servers.stats[3]++ : patch_stats.workstations.stats[3]++;
                        break
                    case 2:
                        isServer ? patch_stats.servers.stats[4]++ : patch_stats.workstations.stats[4]++;
                        break
                    case 3:
                        isServer ? patch_stats.servers.stats[5]++ : patch_stats.workstations.stats[5]++;
                        break
                    case 4:
                        isServer ? patch_stats.servers.stats[6]++ : patch_stats.workstations.stats[6]++;
                        break
                    case 5:
                        isServer ? patch_stats.servers.stats[0]++ : patch_stats.workstations.stats[0]++;
                        break
                    case 6:
                        isServer ? patch_stats.servers.stats[1]++ : patch_stats.workstations.stats[1]++;
                        break
                }
                isServer ? server_sum++ : workstation_sum++
            }
        }, this);

        patch_stats.workstations.stats.forEach(function(count, index) {
            if (count === 0) return
            switch (index) {
                case 0:
                    if (!("Patched" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Patched")
                        patch_stats.workstations.colors.push("#10B981")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Patched")] = patch_stats.workstations.stats[0]
                        patched_workstation_sum += patch_stats.workstations.stats[0]
                    }
                    break
                case 1:
                    if (!("Error" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Error")
                        patch_stats.workstations.colors.push("#8B5CF6")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Error")] = patch_stats.workstations.stats[1]
                    }
                    break
                case 2:
                    if (!("Critical" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Critical")
                        patch_stats.workstations.colors.push("#F43F5E")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Critical")] = patch_stats.workstations.stats[2]
                    }
                    break
                case 3:
                    if (!("Important" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Important")
                        patch_stats.workstations.colors.push("#F97316")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Important")] = patch_stats.workstations.stats[3]
                    }
                    break
                case 4:
                    if (!("Moderate" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Moderate")
                        patch_stats.workstations.colors.push("#EAB308")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Moderate")] = patch_stats.workstations.stats[4]
                    }
                    break
                case 5:
                    if (!("Low" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Low")
                        patch_stats.workstations.colors.push("#06B6D4")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Low")] = patch_stats.workstations.stats[5]
                        patched_workstation_sum += patch_stats.workstations.stats[5]
                    }
                    break
                case 6:
                    if (!("Undefined" in patch_stats.workstations.labels)) {
                        patch_stats.workstations.labels.push("Undefined")
                        patch_stats.workstations.colors.push("#A1DAF8")
                        patch_stats.workstations.data[patch_stats.workstations.labels.findIndex(x => x === "Undefined")] = patch_stats.workstations.stats[6]
                    }
                    break
            }
        }, this);

        patch_stats.servers.stats.forEach(function(count, index) {
            if (count === 0) return
            switch (index) {
                case 0:
                    if (!("Patched" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Patched")
                        patch_stats.servers.colors.push("#10B981")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Patched")] = patch_stats.servers.stats[0]
                        patched_server_sum += patch_stats.servers.stats[0]
                    }
                    break
                case 1:
                    if (!("Error" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Error")
                        patch_stats.servers.colors.push("#8B5CF6")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Error")] = patch_stats.servers.stats[1]
                    }
                    break
                case 2:
                    if (!("Critical" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Critical")
                        patch_stats.servers.colors.push("#F43F5E")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Critical")] = patch_stats.servers.stats[2]
                    }
                    break
                case 3:
                    if (!("Important" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Important")
                        patch_stats.servers.colors.push("#F97316")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Important")] = patch_stats.servers.stats[3]
                    }
                    break
                case 4:
                    if (!("Moderate" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Moderate")
                        patch_stats.servers.colors.push("#EAB308")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Moderate")] = patch_stats.servers.stats[4]
                    }
                    break
                case 5:
                    if (!("Low" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Low")
                        patch_stats.servers.colors.push("#06B6D4")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Low")] = patch_stats.servers.stats[5]
                        patched_server_sum += patch_stats.servers.stats[5]
                    }
                    break
                case 6:
                    if (!("Undefined" in patch_stats.servers.labels)) {
                        patch_stats.servers.labels.push("Undefined")
                        patch_stats.servers.colors.push("#A1DAF8")
                        patch_stats.servers.data[patch_stats.servers.labels.findIndex(x => x === "Undefined")] = patch_stats.servers.stats[6]
                    }
                    break
            }
        }, this);

        patch_stats.servers.percent_patched = Math.floor((patched_server_sum/server_sum) * 100)
        patch_stats.workstations.percent_patched = Math.floor((patched_workstation_sum/workstation_sum) * 100)

        patch_stats.servers.stats = []
        patch_stats.workstations.stats = []

        patch_stats.servers.data.forEach((value, index) => {
            patch_stats.servers.stats.push(Math.max((value / server_sum) * 100, 2))
        }, this)

        patch_stats.workstations.data.forEach((value, index) => {
            patch_stats.workstations.stats.push(Math.max((value / workstation_sum) * 100, 2))
        }, this)

        this.setState({patch_stats: patch_stats})
    }

    export_devices_csv() {
        let csv = 'Host,IP,Type,OS,OS Build,Agent Version,Patch Status,First Seen,Last Seen\n';

        this.state.table_cache.forEach(function(entry) {
            let type = check_kfire(entry) ? "ShadowFire" : check_server(entry) ? "Server" : "Workstation"
            let row = '"' + entry["host"] + '",'
            let IP = entry["ip"] ? entry["ip"] : ""
            row = row.concat(
                '"', IP.replaceAll('"', '""'), '",',
                '"', type, '",',
                '"', safe_index(entry, "sys_info", "full_name"), '",'
            )

            row = row.concat('"',
                safe_index(entry, "sys_info", "major"),
                '.',
                safe_index(entry, "sys_info", "minor"),
                '.',
                safe_index(entry, "sys_info", "patch"),
                '.',
                safe_index(entry, "sys_info", "build"),
                '",')

            row = row.concat(
                '"', entry["version"], '",',
                '"', entry["patch_status"], '",',
                '"', get_time_string(entry["first_seen"]), '",',
                '"', get_time_string(entry["last_seen"]), '",'
            )

            csv += row + "\n"
        }, this);

        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = this.state.company.concat("_Endpoints.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    export_patch_csv() {
        //define the heading for each row of the data
        let csv = 'Device,OS,OS Build,Title,Tags,Severity,Discovered\n';

        //merge the data with CSV
        this.state.filer_patch_entries.forEach(function(entry) {
            let row = '"' + this.state.device_data["host"] + '",'
            row = row.concat('"', safe_index(this.state.device_data, "sys_info", "full_name"), '",')

            row = row.concat('"',
                safe_index(this.state.device_data, "sys_info", "major"),
                '.',
                safe_index(this.state.device_data, "sys_info", "minor"),
                '.',
                safe_index(this.state.device_data, "sys_info", "patch"),
                '.',
                safe_index(this.state.device_data, "sys_info", "build"),
                '",')
            row = row.concat('"', entry["title"], '",')
            row = row.concat('"')
            entry["tags"].forEach(function(tag) {
                row = row.concat(tag, ',')
            })
            row = row.slice(0,-1).concat('",')

            switch (entry["severity"]) {
                case 0:
                    row = row.concat("Critical,")
                    break
                case 1:
                    row = row.concat("Important,")
                    break
                case 2:
                    row = row.concat("Moderate,")
                    break
                case 3:
                    row = row.concat("Low,")
                    break
                case 4:
                    row = row.concat("Undefined,")
                    break
            }
            row = row.concat('"', get_time_string(entry["first_seen"]), '"')
            csv += row.concat(',')
            csv += "\n"
        }, this);

        let filterText = "All"
        switch (this.state.filter_patch_index) {
            case 1:
                filterText = "Critical"
                break
            case 2:
                filterText = "Important"
                break
            case 3:
                filterText = "Moderate"
                break
            case 4:
                filterText = "Low"
                break
            case 5:
                filterText = "Undefined"
                break
        }

        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = this.state.device_data["host"].concat("_CTI_Patch_Status_", filterText,".csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    update_patch_entries(index, search, device_data) {
        let device_data_obj = this.state.device_data
        if (device_data) {
            device_data_obj = device_data
        }

        let stats = [0,0,0,0,0,0]
        let entries = []

        Object.keys(device_data_obj["updates"]).forEach(function (update, i){
            if (isObject(device_data_obj["updates"][update])) {
                let show = this.check_update_filters(index, search, device_data_obj["updates"][update])
                let entry = device_data_obj["updates"][update]

                entry["title"] = update


                if (update !== "" && !update.toLowerCase().includes(search.toLowerCase())) {
                    show = false
                } else {
                    stats[0]++
                    let idx = entry["severity"] + 1
                    if (idx < 6) {
                        stats[idx]++
                    }
                }

                if (show) {
                    entries.push(entry)
                }
            }
        }, this)

        this.setState({filer_patch_entries: entries, filer_patch_stats: stats})
    }

    getTableData() {
        if (!this.state.cache_updated) {
            let active_table = this.state.active_table
            active_table.forEach(function(part, index) {
                switch (get_patch_severity(this[index])) {
                    case 0:
                        this[index]["patch_status"] = "Critical";
                        break
                    case 1:
                        this[index]["patch_status"] = "Important";
                        break
                    case 2:
                        this[index]["patch_status"] = "Moderate";
                        break
                    case 3:
                        this[index]["patch_status"] = "Low";
                        break
                    case 4:
                        this[index]["patch_status"] = "Undefined";
                        break
                    case 5:
                        this[index]["patch_status"] = "Patched";
                        break
                    case 6:
                        this[index]["patch_status"] = this[index]["updates"]["msg"];
                        break
                    case 7:
                        this[index]["patch_status"] = "N/A";
                        break
                }
            }, active_table);
            switch (this.state.filter_index) {
                case 1:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] > this.state.minute_ago)})
                    break
                case 2:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] < this.state.minute_ago && entry["last_seen"] > this.state.month_ago)})
                    break
                case 3:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] <= this.state.month_ago)})
                    break
                case 4:
                    this.setState({table_cache:active_table.filter((entry) => entry["isolated"])})
                    break
                case 10:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] > this.state.minute_ago && !check_kfire(entry) && check_server(entry))})
                    break
                case 11:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] > this.state.minute_ago && !check_kfire(entry) && !check_server(entry))})
                    break
                case 12:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] > this.state.minute_ago && check_kfire(entry))})
                    break
                case 13:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] < this.state.minute_ago && entry["last_seen"] > this.state.month_ago && !check_kfire(entry) && check_server(entry))})
                    break
                case 14:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] < this.state.minute_ago && entry["last_seen"] > this.state.month_ago && !check_kfire(entry) && !check_server(entry))})
                    break
                case 15:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] < this.state.minute_ago && entry["last_seen"] > this.state.month_ago && check_kfire(entry))})
                    break
                case 16:
                    this.setState({table_cache:active_table.filter((entry) => entry["isolated"] && !check_kfire(entry) && check_server(entry))})
                    break
                case 17:
                    this.setState({table_cache:active_table.filter((entry) => entry["isolated"] && !check_kfire(entry) && !check_server(entry))})
                    break
                case 18:
                    this.setState({table_cache:active_table.filter((entry) => entry["isolated"] && check_kfire(entry))})
                    break
                case 19:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] <= this.state.month_ago && !check_kfire(entry) && check_server(entry))})
                    break
                case 20:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] <= this.state.month_ago && !check_kfire(entry) && !check_server(entry))})
                    break
                case 21:
                    this.setState({table_cache: active_table.filter((entry) => entry["last_seen"] <= this.state.month_ago && check_kfire(entry))})
                    break
                default:
                    this.setState({table_cache: active_table})
            }
            this.setState({cache_updated: true})
        }
        return this.state.table_cache
    }

    OpenModal (data) {
        this.setState({device_data: data, show_device_card: true})
    }

    getLastUser(users) {
        if (!users) return ""
        if (users && users.length === 0) return "No User"
        let latest = users[0]
        let active_users = []

        users.forEach((user) => {
            if (user["status"] < 7) {
                active_users.push(user)
            }
        })

        if (active_users.length !== 0) {
            latest = active_users[0]
            active_users.forEach((user) => {
                if (user["last_seen"] > latest["last_seen"]) {
                    latest = user
                }
            })
        } else {
            users.forEach((user) => {
                if (user["last_seen"] > latest["last_seen"]) {
                    latest = user
                }
            })
        }
        return latest["domain"] + "\\" + latest["user"]
    }

    get_stats(devices) {
        let minute_ago = Math.floor(new Date().getTime()/1000.0) - 60
        let month_ago = Math.floor(new Date().getTime()/1000.0) - (60 * 43800)

        let stats = {
            workstation_stats: {
                online: 0,
                offline: 0,
                isolated: 0,
                stale: 0
            },
            server_stats: {
                online: 0,
                offline: 0,
                isolated: 0,
                stale: 0
            },
            kfire_stats: {
                online: 0,
                offline: 0,
                stale: 0
            }
        }

        for (let idx in devices) {
            let device = devices[idx]
            if (check_kfire(device)) {
                if (device["last_seen"] > minute_ago) {
                    stats.kfire_stats.online++
                } else if (device["last_seen"] <= month_ago) {
                    stats.kfire_stats.stale++
                } else {
                    stats.kfire_stats.offline++
                }
            } else if (check_server(device)) {
                if (device["last_seen"] > minute_ago) {
                    stats.server_stats.online++
                } else if (device["last_seen"] <= month_ago) {
                    stats.server_stats.stale++
                } else {
                    stats.server_stats.offline++
                }
                if (device["isolated"]) {
                    stats.server_stats.isolated++
                }
            } else {
                if (device["last_seen"] > minute_ago) {
                    stats.workstation_stats.online++
                } else if (device["last_seen"] <= month_ago) {
                    stats.workstation_stats.stale++
                } else {
                    stats.workstation_stats.offline++
                }
                if (device["isolated"]) {
                    stats.workstation_stats.isolated++
                }
            }
        }

        return stats
    }

    getIcon(status, card) {
        let w = card ? 20 : 28
        let h = card ? 20 : 28
        switch (status) {
            case "Online":
                return (
                    <img src="/main/mgt-online-i.svg" alt="online-i" width={w} height={h}/>
                )
            case "Offline":
                return (
                    <img src="/main/mgt-offline-i.svg" alt="offline-i" width={w} height={h}/>
                )
            case "Stale":
                return (
                    <img src="/main/mgt-stale-i.svg" alt="stale-i" width={w} height={h}/>
                )
            case "Isolated":
                return (
                    <img src="/main/mgt-isolated-i.svg" alt="isolated-i" width={w} height={h}/>
                )
        }
        return (<div />)
    }

    getStatusRender(device, card) {
        let state = "Offline"
        if (device["last_seen"] > this.state.minute_ago) {
            state = "Online"
        } else if (device["last_seen"] <= this.state.month_ago) {
            state = "Stale"
        }
        if (device["isolated"]) {
            state = "Isolated"
        }
        return (

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px"
            }}>
                <div style={{display: "flex", justifyContent: "center", gap: "8px"}}>
                    {this.getIcon(state, card)}
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "2px"
                }}>
                    <div>{state}</div>
                    <div hidden={card} style={{
                        fontSize: 14,
                        color: "#A3A3A3"
                    }}>
                        {
                            check_kfire(device) ?
                                "SFire "
                                :
                                "ETI "
                        }
                        v{device["version"]}
                    </div>
                </div>
            </div>
        )
    }

    getOSRender(device) {
        let is_kfire = check_kfire(device);
        let is_server = check_server(device);
        let name = ""
        if ("full_name" in device["sys_info"]) {
            name = device["sys_info"]["full_name"]
        }
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px"
            }}>
                <div style={{
                    fontSize: 16
                }}>
                    {
                        is_kfire ? "ShadowFire":
                            is_server ? "Server" : "Workstation"
                    }
                </div>

                <div style={{
                    fontSize: 14,
                    color: "#A3A3A3"
                }}>
                    {name}
                </div>
            </div>
        )
    }

    getPlaybookFlags() { //load flags into management from playbook on company change
        let page_data = this.props.page_data()
        if (page_data["cp_data"]["URN"]) {
            let cp_data = page_data["cp_data"]
            let workstation_flags = [
                safe_index(cp_data, "IRDeclaration", "Endpoints").includes("Authority to Act"),
                safe_index(cp_data, "IRDeclaration", "Endpoints").includes("Authority to Isolate")
            ]
            let server_flags = [
                safe_index(cp_data, "IRDeclaration", "Servers").includes("Authority to Act"),
                safe_index(cp_data, "IRDeclaration", "Servers").includes("Authority to Isolate")
            ]
            this.setState({workstation_flags: workstation_flags, server_flags: server_flags})
        }
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let token = data.token
        let company = data.company
        if (this.state.company !== data.company || this.state.active_table.length === 0) {
            this.getPlaybookFlags()
            this.setState({table_refresh: 2, username: data.username, key: key, token: token, company: company, keys: data.keys, active_table: []})
        }
        if (token !== '') {
            let jsonData = {
                "token": token,
                "company": company
            }
            if (this.wait_backend) {
                return
            }
            this.wait_backend = true

            this.props.post_request("management", jsonData).then((response) => {
                if (response) {
                    if (this.state.active_table.length === 0) {
                        this.setState({active_table: response["devices"], minute_ago: Math.floor(new Date().getTime() / 1000.0) - 60, month_ago: Math.floor(new Date().getTime()/1000.0) - (60 * 43800)})
                    }
                    this.setState({limit: response["limit"], pulse: response["pulse"], table_data: response["devices"]})
                    this.setState(this.get_stats(response["devices"]));

                    if (this.state.table_refresh > 0) {
                        this.refresh_table()
                        this.setState({table_refresh: this.state.table_refresh - 1})
                    }
                } else {
                    console.log("failed management")
                }
                this.wait_backend = false
            }).catch((err) => {
                console.log("management", err)
                this.wait_backend = false
            })
            this.get_patch_statistics()
        }

        if (data["device_key"] && data["device_key"] !== "") {
            if (this.skip_refresh === 0) {
                this.refresh_table()
                this.skip_refresh++
            }
            if (this.state.table_cache.length !== 0 && this.skip_refresh === 1) {
                const device = this.state.table_cache.find(obj => obj["key"] === data["device_key"]);
                if (device) {
                    this.OpenModal(device)
                }
                this.props.open_device("")
                this.skip_refresh = 0
            }
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("etimanagement", this.state)
    }

    getDayLabel(idx) {
        if (idx < 0) {
            idx += 7
        }
        return days[idx]
    }

    componentDidMount() {
        let state = this.props.page_state("etimanagement")
        if (state) {
            this.setState(state)
        }
        this.setState({active_table: []})

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                token: data.token,
                company: data.company,
                keys: data.keys
            })
        }

        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );
        this.getPlaybookFlags()
    }

    componentWillUnmount() {
        clearInterval(this.update_status_interval);
        clearInterval(this.interval_update);
    }


    render() {
        return (
            <div
                className="cp-scroll-bar-div"
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >
                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.show_device_card}
                    contentLabel="Device Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "680px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                {
                                    check_kfire(this.state.device_data) ?
                                        (
                                            <div>
                                                {this.state.device_data["host"]} - ShadowFire
                                            </div>
                                        )
                                        :
                                        (
                                            <div>
                                                {this.state.device_data["host"]} - {this.state.device_data["ip"]} - {check_server(this.state.device_data) ? "Server" : "Workstation"}
                                            </div>
                                        )
                                }

                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({device_data: {}, show_device_card: false})}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            width: "100%",
                            height: "150px",
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            padding: 8,
                            display: "flex",
                            flexDirection: "column",
                            background: "#3F3F46",
                            gap: 4,
                        }}
                        >
                            <div style={{
                                width: "100%",
                                height: "70px",
                                position: "relative",
                                color: 'white',
                                fontSize: 22,
                                fontFamily: 'Inter',
                                display: "flex",
                                gap: 8,
                            }}
                            >
                                {
                                    check_kfire(this.state.device_data) ?
                                        <img src="/main/device-kryptofire-i.svg" alt="device-icon"/>
                                        :
                                        check_server(this.state.device_data) ?
                                            <img src="/main/device-server-i.svg" alt="device-icon"/>
                                            :
                                            <img src="/main/device-workstation-i.svg" alt="device-icon"/>
                                }

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '500px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.state.device_data["host"]}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.state.device_data["ip"]}
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            check_kfire(this.state.device_data) ?
                                                this.state.device_data["sys_info"]["firewall"]
                                                :
                                                check_server(this.state.device_data) ? "Server" : "Workstation"
                                        }
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '900px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {safe_index(this.state.device_data, "sys_info", "full_name")}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {""}
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        display: "flex",
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        OS Build&nbsp;
                                        {safe_index(this.state.device_data, "sys_info", "major")}
                                        .
                                        {safe_index(this.state.device_data, "sys_info", "minor")}
                                        .
                                        {safe_index(this.state.device_data, "sys_info", "patch")}
                                        {
                                            safe_index(this.state.device_data, "sys_info", "build") === "N/A" ? "" :
                                                <div>
                                                    .
                                                    {safe_index(this.state.device_data, "sys_info", "build")}
                                                </div>
                                        }
                                    </div>
                                    <div style={{
                                        fontSize: 12,
                                        display: "flex",
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            get_patch_severity_render(this.state.device_data, true)
                                        }
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '400px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.getStatusRender(this.state.device_data, true)}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        Last Seen
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {get_time_string(this.state.device_data["last_seen"])}
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '400px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        Registered
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        First Seen
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {get_time_string(this.state.device_data["first_seen"])}
                                    </div>
                                </div>
                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '220px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            check_kfire(this.state.device_data) ?
                                                "SF Agent"
                                                :
                                                "ETI Agent"
                                        }
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        v{this.state.device_data["version"]}
                                    </div>
                                </div>

                            </div>
                            {
                                check_kfire(this.state.device_data) ?
                                    ""
                                    :
                                    (
                                        <div>
                                            <img src="/main/netflow-dotted-long.svg" width={"100%"} alt="dotted-line"/>
                                            <div style={{
                                                width: "100%",
                                                gap: 8,
                                                display: 'inline-flex',
                                                height: "30px",
                                                alignItems: "center"
                                            }}
                                            >
                                                {check_server(this.state.device_data) ?
                                                    this.state.server_flags[0] ?
                                                        (<img src="/main/double-check-i.svg" alt="flag"/>)
                                                        :
                                                        (<img src="/main/double-cross-i.svg" alt="flag"/>)
                                                    :
                                                    this.state.workstation_flags[0] ?
                                                        (<img src="/main/double-check-i.svg" alt="flag"/>)
                                                        :
                                                        (<img src="/main/double-cross-i.svg" alt="flag"/>)
                                                }
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Authority to Act
                                                </div>

                                                <div style={{
                                                    width: '1px',
                                                    height: '100%',
                                                    background: '#A1A1AA'
                                                }}/>

                                                {check_server(this.state.device_data) ?
                                                    this.state.server_flags[1] ?
                                                        (<img src="/main/double-check-i.svg" alt="flag"/>)
                                                        :
                                                        (<img src="/main/double-cross-i.svg" alt="flag"/>)
                                                    :
                                                    this.state.workstation_flags[1] ?
                                                        (<img src="/main/double-check-i.svg" alt="flag"/>)
                                                        :
                                                        (<img src="/main/double-cross-i.svg" alt="flag"/>)
                                                }
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    Authority to Isolate
                                                </div>

                                                <div style={{
                                                    marginLeft: "auto",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex',
                                                    cursor: 'pointer'
                                                }}
                                                     onClick={() => {
                                                         this.setState({showPatch: true})
                                                         this.update_patch_entries(this.state.filter_patch_index, this.state.filter_patch_text)
                                                         this.update_status_interval = setInterval(
                                                             () => {
                                                                 this.query_device_updates()
                                                             },
                                                             2000
                                                         );
                                                     }}
                                                >
                                                    <div style={{
                                                        height: 32,
                                                        padding: 8,
                                                        background: '#18181B',
                                                        borderRadius: 2,
                                                        overflow: 'hidden',
                                                        border: '1px #A1A1AA solid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <IPatch style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            color: "#A1A1AA",
                                                        }}/>
                                                        <div style={{
                                                            color: '#A1A1AA',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                            wordWrap: 'break-word'
                                                        }}
                                                        >Patch Management
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex',
                                                    cursor: 'pointer'
                                                }}
                                                     onClick={() => this.setState({
                                                         showHIC: true,
                                                         hic_state: !this.state.device_data["isolated"],
                                                         hic_data: this.state.device_data
                                                     })}
                                                >
                                                    <div style={{
                                                        height: 32,
                                                        padding: 8,
                                                        background: '#18181B',
                                                        borderRadius: 2,
                                                        overflow: 'hidden',
                                                        border: '1px #F43F5E solid',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#F43F5E',
                                                            fontSize: 12,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                            wordWrap: 'break-word'
                                                        }}
                                                        >Isolation Control
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }

                        </div>

                        <div className="scroll-bar-div" style={{
                            marginTop: -16,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            padding: 8,
                            paddingLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            background: "#27272A",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: "100%",
                            height: 840
                        }}
                        >
                            {"users" in this.state.device_data ?
                                this.state.device_data["users"].map((user, i) =>
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 4,
                                            gap: 8
                                        }}>
                                            <img src="/main/device-card-user-i.svg" alt="user"/>
                                            <img src="/main/device-card-admin-i.svg" alt="admin"
                                                 style={{
                                                     visibility: user["admin"] ? "visible" : "hidden"
                                                 }}/>

                                            <div style={{
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                width: "780px"
                                            }}>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {user["domain"]}\{user["user"]}
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {get_user_status_text(user["status"])}
                                                </div>
                                                <div style={{
                                                    color: user["device_uuid"] === safe_index(this.state.device_data, "sys_info", "uuid") ? '#A1A1AA' : '#F43F5E',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {
                                                        user["device_uuid"] ?
                                                            <div>
                                                                Device UUID: {user["device_uuid"]}
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>


                                            <div style={{
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>First Seen
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {get_time_string(user["first_seen"])}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Last Seen
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {get_time_string(user["last_seen"])}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                             alt="dotted-line"/>
                                    </div>
                                )
                                :
                                ""
                            }
                        </div>
                        <div style={{
                            color: '#A1A1AA',
                            fontSize: 12,
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            wordWrap: 'break-word'
                        }}>
                            Device UUID: {safe_index(this.state.device_data, "sys_info", "uuid")}
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPatch}
                    contentLabel="Patch Card"
                    style={{
                        overlay: {
                            zIndex: 2000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1440px",
                            height: "880px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                        }}
                        >
                            <div style={{color: "#F9B233"}}>
                                Microsoft Patching Status
                                - {this.state.device_data["host"]} - {this.state.device_data["ip"]} - {check_server(this.state.device_data) ? "Server" : "Workstation"}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => {
                                    this.setState({showPatch: false, filer_patch_entries: [], filer_patch_stats: [0,0,0,0,0,0],
                                        filter_patch_index: 0, filter_patch_text: "", command_update_states: {}, selected_updates: {}})
                                    clearInterval(this.update_status_interval);
                                }}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        <div style={{
                            width: "100%",
                            height: "150px",
                            borderTopRightRadius: 8,
                            borderTopLeftRadius: 8,
                            padding: 8,
                            display: "flex",
                            flexDirection: "column",
                            background: "#3F3F46",
                            gap: 4,
                        }}
                        >
                            <div style={{
                                width: "100%",
                                height: "70px",
                                position: "relative",
                                color: 'white',
                                fontSize: 22,
                                fontFamily: 'Inter',
                                display: "flex",
                                gap: 8,
                            }}
                            >
                                <div style={{
                                    width: 64
                                }}>
                                    <IPatch style={{
                                        width: "64px",
                                        height: "64px",
                                        color: "#A1DAF8",
                                    }}/>
                                </div>


                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '500px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.state.device_data["host"]}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.state.device_data["ip"]}
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            check_server(this.state.device_data) ? "Server" : "Workstation"
                                        }
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '900px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {safe_index(this.state.device_data, "sys_info", "full_name")}
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        display: "flex",
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        OS Build&nbsp;
                                        {safe_index(this.state.device_data, "sys_info", "major")}
                                        .
                                        {safe_index(this.state.device_data, "sys_info", "minor")}
                                        .
                                        {safe_index(this.state.device_data, "sys_info", "patch")}
                                        {
                                            safe_index(this.state.device_data, "sys_info", "build") === "N/A" ? "" :
                                                <div>
                                                    .
                                                    {safe_index(this.state.device_data, "sys_info", "build")}
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '400px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        color: 'white',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        wordWrap: 'break-word'
                                    }}>
                                        {this.getStatusRender(this.state.device_data, true)}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        Last Seen
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {get_time_string(this.state.device_data["last_seen"])}
                                    </div>
                                </div>

                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    background: '#A1A1AA'
                                }}/>

                                <div style={{
                                    width: '400px',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    display: 'inline-flex'
                                }}
                                >
                                    <div style={{
                                        whiteSpace: "nowrap",
                                        color: 'white',
                                        fontSize: 16,
                                        display: "inline-block",
                                        fontFamily: 'Inter',
                                        fontWeight: '600'
                                    }}>
                                        {get_patch_severity_render(this.state.device_data, true)}
                                    </div>
                                    <div style={{
                                        color: 'white',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        Last Scanned
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        wordWrap: 'break-word'
                                    }}>
                                        {get_time_string(safe_index(this.state.device_data, "updates", "last_scan"))}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <img src="/main/netflow-dotted-long.svg" width={"100%"} alt="dotted-line"/>
                                <input className="transparent-input" type='text' name='bdv-search'
                                       style={{
                                           background: "#18181B",
                                           borderRadius: 8,
                                           paddingRight: 16,
                                           paddingLeft: 16,
                                           height: 40
                                       }}
                                       placeholder="Search Updates"
                                       onChange={(data) => {
                                           this.setState({filter_patch_text: data.target.value})
                                           this.update_patch_entries(this.state.filter_patch_index, data.target.value)
                                       }}
                                />
                            </div>
                        </div>


                        <div style={{
                            height: "32px",
                            fontSize: "14px",
                            marginLeft: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px"
                        }}
                        >
                            <div style={{
                                height: "24px",
                                fontSize: "14px",
                                marginLeft: "8px",
                                display: "flex",
                                gap: "8px"
                            }}
                            >
                                <button
                                    className="menu-entry"
                                    id="0"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 0})
                                        this.update_patch_entries(0, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 0 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_patch_index === 0 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            All ({this.state.filer_patch_stats[0]})
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    id="1"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 1})
                                        this.update_patch_entries(1, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 1 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src="/main/critical-i.svg"
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Critical ({this.state.filer_patch_stats[1]})
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    id="2"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 2})
                                        this.update_patch_entries(2, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 2 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src="/main/important-i.svg"
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Important ({this.state.filer_patch_stats[2]})
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    id="3"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 3})
                                        this.update_patch_entries(3, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 3 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src="/main/moderate-i.svg"
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Moderate ({this.state.filer_patch_stats[3]})
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    id="4"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 4})
                                        this.update_patch_entries(4, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 4 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src="/main/low-i.svg"
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Low ({this.state.filer_patch_stats[4]})
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className="menu-entry"
                                    id="4"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                    onClick={() => {
                                        this.setState({filter_patch_index: 5})
                                        this.update_patch_entries(5, this.state.filter_patch_text)
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_patch_index === 5 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src="/main/undefined-i.svg"
                                            width={16}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Undefined ({this.state.filer_patch_stats[5]})
                                        </div>
                                    </div>
                                </button>

                                {/*<button*/}
                                {/*    onClick={() => {*/}
                                {/*        this.query_device_updates()*/}
                                {/*    }}*/}
                                {/*    style={{*/}
                                {/*        width: "28px",*/}
                                {/*        height: "28px",*/}
                                {/*        display: "flex",*/}
                                {/*        cursor: "pointer",*/}
                                {/*        border: "none",*/}
                                {/*        paddingLeft: "16px",*/}
                                {/*        overflow: "hidden",*/}
                                {/*        backgroundColor: "transparent",*/}
                                {/*        alignItems: "center",*/}
                                {/*        justifyContent: "center"*/}
                                {/*    }}>*/}
                                {/*    <img src="/main/refresh-i.svg" alt=""/>*/}
                                {/*</button>*/}
                            </div>

                            <div style={{marginLeft: "auto"}}/>

                            {
                                Object.keys(this.state.command_update_states).length === 0 ?
                                    <button onClick={() => {
                                        this.setState({showRH: true})
                                    }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                marginLeft: "auto",
                                                marginRight: 8,
                                                padding: "4px 8px 4px 8px",
                                                borderRadius: "2px",
                                                background: "rgba(244,63,94,0.25)",
                                                border: "solid 1px #F43F5E",
                                                color: "#F43F5E",
                                                gap: 4
                                            }}
                                    >
                                        <div style={{width: 16, height: 16, display: "flex", marginTop: 1}}>
                                            <IRefreshDynamic
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    color: "#F43F5E",
                                                }}
                                            />
                                        </div>
                                        Reboot Host
                                    </button>
                                    : ""
                            }

                            {
                                this.has_pushable_update() ?
                                    <button onClick={() => {
                                        this.setState({showPU: true})
                                    }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                padding: "4px 8px 4px 8px",
                                                borderRadius: "2px",
                                                background: "rgba(245,158,11,0.25)",
                                                border: "solid 1px #F59E0B",
                                                color: "#F59E0B",
                                                gap: 4
                                            }}
                                    >
                                        <div style={{width: 16, height: 16, display: "flex", marginTop: 1}}>
                                            <IUp_right_arrow
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    color: "#F59E0B",
                                                }}
                                            />
                                        </div>
                                        Push
                                        {
                                            Object.keys(this.state.selected_updates).length > 0 ?
                                                " Selected"
                                                :
                                                " All"
                                        }
                                    </button>
                                    : ""
                            }


                            <button onClick={this.export_patch_csv}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        marginRight: 16,
                                        padding: "4px 8px 4px 8px",
                                        borderRadius: "2px",
                                        background: "transparent",
                                        border: "solid 1px #06B6D4",
                                        color: "#06B6D4",
                                        gap: 4
                                    }}
                            >
                                <div style={{width: 16, height: 16, display: "flex", marginTop: 1}}>
                                    <img src="/main/export-i.svg" alt="export-icon"/>
                                </div>
                                Export CSV
                            </button>
                        </div>


                        <div className="scroll-bar-div" style={{
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            padding: 8,
                            paddingLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            background: "#27272A",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            width: "100%",
                            height: 840
                        }}
                        >
                            {"updates" in this.state.device_data ?
                                this.state.filer_patch_entries.map((update, i) =>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8
                                    }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            marginLeft: "4px",
                                            alignItems: "center",
                                            padding: 4,
                                            gap: 8
                                        }}
                                        >
                                            {
                                                update["id"] ?
                                                    update["reboot_required"] || update["id"] in this.state.command_update_states ?
                                                        <div>
                                                            <IBoxIndeterminate style={{
                                                                color: "#A1A1AA"
                                                            }}/>
                                                        </div>
                                                        :
                                                        this.state.selected_updates[update["id"]] ?
                                                            <div style={{
                                                                cursor: "pointer",
                                                            }}
                                                                 onClick={() => {
                                                                     delete this.state.selected_updates[update["id"]]
                                                                 }}>
                                                                <IBoxChecked style={{
                                                                    color: "#06B6D4"
                                                                }}/>
                                                            </div>
                                                            :
                                                            <div style={{
                                                                cursor: "pointer",
                                                            }}
                                                                 onClick={() => {
                                                                        this.state.selected_updates[update["id"]] = true
                                                                     }}>
                                                                <IBoxEmpty style={{
                                                                    color: "#06B6D4"
                                                                }}/>
                                                            </div>
                                                    :
                                                    <div>
                                                        <IBoxIndeterminate style={{
                                                            color: "#A1A1AA"
                                                        }}/>
                                                    </div>
                                            }

                                            <div style={{
                                                paddingLeft: 8,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                width: "1080px",
                                                gap: "4px"
                                            }}>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {update["title"]}
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    gap: "4px"
                                                }}>
                                                    {
                                                        get_patch_severity_chip(update["severity"])
                                                    }
                                                    {
                                                        update["reboot_behavior"] && update["reboot_behavior"] < 3 ?
                                                        <div
                                                            style={{
                                                                borderRadius: "16px",
                                                                backgroundColor: "rgba(56,189,248,0.25)",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "0px 8px",
                                                                color: "#38BDF8"
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    fontWeight: "600"
                                                                }}
                                                            >
                                                                {
                                                                    update["reboot_behavior"] === 0 ? "No Reboot Required" :
                                                                        update["reboot_behavior"] === 1 ? "Requires Reboot" :
                                                                            update["reboot_behavior"] === 2 ? "May Require Reboot" : ""
                                                                }

                                                            </div>
                                                        </div> : ""
                                                    }
                                                    {
                                                        update["tags"].map((tag, i) =>
                                                            <div
                                                                style={{
                                                                    borderRadius: "16px",
                                                                    backgroundColor: "rgba(148,163,184,0.25)",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    padding: "0px 8px",
                                                                    color: "rgba(148,163,184,1)"
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        position: "relative",
                                                                        fontWeight: "600"
                                                                    }}
                                                                >
                                                                    {tag}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                            </div>


                                            <div style={{
                                                width: "220px",
                                                flexDirection: 'column',
                                                justifyContent: 'left',
                                                marginLeft: "auto",
                                                marginRight: 12,
                                                alignItems: 'left',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    justifyContent: 'left',
                                                    alignItems: 'left',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Discovered
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {get_time_string(update["first_seen"])}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 12,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {
                                                        update["id"] && update["id"] in this.state.command_update_states ? this.state.command_update_states[update["id"]] :
                                                        update["reboot_required"] === true ? "Reboot Required" : update["installed"] === true ? "Installed"
                                                            : update["downloaded"] === true ? "Downloaded" : <div>&nbsp;</div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                             alt="dotted-line"/>
                                    </div>
                                )
                                :
                                ""
                            }
                            {
                                "updates" in this.state.device_data && "patched_since" in this.state.device_data["updates"] ?
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8
                                    }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            marginLeft: "4px",
                                            alignItems: "center",
                                            padding: 4,
                                            gap: 16
                                        }}
                                        >
                                            <img src="/main/updated-i.svg" alt="updated"/>
                                            <div style={{
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex',
                                                width: "1000px",
                                                gap: "2px"
                                            }}>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    No Missing Patches
                                                </div>
                                                <div style={{
                                                    color: 'white',
                                                }}>
                                                    Up To Date

                                                </div>

                                            </div>


                                            <div style={{
                                                marginLeft: "auto",
                                                marginRight: 12,
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>Declared
                                                    </div>
                                                    <div style={{
                                                        color: '#A1A1AA',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {get_time_string(this.state.device_data["updates"]["patched_since"])}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                             alt="dotted-line"/>
                                    </div>
                                    :
                                    ""
                            }
                        </div>

                    </div>
                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPU}
                    contentLabel="Netflow"
                    style={{
                        overlay: {
                            zIndex: 3000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 550,
                            height: 350,
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 8,
                            fontWeight: "600",
                        }}
                        >
                            <IPatch style={{
                                width: "24px",
                                height: "24px",
                                color: "#A1A1AA",
                            }}/>
                            <div style={{color: "#F9B233"}}>
                                Update Push Confirmation
                            </div>
                        </div>


                        <div className="inner-tip-box"
                             style={{
                                 height: "380px"
                             }}
                        >
                            <div style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}>
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/hic-otp-i.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Enter OTP
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 16,
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{width: 1, alignSelf: 'stretch', background: '#71717A'}}/>
                                <input className="transparent-input" type='number' name='supplier'
                                       autoFocus={true}
                                       style={{
                                           backgroundColor: "#18181B",
                                           padding: 16,
                                           borderRadius: 8,
                                           height: "52px"
                                       }}
                                       onChange={(data) => {
                                           this.setState({hic_otp: data.target.value})
                                       }}
                                />
                            </div>

                            <div style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: this.state.hic_otp.length === 6 ? 'rgba(238,3,3,0.25)' : 'rgba(113, 113, 122, 0.25)',
                                    animation: this.state.hic_otp.length === 6 ? "light-pulse 1s alternate infinite" : "",
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex'
                                }}
                                        onClick={() => {
                                            if (this.state.hic_otp.length === 6) {
                                                this.send_patch_command()
                                            }
                                        }
                                        }
                                >
                                    <div style={{
                                        color: this.state.hic_otp.length === 6 ? 'white' : '#71717A',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>
                                        Push Updates
                                    </div>
                                </button>
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: 'rgba(6, 182, 212, 0.25)',
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex',
                                }}
                                        onClick={this.close_hic}
                                >
                                    <div style={{
                                        color: '#06B6D4',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>Cancel
                                    </div>
                                </button>
                            </div>

                            <div hidden={!this.state.invalid_otp}
                                 style={{
                                     marginTop: 8,
                                     gap: 4,
                                     display: "flex",
                                     alignItems: "center"
                                 }}
                            >
                                <img alt="" src="/main/icon-l5-red.svg"/>
                                <div style={{color: "#EF4444"}}>Invalid OTP</div>
                            </div>

                            <div style={{
                                width: '100%',
                                marginTop: "auto",
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{
                                    width: 24,
                                    height: 24,
                                    padding: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <img src="/main/hic-note-i.svg" alt="note"/>
                                </div>
                                <div style={{
                                    flex: '1 1 0',
                                    color: '#A1A1AA',
                                    fontSize: 14,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    letterSpacing: 0.07,
                                    wordWrap: 'break-word'
                                }}>
                                    Pushing updates cannot be canceled once started, the agent will try to download and install the respective updates.
                                </div>
                            </div>

                        </div>

                    </div>


                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showRH}
                    contentLabel="RebootHost"
                    style={{
                        overlay: {
                            zIndex: 3000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 550,
                            height: 550,
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 8,
                            fontWeight: "600",
                        }}
                        >
                            <IPatch style={{
                                width: "24px",
                                height: "24px",
                                color: "#A1A1AA",
                            }}/>
                            <div style={{color: "#F9B233"}}>
                                Reboot Host Confirmation
                            </div>
                        </div>


                        <div className="inner-tip-box"
                             style={{
                                 height: "450px"
                             }}
                        >
                            <div style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}
                            >
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/hic-otp-i.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Enter message (Optional)
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 16,
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{width: 1, alignSelf: 'stretch', background: '#71717A'}}/>
                                <input className="transparent-input" type='text' name='message'
                                       style={{
                                           backgroundColor: "#18181B",
                                           padding: 16,
                                           borderRadius: 8,
                                           height: "52px"
                                       }}
                                       onChange={(data) => {
                                           let hic_data = this.state.hic_data
                                           hic_data["msg"] = data.target.value
                                           this.setState({hic_data: hic_data})
                                       }}
                                />
                            </div>

                            <div style={{
                                marginLeft: 8,
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}
                            >
                                {
                                    this.state.hic_data["force"] ?
                                        <div
                                            style={{
                                                display: "flex",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                            this.state.hic_data["force"] = false
                                        }}>
                                            <IBoxChecked/>
                                        </div>
                                        :
                                        <div
                                            style={{
                                                display: "flex",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                            this.state.hic_data["force"] = true
                                        }}>
                                            <IBoxEmpty/>
                                        </div>

                                }

                                <div style={{
                                    marginLeft: 8,
                                    color: "#a1daf8"
                                }}>Force close applications
                                </div>
                            </div>

                            <div style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}
                            >
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/hic-otp-i.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Enter OTP
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 16,
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{width: 1, alignSelf: 'stretch', background: '#71717A'}}/>
                                <input className="transparent-input" type='number' name='otp'
                                       autoFocus={true}
                                       style={{
                                           backgroundColor: "#18181B",
                                           padding: 16,
                                           borderRadius: 8,
                                           height: "52px"
                                       }}
                                       onChange={(data) => {
                                           this.setState({hic_otp: data.target.value})
                                       }}
                                />
                            </div>

                            <div style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: this.state.hic_otp.length === 6 ? 'rgba(238,3,3,0.25)' : 'rgba(113, 113, 122, 0.25)',
                                    animation: this.state.hic_otp.length === 6 ? "light-pulse 1s alternate infinite" : "",
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex'
                                }}
                                        onClick={() => {
                                            if (this.state.hic_otp.length === 6) {
                                                this.send_reboot_command()
                                            }
                                        }
                                        }
                                >
                                    <div style={{
                                        color: this.state.hic_otp.length === 6 ? 'white' : '#71717A',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>
                                        Reboot Host
                                    </div>
                                </button>
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: 'rgba(6, 182, 212, 0.25)',
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex',
                                }}
                                        onClick={this.close_hic}
                                >
                                    <div style={{
                                        color: '#06B6D4',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>Cancel
                                    </div>
                                </button>
                            </div>

                            <div hidden={!this.state.invalid_otp}
                                 style={{
                                     marginTop: 8,
                                     gap: 4,
                                     display: "flex",
                                     alignItems: "center"
                                 }}
                            >
                                <img alt="" src="/main/icon-l5-red.svg"/>
                                <div style={{color: "#EF4444"}}>Invalid OTP</div>
                            </div>

                            <div style={{
                                width: '100%',
                                marginTop: "auto",
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{
                                    width: 24,
                                    height: 24,
                                    padding: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <img src="/main/hic-note-i.svg" alt="note"/>
                                </div>
                                <div style={{
                                    flex: '1 1 0',
                                    color: '#A1A1AA',
                                    fontSize: 14,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    letterSpacing: 0.07,
                                    wordWrap: 'break-word'
                                }}>
                                    The reboot command cannot be canceled once submitted, the agent will try to reboot
                                    the device until it succeeds.
                                </div>
                            </div>
                        </div>
                    </div>


                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showHIC}
                    contentLabel="Netflow"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 550,
                            height: 460,
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 8,
                            fontWeight: "600",
                        }}
                        >
                            <img src="/main/mgt-isolated-i.svg" alt="isolated" width={28} height={27}/>
                            <div style={{color: "#F9B233"}}>
                                Host Isolation Confirmation
                            </div>
                        </div>


                        <div className="inner-tip-box"
                             style={{
                                 height: "380px"
                             }}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}>
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/tiphost.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Host Information
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "4px",
                                height: "calc(100%-24px)"
                            }}
                                 id="host-data"
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    color: "#A1A1AA",
                                    gap: "4px",
                                    width: "100px"
                                }} id="section1">
                                    <div>Client IP:</div>
                                    <div>Hostname:</div>
                                    <div>Last User:</div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px"
                                }} id="section2">
                                    <div>{this.state.hic_data["ip"]}</div>
                                    <div>{this.state.hic_data["host"]}</div>
                                    <div>{this.getLastUser(this.state.hic_data["users"])}</div>
                                </div>
                            </div>


                            <div style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}>
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/hic-otp-i.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Enter OTP
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 16,
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{width: 1, alignSelf: 'stretch', background: '#71717A'}}/>
                                <input className="transparent-input" type='number' name='supplier'
                                       autoFocus={true}
                                       style={{
                                           backgroundColor: "#18181B",
                                           padding: 16,
                                           borderRadius: 8,
                                           height: "52px"
                                       }}
                                       onChange={(data) => {
                                           this.setState({hic_otp: data.target.value})
                                       }}
                                />
                            </div>

                            <div style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: this.state.hic_otp.length === 6 ? 'rgba(238,3,3,0.25)' : 'rgba(113, 113, 122, 0.25)',
                                    animation: this.state.hic_otp.length === 6 ? "light-pulse 1s alternate infinite" : "",
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex'
                                }}
                                        onClick={() => {
                                            if (this.state.hic_otp.length === 6) {
                                                this.isolation_route()
                                            }
                                        }
                                        }
                                >
                                    <div style={{
                                        color: this.state.hic_otp.length === 6 ? 'white' : '#71717A',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            this.state.hic_state ?
                                                "Isolate " : "Release "
                                        }
                                        Host
                                    </div>
                                </button>
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: 'rgba(6, 182, 212, 0.25)',
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex',
                                }}
                                        onClick={this.close_hic}
                                >
                                    <div style={{
                                        color: '#06B6D4',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>Cancel
                                    </div>
                                </button>
                            </div>

                            <div hidden={!this.state.invalid_otp}
                                 style={{
                                     marginTop: 8,
                                     gap: 4,
                                     display: "flex",
                                     alignItems: "center"
                                 }}
                            >
                                <img alt="" src="/main/icon-l5-red.svg"/>
                                <div style={{color: "#EF4444"}}>Invalid OTP</div>
                            </div>

                            <div style={{
                                width: '100%',
                                marginTop: "auto",
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{
                                    width: 24,
                                    height: 24,
                                    padding: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <img src="/main/hic-note-i.svg" alt="note"/>
                                </div>
                                <div style={{
                                    flex: '1 1 0',
                                    color: '#A1A1AA',
                                    fontSize: 14,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    letterSpacing: 0.07,
                                    wordWrap: 'break-word'
                                }}>
                                    {
                                        this.state.hic_state ?
                                            "Isolating a host will stop all communication on that host for all adapters. The only " +
                                            "communications allowed are via the CyberShadows platform."
                                            :
                                            "Releasing a host will enable normal communication on that host for all adapters."
                                    }
                                </div>
                            </div>

                        </div>

                    </div>


                </ReactModal>


                <div className="Pulse-Box">
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    ETI Pulse: {this.state.pulse}ms
                </div>

                <div style={{
                    width: '100%',
                    paddingTop: 8,
                    paddingBottom: 8,
                    boxShadow: '0px 0px 1px rgba(48, 49, 51, 0.05)',
                    borderRadius: 8,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: 32,
                    display: 'inline-flex'
                }}
                >
                    <div style={{
                        width: "100%",
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "8px",
                        color: "#F9B233",
                    }}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "8px",
                                width: "100%"
                            }}
                        >
                            <div
                                className="Detection-Card Detection-Card-Management-Page"
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                            zIndex: "10",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600"
                                            }}
                                        >
                                            ETI License Usage
                                        </div>
                                        <MUITooltip title={this.props.tooltip_info("ETI License Usage")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt="detection-i"
                                                src="/main/detection-i.svg"
                                            />
                                        </MUITooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "232px",
                                        height: "232px",
                                        textAlign: "center",
                                        fontSize: "45px",
                                        color: "#fff",
                                        zIndex: "100"
                                    }}
                                >

                                    <CircularProgressbarWithChildren value={((this.state.table_data.length - this.state.server_stats.stale - this.state.workstation_stats.stale)/(this.state.limit === 0 ? 1 : this.state.limit))*100} strokeWidth={5}
                                                                     styles={buildStyles({
                                                                         textSize: '25px',
                                                                         pathTransitionDuration: 0.5,
                                                                         textColor: '#fff',
                                                                         trailColor: '#27272A',
                                                                         pathColor: '#84CC16'
                                                                     })}
                                    >
                                        <div style={{marginBottom: -20}}>{this.state.table_data.length - this.state.server_stats.stale - this.state.workstation_stats.stale}</div>
                                        <img alt="" src="/main/dividor.svg"/>
                                        <div style={{
                                            marginTop: -20,
                                            color: 'rgba(132,204,22,0.35)'
                                        }}>{this.state.limit}</div>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "4px",
                                        fontSize: "12px",
                                        color: "#a1a1aa",
                                        justifyContent: "center",
                                        height: "20px"
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(132,204,22,0.25)",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#84CC16"
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600"
                                            }}
                                        >
                                            Registered / Limit
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="Detection-Card"
                                style={{
                                    width: "calc((100%/2) - 8px)",
                                    height: "100%",
                                    padding: 16,
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                            zIndex: "10",
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600"
                                            }}
                                        >
                                            Endpoint Status
                                        </div>
                                        <MUITooltip title={this.props.tooltip_info("Endpoint Status")} arrow>
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    overflow: "hidden",
                                                    flexShrink: "0",
                                                }}
                                                alt="detection-i"
                                                src="/main/detection-i.svg"
                                            />
                                        </MUITooltip>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "",
                                        fontSize: "45px",
                                        alignItems: 'left',
                                        color: "#fff",
                                        zIndex: "100"
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: '50%',
                                        gap: 8
                                    }}
                                    >
                                        <div style={{
                                            width: '50%',
                                            height: '100%',
                                            padding: 8,
                                            background: 'rgba(16, 185, 129, 0.25)',
                                            borderRadius: 6,
                                            border: '1px #10B981 solid',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                flex: '1 1 0',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <img width={22} height={22} src="/main/mgt-online-i.svg"
                                                             alt={"online-i"}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                        }}>Online
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Servers
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#10B981'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Workstations
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#10B981'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>ShadowFire
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div id={"10"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.server_stats.online}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#10B981'
                                                        }}/>
                                                        <div id={"11"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.workstation_stats.online}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#10B981'
                                                        }}/>
                                                        <div id={"12"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.kfire_stats.online}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '50%',
                                            height: '100%',
                                            padding: 8,
                                            background: 'rgba(249,115,22,0.25)',
                                            borderRadius: 6,
                                            border: '1px #F97316 solid',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                flex: '1 1 0',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <img width={22} height={22} src="/main/mgt-offline-i.svg"
                                                             alt={"offline-i"}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                        }}>Offline
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Servers
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F97316'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Workstations
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F97316'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>ShadowFire
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div id={"13"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.server_stats.offline}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F97316'
                                                        }}/>
                                                        <div id={"14"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.workstation_stats.offline}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F97316'
                                                        }}/>
                                                        <div id={"15"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.kfire_stats.offline}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: '50%',
                                        gap: 8
                                    }}
                                    >
                                        <div style={{
                                            width: '50%',
                                            height: '100%',
                                            padding: 8,
                                            background: 'rgba(244,63,94,0.25)',
                                            borderRadius: 6,
                                            border: '1px #F43F5E solid',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                flex: '1 1 0',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <img width={22} height={22} src="/main/mgt-isolated-i.svg"
                                                             alt={"isolated-i"}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                        }}>Isolated
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Servers
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F43F5E'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Workstations
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F43F5E'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>ShadowFire
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div id={"16"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.server_stats.isolated}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F43F5E'
                                                        }}/>
                                                        <div id={"17"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.workstation_stats.isolated}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#F43F5E'
                                                        }}/>
                                                        <div id={"18"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{0}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '50%',
                                            height: '100%',
                                            padding: 8,
                                            background: 'rgba(113,113,122,0.25)',
                                            borderRadius: 6,
                                            border: '1px #71717A solid',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                alignSelf: 'stretch',
                                                flex: '1 1 0',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    alignSelf: 'stretch',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <img width={22} height={22} src="/main/mgt-stale-i.svg"
                                                             alt={"stale-i"}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '500',
                                                        }}>Stale
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Servers
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#71717A'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>Workstations
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#71717A'
                                                        }}/>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '400',
                                                        }}>ShadowFire
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        flex: '1 1 0',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div id={"19"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.server_stats.stale}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#71717A'
                                                        }}/>
                                                        <div id={"20"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.workstation_stats.stale}
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            background: '#71717A'
                                                        }}/>
                                                        <div id={"21"}
                                                             onClick={this.update_table}
                                                             className={"Transparent-Btn"} style={{
                                                            flex: '1 1 0',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                        }}>{this.state.kfire_stats.stale}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div
                                className="Data-Card"
                                id="ep-card"
                                style={{
                                    minWidth: "500px",
                                    height: "100%",
                                    maxHeight: "360px"
                                }}
                            >
                                <div
                                    style={{
                                        flex: "1",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "32px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "4px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    position: "relative",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    marginLeft: "3px",
                                                }}
                                            >
                                                Patch Status
                                            </div>
                                            <MuiTooltip title={this.props.tooltip_info("Patch Status")} arrow>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        overflow: "hidden",
                                                        flexShrink: "0",
                                                    }}
                                                    alt=""
                                                    src="/main/detection-i.svg"
                                                />
                                            </MuiTooltip>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                width: "100%",
                                                height: "100%"
                                            }}
                                        >

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50%",
                                                height: "100%",
                                                gap: "16px",
                                                color: "white"
                                            }}
                                            >

                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Servers
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                    width: "60%",
                                                    height: "100%",
                                                }}>
                                                    <div style={{
                                                        position: "absolute",
                                                        fontSize: 45,
                                                        zIndex: 1,
                                                    }}>
                                                        {!Number.isNaN(this.state.patch_stats.servers.percent_patched) ?
                                                            <div>
                                                                {this.state.patch_stats.servers.percent_patched}%
                                                            </div>
                                                            :
                                                            <div>
                                                                None
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{zIndex: 100}}>
                                                        <Doughnut
                                                            height={"200px"}
                                                            width={"200px"}
                                                            plugins={[{
                                                                id: 'edgeCase',
                                                                beforeDraw(chart, plugin) {
                                                                    if (chart.getDatasetMeta(0).data.length === 1) {
                                                                        chart.getDatasetMeta(0).data[0].options.borderWidth = 0
                                                                        chart.getDatasetMeta(0).data[0].options.spacing = -5
                                                                    }
                                                                }
                                                            }]}
                                                            options={{
                                                                responsive: false,
                                                                cutout: this.state.patch_stats.servers.stats.length === 1 ? 84 : 80,
                                                                plugins: {
                                                                    legend: {
                                                                        display: false
                                                                    },
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label(tooltipItem) {
                                                                                return `Server Count: ${tooltipItem.dataset.hidden_data[tooltipItem.dataIndex]}`;
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            data={{
                                                                labels: this.state.patch_stats.servers.labels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Server Count',
                                                                        hidden_data: this.state.patch_stats.servers.data,
                                                                        data: this.state.patch_stats.servers.stats,
                                                                        hoverBackgroundColor: this.state.patch_stats.servers.colors,
                                                                        backgroundColor: this.state.patch_stats.servers.colors,
                                                                        borderColor: ["#18181b", "#18181b", "#18181b", "#18181b", "#18181b", "#18181b", "#18181b"],
                                                                        hoverBorderWidth: 2,
                                                                        borderRadius: 8,
                                                                        borderWidth: 4,
                                                                    },
                                                                ],
                                                            }}/>
                                                    </div>
                                                </div>


                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "50%",
                                                height: "100%",
                                                gap: "16px",
                                                color: "white"
                                            }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Workstations
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    position: "relative",
                                                    width: "60%",
                                                    height: "100%"
                                                }}>
                                                    <div style={{
                                                        position: "absolute",
                                                        fontSize: 45,
                                                        zIndex: 1,
                                                    }}>
                                                        {!Number.isNaN(this.state.patch_stats.workstations.percent_patched) ?
                                                            <div>
                                                                {this.state.patch_stats.workstations.percent_patched}%
                                                            </div>
                                                            :
                                                            <div>
                                                                None
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{zIndex: 100}}>
                                                        <Doughnut
                                                            height={"200px"}
                                                            width={"200px"}
                                                            plugins={[{
                                                                id: 'edgeCase',
                                                                beforeDraw(chart, plugin) {
                                                                    if (chart.getDatasetMeta(0).data.length === 1) {
                                                                        chart.getDatasetMeta(0).data[0].options.borderWidth = 0
                                                                        chart.getDatasetMeta(0).data[0].options.spacing = -5
                                                                    }
                                                                }
                                                            }]}
                                                            options={{
                                                                responsive: false,
                                                                cutout: this.state.patch_stats.workstations.stats.length === 1 ? 84 : 80,
                                                                plugins: {
                                                                    legend: {
                                                                        display: false
                                                                    },
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label(tooltipItem) {
                                                                                return `Workstation Count: ${tooltipItem.dataset.hidden_data[tooltipItem.dataIndex]}`;
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            data={{
                                                                labels: this.state.patch_stats.workstations.labels,
                                                                datasets: [
                                                                    {
                                                                        label: 'Workstation Count',
                                                                    hidden_data: this.state.patch_stats.workstations.data,
                                                                    data: this.state.patch_stats.workstations.stats,
                                                                    hoverBackgroundColor: this.state.patch_stats.workstations.colors,
                                                                    backgroundColor: this.state.patch_stats.workstations.colors,
                                                                    borderColor: ["#18181b", "#18181b", "#18181b", "#18181b", "#18181b", "#18181b", "#18181b"],
                                                                    hoverBorderWidth: 2,
                                                                    borderRadius: 8,
                                                                    borderWidth: 4,
                                                                },
                                                            ],
                                                        }}/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "calc(100% - 8px)",
                            borderRadius: "8px",
                            height: "100%"
                        }}
                        >

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "24px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <div style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    borderRadius: "4px 4px 0 0",
                                    alignItems: "center",
                                    backgroundColor: "#27272A",
                                    color: "#F9B233",
                                    padding: "4px 16px",
                                    gap: "4px",
                                    marginBottom: "-10px"
                                }}>
                                    <IDetections style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#65A30D",
                                    }}/>
                                    ETI Devices
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    fontSize: 14
                                }}>
                                    <button
                                        onClick={this.refresh_table}
                                        style={{
                                            width: "28px",
                                            height: "28px",
                                            display: "flex",
                                            cursor: "pointer",
                                            border: "none",
                                            paddingLeft: "16px",
                                            overflow: "hidden",
                                            backgroundColor: "transparent",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                        <img src="/main/refresh-i.svg" alt=""/>
                                    </button>

                                    Refresh Table
                                </div>

                                <div style={{
                                    display: "flex",
                                    gap: "8px"
                                }}>
                                    <button
                                        className="menu-entry"
                                        onClick={this.update_table}
                                        id="0"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: this.state.filter_index === 0 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src={this.state.filter_index === 0 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                All
                                            </div>
                                        </div>
                                    </button>

                                    <button
                                        className="menu-entry"
                                        hidden={this.state.filter_index < 10}
                                        id="10"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src={"/main/filter-active-i.svg"}
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                Custom
                                            </div>
                                        </div>
                                    </button>

                                    <button
                                        className="menu-entry"
                                        onClick={this.update_table}
                                        id="1"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: this.state.filter_index === 1 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src="/main/mgt-online-i.svg"
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                Online
                                            </div>
                                        </div>
                                    </button>

                                    <button
                                        className="menu-entry"
                                        onClick={this.update_table}
                                        id="2"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: this.state.filter_index === 2 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src="/main/mgt-offline-i.svg"
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                Offline
                                            </div>
                                        </div>
                                    </button>

                                    <button
                                        className="menu-entry"
                                        onClick={this.update_table}
                                        id="3"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: this.state.filter_index === 3 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src="/main/mgt-stale-i.svg"
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                Stale
                                            </div>
                                        </div>
                                    </button>

                                    <button
                                        className="menu-entry"
                                        onClick={this.update_table}
                                        id="4"
                                        style={{
                                            width: "unset",
                                            minHeight: "100%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: this.state.filter_index === 4 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                                borderRadius: "2px",
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "8px 12px 8px 8px",
                                                boxSizing: "border-box",
                                                gap: "4px"
                                            }}
                                        >
                                            <img
                                                src="/main/mgt-isolated-i.svg"
                                                width={16}
                                                alt="filter"/>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    color: "white",
                                                }}
                                            >
                                                Isolated
                                            </div>
                                        </div>
                                    </button>

                                    <div style={{
                                        width: '1px',
                                        height: '100%',
                                        background: '#52525B'
                                    }}/>

                                    <button onClick={this.export_devices_csv}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                marginRight: 16,
                                                padding: "4px 8px 4px 8px",
                                                borderRadius: "2px",
                                                background: "transparent",
                                                border: "solid 1px #06B6D4",
                                                color: "#06B6D4",
                                                gap: 4
                                            }}
                                    >
                                        <div style={{width: 16, height: 16, display: "flex"}}>
                                            <img src="/main/export-i.svg" alt="export-icon"/>
                                        </div>
                                        Export CSV
                                    </button>

                                </div>

                            </div>
                            <ThemeProvider theme={this.tableTheme}
                            >
                                <MaterialReactTable
                                    enableRowActions={true}
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: 'Device Card'
                                        },
                                    }}
                                    renderRowActions={({row}) => (
                                        <button
                                            className="menu-entry"
                                            onClick={() => this.OpenModal(row["original"])}
                                            style={{
                                                width: "100px"
                                            }}
                                            id="show-btn"
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    borderRadius: "2px",
                                                    padding: "7px",
                                                    backgroundColor: "#27272A"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontSize: "14px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    View
                                                </div>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                    alt=""
                                                    src="/main/show-i.svg"
                                                />
                                            </div>
                                        </button>
                                    )}
                                    initialState={{
                                        sorting: [{
                                            id: 'host',
                                            desc: true,
                                        }]
                                    }}
                                    columns={this.columns} data={this.getTableData()}
                                >
                                </MaterialReactTable>
                            </ThemeProvider>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}