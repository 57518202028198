import React, { Component } from 'react'
import {Link, Navigate} from 'react-router-dom'
import WorldMap, {CountryContext} from "react-svg-worldmap";
import { ReactComponent as Iice } from './icons/ice2.svg';
import network from "../modules/network";

export default class Ice extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.map_ref = React.createRef()
        this.eti_feed_ref = React.createRef()
        this.state = {
            yes: 1,
            sign_out: false,
            map_data: [],
            eti_feed: [],
            token: '',
            username: '',
            company: '',
            stats: {},
            keys: {},
            ioa_data: undefined
        };
        this.last_feed_idx = 0
        this.feed_cache = []
        this.getMapWidth = this.getMapWidth.bind(this)
        this.getMapHeight = this.getMapHeight.bind(this)
        this.update_feed = this.update_feed.bind(this);
        this.getMapData = this.getMapData.bind(this);
        this.update_feed_cache = this.update_feed_cache.bind(this)
        this.tick = this.tick.bind(this)
    }

    getMapData() {
        if (this.state.stats.detections) {
            //let items = []
            let map_data = []
            for (let item of this.state.stats.detections) {
                //items.push(item)
                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function(key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            this.setState({map_data: map_data})
        }
        return this.state.map_data
    }

    update_feed_cache() {
        let update = []
        if (!this.state.ioa_data)return
        if (this.last_feed_idx > this.state.ioa_data.length - 15) {
            this.last_feed_idx = 0
        }
        for (let i = this.last_feed_idx; i < this.last_feed_idx + 10; i++) {
            if (this.state.ioa_data[i]) {
                let str = this.state.ioa_data[i]["ip"]
                if (this.state.ioa_data[i]["host"] !== "N/A") {
                    str += " " + this.state.ioa_data[i]["host"]
                } else if (this.state.ioa_data[i]["ssl_cn"] !== "N/A") {
                    str += " " + this.state.ioa_data[i]["ssl_cn"]
                }
                update.push(str)
            }
        }
        this.last_feed_idx = this.last_feed_idx + 10
        this.feed_cache = this.feed_cache.concat(update)
    }

    update_feed() {
        if (this.feed_cache.length > 1) {
            let feed = this.state.eti_feed
            let len = 45
            if (this.eti_feed_ref.current !== null) {
                len = Math.ceil(this.eti_feed_ref.current.offsetHeight / 21)
            }
            if (feed.length >= len) {
                feed.splice(0, 1)
            }
            feed.push(this.feed_cache[0])
            this.feed_cache.splice(0, 1)
            if (this.feed_cache.length > 10) {
                this.feed_cache.length = []
            }
            this.setState({eti_feed: feed})
        }
    }

    tick() {
        if (this.state.yes > 10) {
            this.setState({yes: 0})
        }
        this.setState({yes: this.state.yes + 1})
        let company = this.state.company;
        if (company !== "KryptoKloud Ltd") {
            this.setState({sign_out: true})
            return
        }
        let token = this.state.token
        if (token !== '') {
            let jsonData = {
                "token": token,
                "company": company
            }
            network.post_request("latest_ioa_data", jsonData).then((response) => {
                if (response) {
                    if (response.data) {
                        this.setState({ioa_data: response["data"]})
                    }
                }
            }).catch((err) => {
                console.log("ice", err)
            })
            this.update_feed_cache()
        }
    }
    componentDidMount() {
        let user = this.props.user()
        if (user.username === '') {
            this.setState({sign_out: true})
        } else {
            this.setState({username: user.username, token: user.session, company: user.company, keys: user.keys})
        }
        this.interval_update = setInterval(
            () => this.tick(),
            5000
        );
        this.feed_update = setInterval(
            () => this.update_feed(),
            200
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
        clearInterval(this.feed_update);
    }

    getStyle = ({
                          countryValue,
                          countryCode,
                          minValue,
                          maxValue,
                          color,
                      }: CountryContext) => ({
        fill: countryValue ? color: "rgb(85,115,85)",
        fillOpacity: 0.5,
        stroke: "white",
        strokeWidth: 1,
        cursor: "pointer",
    });

    getMapHeight() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetHeight
        }
        return 200
    }

    getMapWidth() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetWidth
        }
        return 200
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto",
                    width: "100%",
                    height: "100%"
                }}
                id="inner-data"
            >
                {(this.state.sign_out) && (<Navigate to="/sign-in?page=ice" replace={true}/>)}

                <div style={{
                    color: "initial"
                }}>
                    <Iice style={{
                        position: "relative",
                        color: "initial"
                    }}/>
                </div>
                <div
                    style={{
                        position: "relative",
                        width: "99%",
                        height: "60%"
                    }}
                >
                <div
                    className="Data-Card"
                    style={{
                        height: "100%",
                    }}
                    id="active-card"
                >
                    <div
                        style={{
                            alignSelf: "stretch",
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            padding: "8px 0px",
                            gap: "24px",
                        }}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "8px",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "4px",
                                }}
                            >
                                <img
                                    style={{
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                    }}
                                    alt=""
                                    src="/main/active-i.svg"
                                />
                                <div
                                    style={{
                                        position: "relative",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                        display: "inline-block",
                                        flexShrink: "0",
                                        color: "#84CC16",
                                    }}
                                >
                                    Active C2 Infra ITW / Threat Hunting
                                </div>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#a3a3a3",
                                }}
                            >
                                New active C2 infrastructure over the Realtime Detections
                            </div>
                        </div>
                        <div
                            style={{
                                alignSelf: "stretch",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "flex-start",
                                gap: "8px",
                                textAlign: "start",
                                fontSize: "16px",
                                color: "#fff",
                                overflow: "hidden",
                                marginTop: "-15px",
                                marginBottom: "-10px",
                                marginRight: "auto",
                                height: "100%"
                            }}
                        >

                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden"
                                }}
                                ref={this.eti_feed_ref}
                            >
                                {this.state.eti_feed.map((item, i) =>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        overflow: "hidden"
                                    }}>{item}</div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                </div>

                <div
                    style={{
                        position: "relative",
                        width: "99%",
                        height: "40%"
                    }}
                >
                    <div style={{
                        borderRadius: "8px",
                        width: "100%",
                        minWidth: "570px",
                        justifyContent: "flexStart",
                        boxSizing: "border-box",
                        paddingBottom: "100px",
                        gap: "8px",
                    }}
                         id="Map-Card">
                        <div
                            style={{
                                backgroundColor: "#18181b",
                                borderRadius: "8px",
                                boxSizing: "border-box",
                                gap: "24px",
                                padding: "16px 24px 16px 16px",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    padding: "8px 0px",
                                    gap: "24px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "4px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            alt=""
                                            src="/main/map-i.svg"
                                        />
                                        <div
                                            style={{
                                                flex: "1",
                                                position: "relative",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                marginLeft: "3px",
                                                color: "#84CC16",
                                            }}
                                        >
                                            C2 Threat Map
                                        </div>
                                    </div>

                                </div>

                                <div ref={this.map_ref} style={{
                                    width: "100%",
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        marginLeft: "20px",
                                        marginTop: "20px"
                                    }}>
                                        <img width={this.getMapWidth() - 19} height={this.getMapHeight() - 60}
                                             src="/main/crosshair.svg" alt=""/>
                                    </div>
                                    <div style={{
                                        position: "absolute",
                                        marginLeft: "20px",
                                        marginTop: "20px",
                                        transform: "scale(0.7)",
                                        animation: "rotateLine 10s linear infinite",
                                    }}>
                                        <img width={(this.getMapWidth() - 19)} height={(this.getMapHeight() - 60)} src="/main/circle-scanner.svg" alt=""/>
                                    </div>
                                    <div>
                                        <WorldMap backgroundColor="#18181B" color="red"
                                                  size={this.getMapWidth()} data={this.getMapData()}
                                                  styleFunction={this.getStyle}

                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}